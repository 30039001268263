import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LandingContact from "../../landing/contact";
import Form from "../../landing/form";
import { RootState } from "../../../redux";
import {
  Bottom,
  ServicePageCont,
  FormCont,
  Header,
  SmallerText,
  Top,
} from "./styles";

import { useInView } from "react-intersection-observer";

const ServicesHero = () => {
  const { loaded } = useSelector((state: RootState) => state.loaded);
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <ServicePageCont>
      <Top>
        <Header>SPECIALIST SERVICES</Header>
        <div>
          <SmallerText>
            Our list of dedicated services and solutions have been implemented
            to best meet the challenges our clients are facing. With an
            unparalleled understanding of the marketplace and its current
            direction, we look to ensure each client that enlists Cedar Dean to
            help them receive a positive experience. 
          </SmallerText>
          <SmallerText>
            We’ve successfully generated over £500m worth of savings by helping
            reduce our clients’ leasehold liabilities and obligations. Our
            bespoke team of experts are on hand to work with you and guide you
            like no one else can.
          </SmallerText>
        </div>
      </Top>
    </ServicePageCont>
  );
};

export default ServicesHero;
