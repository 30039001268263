import styled, { keyframes, css } from "styled-components";

export const RightCont = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-left: 40px;
  position: relative;

  @media (max-width: 1377px) {
    margin-left: 30px;
    justify-content: center;
  }

  @media (max-width: 1130px) {
    margin-left: 30px;
  }

  @media (max-width: 1040px) {
    margin-left: 0px;
  }

  @media (max-width: 750px) {
    div:last-child {
      margin-right: 0px;
    }
  }
`;

interface props {
  img: string;
}

const moveUp = keyframes`
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
`;

const moveUpTwo = keyframes`
  0% {
    transform: translateY(75%);
  }
  100% {
    transform: translateY(0);
  }
`;

const moveUpThree = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

interface aniProps {
  inView: boolean;
  ani: string;
}

export const Div = styled.div<aniProps>`
  margin-left: 40px;
  animation: ${({ inView, ani }) =>
    inView && ani === "one"
      ? css`
          ${moveUp} 1s ease-out forwards;
        `
      : inView && ani === "two"
      ? css`
          ${moveUpTwo} 1s ease-out forwards;
        `
      : inView && ani === "three"
      ? css`
          ${moveUpThree} 1s ease-out forwards;
        `
      : "none"};

  @media (max-width: 1437px) {
    margin-left: 20px;
  }

  @media (max-width: 1377px) and(min-width:1170px) {
    width: 30%;
  }

  @media (max-width: 750px) {
    margin-left: 0px;
    margin-right: 20px;
    margin-bottom: 40px;
  }
`;

export const Card = styled.div<props>`
  background-image: ${({ img }) => `url(${img})`};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  width: 304px;
  height: 400px;
  background-size: contain;
  position: relative;

  @media (max-width: 1377px) and(min-width:1170px) {
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  h1 {
    position: relative;
    z-index: 100;
    font-family: "Anton";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #f8f8f8;

    @media (max-width: 750px) {
      font-size: 38px;
      line-height: 48px;
    }
  }

  h2 {
    position: relative;
    z-index: 100;

    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #f8f8f8;
    font-family: "Poppins";
    font-style: normal;
    margin-top: 6px;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f8f8f8;
    margin-top: 16px;

    @media (max-width: 750px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  div {
    position: relative;
    z-index: 100;

    max-height: 0px;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s linear;
  }

  :hover {
    div {
      max-height: 300px;
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const Background = styled.div`
  background: rgba(245, 34, 24, 0.6);

  min-width: 100%;
  min-height: 100%;

  position: absolute !important;
  bottom: 0px;
  z-index: 50 !important;
  left: 0px;
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 18px 0 58px;

  @media (max-width: 750px) {
    padding: 18px 0 0;
  }

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f52218;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const LinksLeft = styled.div`
  display: flex;
  div {
    position: relative;
    width: 28px;
    margin-right: 20px;

    img {
      position: absolute;
    }
  }

  .image-container {
    position: relative;
    width: 29px; // change these values according to your image size
    height: 29px; // change these values according to your image size
    margin-bottom: 15px;

    @media (max-width: 800px) {
      margin-bottom: 0px;
      margin-right: 15px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.2s;
      cursor: pointer;
    }

    img:first-child {
      z-index: 1;
    }

    img:nth-child(2) {
      z-index: 0;
    }

    &:hover img:first-child {
      opacity: 0;
    }
  }
`;
