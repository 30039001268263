import styled, { keyframes, css } from "styled-components";

export const ServicesGridContainer = styled.div`
  padding: 0 64px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div:last-child {
    opacity: 0;
    pointer-events: none;
  }

  div:nth-last-child(2) {
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: 800px) {
    padding: 0 16px 50px;

    div:last-child {
      display: none;
    }

    div:nth-last-child(2) {
      opacity: 1;
      pointer-events: unset;
    }
  }

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

export const ServicesGridItem = styled.div<props>`
  flex-basis: 33.33%;
  margin-bottom: 20px;
`;

export const ServicesGridLastRow = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  ${ServicesGridItem} {
    flex-basis: unset;
  }
`;
interface props {
  img: string;
  inView: boolean;
  ani: string;
}

const moveUp = keyframes`
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
`;

const moveUpTwo = keyframes`
  0% {
    transform: translateY(75%);
  }
  100% {
    transform: translateY(0);
  }
`;

const moveUpThree = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Box = styled.div<props>`
  min-height: 340px;
  @media (min-width: 800px) {
    animation: ${({ inView, ani }) =>
      inView && ani === "one"
        ? css`
            ${moveUp} 1s ease-out forwards;
          `
        : inView && ani === "two"
        ? css`
            ${moveUpTwo} 1s ease-out forwards;
          `
        : inView && ani === "three"
        ? css`
            ${moveUpThree} 1s ease-out forwards;
          `
        : "none"};
  }

  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: ${({ img }) => `url(${img})`};
  /* margin-right: 16px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.2s linear;
  width: 32.5%;
  margin-bottom: 16px;

  @media (max-width: 850px) {
    width: 48%;
    min-height: 451px;
    max-height: 451px;
  }

  @media (max-width: 560px) {
    width: 343px;
    margin: 10px auto;
  }

  @media (max-width: 375px) {
    width: 100%;
    margin: 10px auto;
  }

  padding: 24px;
  position: relative;
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #f8f8f8;
    margin-bottom: 8px;

    position: relative;
    z-index: 99;
    @media (max-width: 650px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #f8f8f8;

    position: relative;
    z-index: 99;
    @media (max-width: 650px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  div:nth-child(3) {
    display: flex;
    max-height: 0px;
    justify-content: space-between;
    transition: 0.2s linear;
    opacity: 0%;
    position: relative;
    z-index: 99;

    p {
      font-weight: 600;
    }
  }
  :hover {
    cursor: pointer;
    div {
      max-height: 100px;
      opacity: 100%;
      margin-top: 10px;
    }

    div:nth-child(4) {
      opacity: 100%;
    }
  }

  div:nth-child(4) {
    transition: 0.1s linear;
    opacity: 0%;
  }

  &:hover::after {
    content: "";
    background: rgba(245, 34, 24, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
`;

export const Background = styled.div`
  background: rgba(245, 34, 24, 0.6);

  min-width: 100%;
  min-height: 100%;

  position: absolute;
  bottom: 0px;
  z-index: 50;
  left: 0px;
`;
