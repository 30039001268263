import { Button, OtherButton, QuestionOneContainer } from "../styles";

const QuestionThree = ({ setData, data, setStep }: any) => {
  const onClick = (x: string) => {
    setData({ ...data, annualRent: x });
    setStep(3);
  };

  return (
    <QuestionOneContainer>
      <h3>HOW MUCH IS YOUR ANNUAL RENT?</h3>
      <div>
        <Button onClick={() => onClick("Below £20,000")}>Below £20,000</Button>
        <Button onClick={() => onClick("Between £20,000 - £40,000")}>
          Between £20,000 - £40,000
        </Button>
        <Button onClick={() => onClick("Between £40,000 - £75,000")}>
          Between £40,000 - £75,000
        </Button>
        <Button onClick={() => onClick("£75,000 +")}>£75,000 +</Button>

        <OtherButton onClick={() => onClick("Unsure")}>Unsure</OtherButton>
      </div>
    </QuestionOneContainer>
  );
};

export default QuestionThree;
