import styled, {keyframes, css} from "styled-components";
import img from "../../../assets";

interface propsSlide {
  isVisible: boolean;
}

const slideUp = keyframes`
  
  0%{
    opacity: 1;
    transform: translateY(30%)
  }

  100%{
    opacity: 1;
    transform: translateY(0)
  }
`;

export const TestimonialsContainer = styled.div<propsSlide>`
  background-image: url(${img.testimonialBg});
  background-size: cover;
  background-repeat: no-repeat;

  height: 463px;
  margin: -240px 64px 0;
  overflow-y: hidden;
  background-position: center center;
  transform: translateY(40%);
  ${({isVisible}) =>
    isVisible &&
    css`
      animation: ${slideUp} 1s ease-in-out forwards;
    `}
  @media (max-width: 800px) {
    height: 568px;
  }

  @media (max-width: 500px) {
    background-image: url(${img.testimonialMobile});
    margin: -285px 16px 0;
  }
`;

export const InnerDiv = styled.div`
  position: absolute;
  max-width: calc(100% - 462px);

  @media (max-width: 1250px) {
    max-width: calc(100% - 128px);
  }
  @media (max-width: 915px) {
    max-width: calc(100% - 32px);
  }
  /* margin: 0 auto; */
  color: #f8f8f8;
`;

const fadeIn = keyframes`
  
  0%{
    opacity: 0;

  }

  100%{
    opacity: 1;

  }
`;

export const Inner = styled.div`
  background: linear-gradient(
    0deg,
    rgba(164, 21, 15, 0.6),
    rgba(164, 21, 15, 0.6)
  );
  min-width: 100%;
  min-height: 100%;
  padding: 82px 231px;
  position: relative;

  @media (max-width: 1250px) {
    padding: 82px 64px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${fadeIn} 2s ease-in-out forwards;

  @media (max-width: 915px) {
    padding: 49px 16px;
  }
  div {
    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 16px;
      @media (max-width: 800px) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    h5 {
      font-weight: 400 !important;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 21px;
      }

      span {
        font-weight: 600;
      }
    }
  }
`;

export const LinesDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface props {
  selected: boolean;
}

export const Line = styled.div<props>`
    min-width: 30%
    height: 6px;
margin: 0 !important;
width: 32%;
    min-height: 6px;
    transition: 0.2s linear;
    background-color: ${({selected}) =>
      (selected && "#F8F8F8") || (!selected && "rgba(248, 248, 248, 0.3)")};
      
      :hover{
        cursor: pointer;
      }
      `;
