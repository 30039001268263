import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface props {
  page: boolean;
}

export const FormContainer = styled.div<props>`
  padding: ${({ page }) =>
    (!page && "177px 80px 150px") || (page && "71px 80px 20px")};

  @media (max-width: 1100px) {
    padding: 177px 20px 176px;
    padding: ${({ page }) =>
      (!page && "177px 20px 150px") || (page && "71px 16px 20px")};
  }

  @media (max-width: 550px) {
    padding: ${({ page }) =>
      (!page && "135px 16px 130px") || (page && "71px 16px 20px")};
  }

  div:first-child {
    animation: 0.5s ${fadeIn} ease-in;
    max-width: 600px;
    overflow: hidden;
    position: relative;
    max-height: 524px;

    @media (max-width: 550px) {
      max-height: none;
    }
  }

  h3 {
    font-family: "Anton";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #133244;
    margin-bottom: 40px;

    @media (max-width: 800px) {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
  }
`;

export const Button = styled.button`
  border: 1px solid #f52218;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f52218;
  transition: 0.1s linear;
  width: 48%;
  height: 64px;
  margin-bottom: 25px;
  background: none;

  @media (max-width: 550px) {
    width: 100%;
  }
  :hover {
    cursor: pointer;
    background: #a4150f;
    color: #f8f8f8;
  }
`;

export const OtherButton = styled(Button)`
  width: 100%;
`;

export const Continue = styled(Button)`
  width: 186px;
  color: #f8f8f8;
  background: #f52218;
  :hover {
    background: #a4150f;
  }
`;

export const QuestionOneContainer = styled.div`
  max-width: 600px;

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const QuestionTwoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    border: 1px solid #b5c6ce;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #133244;
    padding: 10px 16px;
    @media (max-width: 550px) {
      font-size: 14px;
    }
  }

  textarea {
    width: 100%;
    margin-bottom: 56px;
    background: #f8f8f8;
    border: 1px solid #b5c6ce;
    font-size: 16px;
    line-height: 24px;
    height: 100px;
    padding: 10px 16px;

    :focus {
      outline: none;
    }
  }
`;

export const QuestionFiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    width: 100%;

    @media (max-width: 550px) {
      gap: 16px;
    }
  }
`;

export const QuestionFiveInputsWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 550px) {
    flex-direction: column;
    gap: 16px;
  }

  > div {
    width: 100%;
    border: 1px solid #b5c6ce;

    > p {
      width: 100%;
      border: none;
      font-size: 16px;
      line-height: 24px;
      color: #133244;
      padding: 10px 16px;
      border-bottom: 1px solid #b5c6ce;
    }

    > input {
      width: 100%;
      background: #f8f8f8;
      font-size: 14px;
      line-height: 21px;
      border: none;
      border-top: 1px solid #b5c6ce;
      padding: 12px 16px;
      border-top: none;
      :focus {
        outline: none;
      }
    }
  }
`;

export const NewsletterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap !important;

  input {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  p {
    @media (max-width: 931px) {
      margin-right: 10px;
    }

    @media (max-width: 550px) {
      font-size: 14px;
    }
  }
`;

export const QuestionSixContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #657a85;
    margin-bottom: 50px;
  }
`;
