import styled, { keyframes, css } from "styled-components";

const slideIn = keyframes`
from{
transform: translateX(200%);
opacity: 0%;
}

to{

  transform: translateX(0%);
opacity: 100%;
}
`;

interface props {
  loaded: boolean;
}

export const IconsContainer = styled.div<props>`
  ${({ loaded }) =>
    !loaded &&
    css`
      animation: ${slideIn} 1s 2s forwards;
      opacity: 0%;
    `}
  opacity: ${({ loaded }) => (loaded ? "100%" : "0%")};

  display: flex;
  flex-direction: column;
  position: absolute;
  right: 64px;
  top: 240px;

  transform: ${({ loaded }) => (loaded ? "none" : "translateY(-50%)")};

  @media (max-width: 990px) {
    flex-direction: row;
    position: initial;
    transform: none;
    margin-top: 46px;
    margin-left: 22px;
  }

  .image-container {
    position: relative;
    width: 29px; // change these values according to your image size
    height: 29px; // change these values according to your image size
    margin-bottom: 15px;

    @media (max-width: 800px) {
      margin-bottom: 0px;
      margin-right: 15px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.2s;
      cursor: pointer;
    }

    img:first-child {
      z-index: 1;
    }

    img:nth-child(2) {
      z-index: 0;
    }

    &:hover img:first-child {
      opacity: 0;
    }
  }
`;
