import styled from "styled-components";

export const ServicesContainer = styled.div`
  margin: 83px 64px 82px;

  @media (max-width: 800px) {
    margin: 0px 16px 0px;
  }
`;

export const Header = styled.h1`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.06em;
  color: #133244;
  width: fit-content;

  @media (max-width: 650px) {
    font-size: 32px;
    line-height: 42px;
    max-width: 286px;
  }
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ScrollableDiv = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-top: 0px;
  max-height: 1000px;
  overflow-y: hidden;
  margin-bottom: 0px;
  ::-webkit-scrollbar {
    display: none;
  }

  div:last-child {
    margin-right: 0px;
  }

  @media (max-width: 650px) {
    max-height: 1000px;
  }
`;

interface props {
  img: string;
}

export const Box = styled.div<props>`
  min-width: 426px;
  min-height: 451px;
  overflow: hidden;
  background-size: cover;
  background-image: ${({ img }) => `url(${img})`};
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.2s linear;

  @media (max-width: 650px) {
    min-width: 343px;
    max-width: 343px;
    min-height: 451px;
    max-height: 451px;
  }

  padding: 24px;
  position: relative;
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #f8f8f8;
    margin-bottom: 8px;

    position: relative;
    z-index: 99;
    @media (max-width: 650px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #f8f8f8;

    position: relative;
    z-index: 99;
    @media (max-width: 650px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  div:nth-child(3) {
    display: flex;
    max-height: 0px;
    justify-content: space-between;
    transition: 0.2s linear;
    opacity: 0%;
    position: relative;
    z-index: 99;

    p {
      font-weight: 600;
    }
  }
  :hover {
    cursor: pointer;
    div {
      max-height: 100px;
      opacity: 100%;
      margin-top: 10px;
    }

    div:nth-child(4) {
      opacity: 100%;
    }
  }

  div:nth-child(4) {
    transition: 0.1s linear;
    opacity: 0%;
  }
`;

export const Background = styled.div`
  background: rgba(245, 34, 24, 0.6);

  min-width: 100%;
  min-height: 100%;

  position: absolute !important;
  bottom: 0px;
  z-index: 50 !important;
  left: 0px;
`;

export const BarCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LineCont = styled.div`
  width: 80vw;
  background: #b5c6ce;
  height: 1px;
  position: relative;

  @media (max-width: 650px) {
    margin: auto;
    width: 70vw;
  }

  div {
    min-width: 10%;
    height: 3px;
    background-color: #133244;
    position: absolute;
    top: -1px;
    max-width: 100%;
  }
`;

export const ArrowCont = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  @media (max-width: 650px) {
    width: calc(100% - 32px);
    position: absolute;
    margin-left: 0px;
    justify-content: space-between;
  }
  img:first-child {
    transform: rotate(180deg);
    margin-right: 20px;
    @media (max-width: 650px) {
      left: 0px;
      margin-right: 0px;
    }
  }
`;

interface arrowProps {
  inactive: boolean;
}

export const Arrow = styled.img<arrowProps>`
  :hover {
    cursor: ${({ inactive }) =>
      (inactive && "default") || (!inactive && "pointer")};
  }
`;

export const DesktopButton = styled.button`
  width: 198px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f52218;
  background: none;
  border: 1px solid #f52218;
  border-radius: 4px;
  transition: 0.1s linear;
  :hover {
    cursor: pointer;
    background: #a4150f;
    color: #f8f8f8;
  }
`;

interface btnProps {
  img: string;
  img2: string;
}

export const ImgButton = styled.div<btnProps>`
  border: 1px solid #f52218;
  width: 49px;
  height: 50px;
  background-image: ${({ img }) => `url(${img})`};
  background-size: 38%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s linear;
  :hover {
    cursor: pointer;
    background: #a4150f;
    background-image: ${({ img2 }) => `url(${img2})`};
    background-size: 38%;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
