import styled from "styled-components";

export const TeamCont = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 78px 64px 161px;

  @media (max-width: 1040px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    padding: 50px 16px 90px;
  }
`;

export const Header = styled.h1`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.06em;
  color: #133244;
  margin-bottom: 14px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #657a85;
  margin-bottom: 20px;
`;

export const Left = styled.div`
  min-width: 237px;
  max-width: 237px;
  overflow: hidden;

  @media (max-width: 1040px) {
    max-width: 100%;
    margin-bottom: 50px;
  }
`;

export const ButtonCont = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
  }

  div {
    background: #a4150f;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 18px;
    }
  }
`;

export const RedBtn = styled.button`
  background: #f52218;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #f8f8f8;
  width: 160px;
  border: none;
  transition: 0.05s linear;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 168px;
  }

  &:hover {
    cursor: pointer;
    width: 200px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #a4150f;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.1s ease-in-out;
    z-index: 1;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  span {
    position: relative;
    z-index: 2;
  }
`;

export const Image = styled.img`
  position: absolute;
  right: 0px;
  bottom: 16px;
  z-index: -1;
  @media (max-width: 600px) {
    display: none;
  }
`;
