import styled, { keyframes, css } from "styled-components";

export const ServicePageCont = styled.div`
  padding: 72px 64px 50px;

  @media (max-width: 800px) {
    padding: 47px 16px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 72px;

  div {
    p:first-child {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding-bottom: 0px;
  }
`;

const slideLeft = keyframes`
from{opacity: 0;
transform: translateX(-100%);

}


to{
  opacity: 1;
transform: translateX(0%);

}`;

const slideRight = keyframes`
from{opacity: 0;
transform: translateX(100%);

}


to{
  opacity: 1;
transform: translateX(0%);

}`;

export const Header = styled.h1`
  animation: ${slideLeft} 1s forwards;

  opacity: 0;

  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: 0.06em;
  color: #133244;
  margin-right: 30px;
  @media (max-width: 800px) {
    margin-bottom: 15px;
  }
  @media (max-width: 500px) {
    margin: 0 0px 16px;
    font-size: 48px;
    line-height: 56px;
  }
`;
export const SmallerText = styled.p`
  font-weight: 400;
  font-size: 16px;

  line-height: 24px;
  color: #657a85;
  max-width: 616px;

  animation: ${slideRight} 1s forwards;
  @media (max-width: 800px) {
    animation: ${slideLeft} 1s forwards;
  }

  opacity: 0;

  @media (max-width: 500px) {
    margin: 0 0px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Bottom = styled(Top)`
  div:first-child {
    h1 {
      display: none;
    }
  }
`;

interface props {
  inView: boolean;
}

export const FormCont = styled.div<props>`
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  animation: ${({ inView }) =>
    inView
      ? css`
          ${slideRight} 1s forwards
        `
      : "none"};
`;
