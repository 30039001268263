import styled from "styled-components";

export const Cont = styled.div`
  padding: 150px 64px;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    padding: 150px 16px;
  }
  h1 {
    font-family: Anton;
    font-size: 64px;
    font-weight: 400;
    line-height: 84px;
    letter-spacing: 0.06em;
    text-align: left;
    color: rgba(19, 50, 68, 1);
  }
`;
