import { Loading, Notify } from "notiflix";
import { useState } from "react";
import { emailValidation } from "../../../../services/emailValidation/EmailValidation";
import { phoneValidation } from "../../../../services/phoneValidation/phoneValidation";
import sendEnquiry from "../../../../utils/sendEnquiry";

import {
  Continue,
  NewsletterContainer,
  QuestionFiveContainer,
  QuestionFiveInputsWrapper,
} from "../styles";

const QuestionFive = ({ setData, data, setStep }: any) => {
  const [newsletter, setNewsletter] = useState(false);
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const onChange = (e: any) => {
    const { value, id } = e.target;

    if (id === "firstName" || id === "lastName") {
      let tester = false;

      tester = /^[a-zA-Z\-]+$/.test(value);

      if (tester || value === "") {
        setDetails({ ...details, [id]: value });
      }
    } else if (id === "phone") {
      let tester = false;

      tester = /^[0-9\+][0-9]*$/.test(value);

      if (tester || value === "") {
        setDetails({ ...details, [id]: value });
      }
    } else {
      setDetails({ ...details, [id]: value });
    }
  };

  const handleNewsletter = (e: any) => {
    setNewsletter(e.target.checked);
  };

  const handleSubmit = async () => {
    Loading.standard();
    //let validPhone = await phoneValidation(parseInt(details.phone));
   // let validEmail = await emailValidation(details.email);
   
    let validPhone = parseInt(details.phone);
    let validEmail = details.email;

    if (!validEmail && !validPhone) {
      Loading.remove();
      Notify.failure("Please make sure your details are correct");
    } else if (!validEmail && validPhone) {
      Loading.remove();
      Notify.failure("Please make sure your email is correct");
    } else if (validEmail && !validPhone) {
      Loading.remove();
      Notify.failure("Please make sure your phone number is correct");
    } else if (
      details.firstName.length === 0 &&
      details.lastName.length === 0
    ) {
      Loading.remove();
      Notify.failure("Please make sure you have input your name");
    } else if (validEmail && validPhone) {
      const obj = { ...data, ...details, subscribed: newsletter };
      setData({ ...data, ...details, subscribed: newsletter });
      sendEnquiry(obj)
        .then((res: any) => {
          setStep(5);
          Loading.remove();
        })
        .catch((err: any) => {
          Loading.remove();
          Notify.failure("Sorry, Something went wrong submitting enquiry");
        });
    }
  };

  return (
    <QuestionFiveContainer>
      <h3>PLEASE TELL US A LITTLE MORE ABOUT YOU</h3>
      <div>
        <QuestionFiveInputsWrapper>
          <div>
            <p>First name</p>
            <input
              placeholder="Enter your first name..."
              onChange={onChange}
              value={details.firstName}
              id="firstName"
              maxLength={25}
            />
          </div>

          <div>
            <p>Surname</p>
            <input
              placeholder="Enter your surname..."
              onChange={onChange}
              value={details.lastName}
              id="lastName"
              maxLength={25}
            />
          </div>
        </QuestionFiveInputsWrapper>
        <QuestionFiveInputsWrapper>
          <div>
            <p>Email address</p>
            <input
              placeholder="Enter your email address..."
              onChange={onChange}
              value={details.email}
              id="email"
              maxLength={50}
            />
          </div>
          <div>
            <p>Contact number</p>
            <input
              placeholder="Enter your contact number..."
              onChange={onChange}
              value={details.phone}
              id="phone"
              maxLength={15}
            />
          </div>
        </QuestionFiveInputsWrapper>
      </div>
      <NewsletterContainer>
        {" "}
        <input type="checkbox" onChange={handleNewsletter} />
        <p>Stay up to date by subscribing to our newsletter</p>
      </NewsletterContainer>
      <Continue onClick={handleSubmit}>Submit Enquiry</Continue>
    </QuestionFiveContainer>
  );
};

export default QuestionFive;
