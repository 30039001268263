import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Footer from "./layout/footer";

import Header from "./layout/header";
import NotFoundPage from "./pages/404";
import AboutPage from "./pages/about";

import ContactPage from "./pages/contact";
import IndividualNews from "./pages/individualNews";
import IndividualService from "./pages/individualServ";

import LandingPage from "./pages/landing/landing";
import NewsPage from "./pages/news";
import ServicesPage from "./pages/services";
import { updateLoaded } from "./redux/reducers/loaded";

function App() {
  // Scroll to Top after refreshing the page
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/services/:service" element={<IndividualService />} />
          <Route path="/news/:news" element={<IndividualNews />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
