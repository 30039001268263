import styled, { keyframes, css } from "styled-components";

export const Container = styled.div``;

const slideUp = keyframes`
  from{
    opacity: 0;
    transform: translateY(30%)
  }

  to{
    opacity: 1;
    transform: translateY(0%)
  }
`;

const slideUpRed = keyframes`
  from{
    opacity: 0;
    transform: translateY(500%)
  }

  to{
    opacity: 1;
    transform: translateY(0%)
  }
`;

interface props {
  inView: boolean;
  del: string;
}

export const RedCont = styled.div<props>`
  display: flex;
  justify-content: space-between;
  margin: 0 64px;
  flex-wrap: wrap;
  @media (min-width: 1500px) {
    max-width: 1400px;
    margin: 0 auto;
  }

  opacity: 0;
  transition: opacity 0.5s ease-out; /* Add a transition for smoother opacity change */
  text-transform: uppercase;
  ${({ inView, del }) =>
    inView &&
    css`
      opacity: 1;
      transition-delay: ${del}s; /* Add a transition delay to synchronize with the animation */
      * {
        animation: ${slideUpRed} 1s forwards;
      }
    `}

  @media (max-width: 800px) {
    margin: 0 16px;
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
  p {
    font-family: "Anton";
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(245, 34, 24, 1);
    margin-bottom: 20px;
    white-space: nowrap;

    @media (max-width: 600px) {
      white-space: normal;
      font-size: 38px;
      line-height: 48px;
    }
  }
`;

export const Text = styled.div<props>`
  display: flex;
  justify-content: space-between;
  margin: 30px 64px 50px;

  @media (min-width: 1500px) {
    max-width: 1400px;
    margin: 40px auto;
  }

  opacity: ${({ inView }) =>
    inView ? 1 : 0}; // Updated opacity based on inView

  ${({ inView }) =>
    inView &&
    css`
      * {
        animation: ${slideUp} 1s forwards;
      }
    `}

  @media (max-width: 800px) {
    margin: 30px 16px 50px;
    flex-direction: column;
  }
  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(101, 122, 133, 1);
    margin-bottom: 20px;

    @media (max-width: 800px) {
      font-size: 14px;

      line-height: 22px;
    }
  }
`;

export const Left = styled.div`
  max-width: 616px;
  margin-right: 30px;
`;

export const Right = styled.div`
  max-width: 616px;
`;

export const WhatIsCont = styled.div`
  padding: 64px;
  background: rgba(19, 50, 68, 1);
  display: flex;
  justify-content: space-between;

  @media (max-width: 550px) {
    padding: 64px 0px;
  }

  @media (max-width: 800px) {
    padding-top: 0px;
    flex-direction: column-reverse;
  }

  h1 {
    animation: ${slideUp} 1s forwards;
    font-family: Anton;
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(248, 248, 248, 1);
    margin-bottom: 20px;

    @media (max-width: 550px) {
      margin: 0 16px 20px;
    }
    @media (max-width: 800px) {
      font-size: 38px;
      line-height: 48px;
    }
  }

  p {
    margin-bottom: 20px;
    @media (max-width: 550px) {
      margin: 0 16px 20px !important;
    }
  }

  p,
  li {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(248, 248, 248, 1);

    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 22px;
    }
    @media (max-width: 550px) {
      margin: 0 16px;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 20px;
  }

  li {
    font-weight: 600;
  }
`;

export const WhatisLeft = styled.div`
  width: 616px;
  min-width: 616px;

  margin-right: 20px;

  @media (max-width: 800px) {
    min-width: 100%;
    width: 100%;
  }

  button {
    @media (max-width: 550px) {
      margin-left: 16px;
    }
  }
`;

export const WhatisRight = styled.div`
  max-width: 616px;
  max-height: 100%;

  @media (max-width: 1090px) and (min-width: 800px) {
    display: none;
  }

  @media (max-width: 800px) {
    max-width: 100%;
    margin-bottom: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
