import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsOne = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(0));
  }, []);

  return (
    <NewsCont>
      <Header>
        CEDAR DEAN HAVE BEEN RECOGNISED AS ONE OF SUNDAY TIMES’ BEST PLACES TO
        WORK
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>May 25, 2023 - 2:30pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigOne : thoughtImg.sOne} />
      </ImageContainer>
      <BodyCont>
        <p>
          We are thrilled to share the exciting news that{" "}
          <b>
            Cedar Dean has been recognised as one of the Sunday Times’ Best
            Places to Work in 2023.
          </b>
          This prestigious accolade is a testament to our commitment to creating
          a supportive and engaging work environment for our team members.
        </p>
        <p>
          At Cedar Dean, we are the UK’s leading lease restructuring advisors,
          located in the heart of London. Our expertise lies in assisting
          corporate clients throughout their journey, providing invaluable
          insights and services that truly add value to their businesses. With
          over 20 years of experience and a deep understanding of the market, we
          offer unrivalled advice and tailor-made solutions to meet the specific
          needs of each client.
        </p>

        <p>
          What sets Cedar Dean apart is our team of exceptional individuals who
          embody our core values: win win win, working to the Nth degree,
          innovation, and integrity. These values form the foundation of our
          company culture and guide us in every project and interaction. We
          believe that our success lies in the talents and dedication of our
          employees. That is why we are always on the lookout for outstanding
          individuals to join our team and contribute to our continued growth.
        </p>

        <p>
          Working at Cedar Dean means being part of a dynamic and collaborative
          environment where your skills are valued, and your ideas are
          encouraged. We foster a culture of continuous learning and
          development, providing ample opportunities for personal and
          professional growth. With our commitment to work-life balance, we
          ensure that our employees have the flexibility and support they need
          to thrive both personally and professionally.
        </p>

        <p>
          As a member of our team, you will have the chance to work on
          challenging projects that make a real impact on our clients’
          businesses. You will collaborate with industry experts and be at the
          forefront of innovation in lease restructuring. Your contributions
          will be recognised and rewarded, and you will have the opportunity to
          forge a rewarding and fulfilling career with us as we grow and
          continue to support UK businesses.
        </p>

        <p>
          If you are interested in joining Cedar Dean and becoming part of our
          talented team, we would love to hear from you. Please don’t hesitate
          to reach out to us at{" "}
          <a href="mailto:info@cedardean.com">info@cedardean.com</a>. We welcome
          individuals from diverse backgrounds and experiences who are
          passionate about making a difference in the corporate landscape.
        </p>
        <p>
          To learn more about Cedar Dean’s recognition as one of the Sunday
          Times’ Best Places to Work in 2023, we invite you to read the full
          article on The Times website:
          <a
            href="https://www.thetimes.co.uk/static/best-places-to-work-2023"
            target="_blank"
          >
            {" "}
            https://www.thetimes.co.uk/static/best-places-to-work-2023
          </a>
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsOne;
