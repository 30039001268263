import values from "../../../assets/values";

const valuesData = [
  {
    img: values.trophy,
    header: "Win, Win, Win",
    text: "We focus on our clients’ desired outcome and will do our utmost to make your objectives a reality.",
  },
  {
    img: values.fingerprint,
    header: "Integrity",
    text: "We are upfront with our clients and put your business interests at the forefront of what we do.",
  },
  {
    img: values.bulb,
    header: "Innovation",
    text: "We always look to challenge or recreate the norm, so we can deliver your desired results, faster.",
  },
  {
    img: values.target,
    header: "Nth Degree",
    text: "We will always put the needs of our client first and work to the Nth degree to try to ensure their desired outcome.",
  },
];

export default valuesData;
