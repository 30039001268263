import styled from "styled-components";

export const LandingPageContainer = styled.div``;

interface props{
    show:boolean;
}

export const Placeholder = styled.div<props>`
    min-height: 100vh;
    min-width:100vw;
     background-color: #133244;
     position: absolute;
     top: 0;
     z-index: 999;
     display:${({show}) => !show && "none"};
`