import {useNavigate} from "react-router-dom";
import img from "../../../assets";
import {ButtonCont, RedBtn} from "./styles";

const RedButton = () => {
  const navigate = useNavigate();

  return (
    <ButtonCont onClick={() => navigate("/services")}>
      <RedBtn>
        <span>Find Out More</span>
      </RedBtn>
      <div>
        <img src={img.rightArrow} alt="rightArrow" />
      </div>
    </ButtonCont>
  );
};

export default RedButton;
