import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import {
  BodyCont,
  Div,
  Header,
  ImageContainer,
  NewsCont,
  SourcesCont,
} from "../styles";

const NewsNineteen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(77.5));
  }, []);

  return (
    <NewsCont>
      <Header>PASSIVE LANDLORD-ING IS OVER</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>May 25, 2021 - 1:59pm</p>
      </Div>
      <ImageContainer>
        <img
          src={width! > 450 ? thoughtImg.bigNineteen : thoughtImg.sNineteen}
        />
      </ImageContainer>
      <BodyCont>
        <h3>
          This year marks 20 years that I have been active in the UK commercial
          property market, specialising predominantly in Leisure and Retail.
          During that time, the theme for London especially has been the same.
        </h3>
        <p>
          The demand for property outstrips supply, rents go up and landlords
          can generally see their investment climb as passive investors.
        </p>
        <p>
          And in the last 10 years, since I started lease restructuring for
          tenants after the 2008 financial crash, I have become acutely aware of
          how the whole landlord and tenant system is floored. The keyword here
          is “system”, as most commercial leases are completely biased in favour
          of landlords.
        </p>
        <p>
          The COVID-19 pandemic has exposed this as a gaping hole in the
          commercial property world. It has been fascinating to see the reaction
          from landlords who are reeling from over 70% of tenants not paying the
          March quarter. There is no question that the whole industry is now
          facing a Black Swan. Nobody saw it coming but its arrival has
          highlighted the flaws in a model where upward only rent reviews mean
          landlords have no downside on their income. It is fair to say it’s a
          win-lose relationship. That is why it is no surprise we have seen
          rents rising to levels which were completely unsustainable (over 20%
          of turnover).
        </p>
        <p>
          One extreme example I have come across was a retail unit in a shopping
          centre selling cards. The rent was nearly £600,000 excluding service
          charge. That meant they would have to sell c.1000 cards just to pay
          rent and service charge! Landlords and their advisors will say this
          the fault of tenants who have driven the tone by a taking space at a
          new rent which sets a precedent for that area. This is the essence of
          comparable evidence, which has dictated the rent review system for
          decades by the RICS (Royal Institute of Chartered Surveyors).
        </p>
        <p>
          If a new tenant pays £100 per square foot and an existing tenant was
          paying £70, then their rent needs to go to £100 at rent review. This
          completely disregards what you do within retail and leisure and how
          affordable the rent is for that business. How can a coffee shop pay
          the same rent as a high-end jeweller in Mayfair? The answer is they
          can’t. The consequence is the street loses the tenants that can’t
          afford it and it becomes a pretty dull place – like most shopping
          centres and high streets in the UK.
        </p>
        <p>
          Ask yourself, why is it that so much of retail and leisure vibrancy
          comes from street markets and pop-ups, run by new start-ups? This sums
          up the whole problem we face with the Coronavirus and perhaps a
          solution to those that listen to the following very sensible advice.
        </p>

        <h3>RELATIONSHIPS AND AFFORDABILITY</h3>
        <p>
          The majority of Landlords have not maintained true relationships with
          their tenants and have shown little interest in how affordable the
          rent was for them. Landlords have used efficient managing agents to
          ensure every detail of the lease is adhered to – as a reminder of
          their dominance in the relationship.
        </p>
        <p>
          I had an example of this recently where the managing agent of my
          office building in Soho, did a surprise inspection of our office and
          insisted I took a picture down in the common parts, as it did not
          comply with the lease. My reaction was to call the landlord directly
          and, after a sensible chat, the picture remained.
        </p>
        <p>
          This is a small symptom of a much larger problem in the industry where
          landlords run the relationship with their tenants in an efficient way
          as opposed to an effective way. Efficiently run means, they must
          adhere toevery term in the lease, whereas effective means achieving an
          objective which, for most landlords, should be to get the rent paid.
        </p>
        <p>
          Managing agents have kicked into efficiency overdrive with emails that
          read “let me remind you of your obligations etc”. Others are staying
          quiet trying to see how long this will last and praying it goes back
          to the way it was. Then you have landlords who think monthly rents or
          rent deferment will actually help!
        </p>
        <h3>I WOULD RESPOND TO LANDLORDS WITH THE FOLLOWING ADVICE:</h3>
        <p>
          Firstly, put your managing agents on ice. They don’t have the right
          skills to handle your tenants at this painful time. Please remember
          virtually all your tenants are now insolvent and if you push them too
          far, they will instruct an insolvency practitioner and – either give
          up on reopening – or come to you with much tougher terms out of
          administration, where they can simply disclaim the lease.
        </p>
        <p>
          Secondly, understand each of your tenants’ action plans, how are they
          going to survive this and how can you be a part of their survival. For
          this, you really need to understand their business model and what is
          going to be affordable for them. Any landlord who thinks turnover will
          resume back to normal after tenants reopen is simply delusional. Most
          businesses will be in the Economic ICU for up to two years and sadly,
          many good businesses will still not survive.
        </p>
        <h3>
          “THE MORE LOYALTY YOU SHOW YOUR TENANTS NOW, THE MORE THEY WILL STAND
          BY YOU AHEAD OF LANDLORDS WHO TURNED THEIR BACKS IN THEIR HOUR OF
          NEED.”
        </h3>
        <p>
          Thirdly, get the deals done now. Don’t wait to document rent-frees or
          reductions. The more loyalty you show your tenants now, the more they
          will stand by you ahead of landlords who turned their backs in their
          hour of need. I know for some of you this will mean taking a hit to
          your cash reserves – or a hard conversation with your bank – but if
          you want to come out of this with tenants that can survive, I suggest
          you get them the help they need. We are predicting a dramatic drop in
          rents over the next two years. It would be much better to give your
          tenants six to nine months rent-free now, over facing a big drop in
          rent that will hit your investment value.
        </p>
        <p>
          Finally, move away completely from rent reviews being led by Tone and
          comparable evidence. Build the relationship with your tenant and agree
          on a fair percentage of turnover that they can afford, and you will
          find a happy balance. Let’s not forget before banks and high leverage
          lending came in that’s how it was, landlords and tenants were partners
          and it was a win-win Relationship.
        </p>
        <p>
          In summary, I have been saying for many years now, it’s not just about
          “Location, Location, Location” but “Landlord, Landlord, Landlord.”
        </p>
        <p>
          To all the businesses who are struggling to know what to do at this
          time: whilst you are closed and stuck at home, my advice is to chose
          your property partners carefully. Act now to renegotiate rental terms
          with those landlords for whom the penny has finally dropped that you
          are the customer and they need to keep you happy – just how it’s done
          with all customers.
        </p>
        <p>Thanks for reading and stay safe,</p>
        <p>David</p>
        <p>
          PS For any confidential advice or support please email me on{" "}
          <a href="David@cedardean.com">David@cedardean.com</a>
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsNineteen;
