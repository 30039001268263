import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LandingContact from "../../components/landing/contact";
import Form from "../../components/landing/form";
import { RootState } from "../../redux";
import {
  Bottom,
  ContactPageCont,
  FormCont,
  Header,
  SmallerText,
  Top,
} from "./styles";

import { useInView } from "react-intersection-observer";
import { updateLoaded } from "../../redux/reducers/loaded";

const ContactPage = () => {
  const { loaded } = useSelector((state: RootState) => state.loaded);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("My Mail Function")
    window.scrollTo(0, 0);

    dispatch(updateLoaded());
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <ContactPageCont>
      <Top>
        <Header>GET IN TOUCH</Header>
        <SmallerText>
          Get in touch with us directly or leave a message in the form and we’ll
          be back in touch as soon as possible.
        </SmallerText>
      </Top>
      <Bottom>
        <LandingContact />
        <FormCont inView={inView} ref={ref}>
          <Form />
        </FormCont>
      </Bottom>
    </ContactPageCont>
  );
};

export default ContactPage;
