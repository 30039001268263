import styled from "styled-components";

export const Cont = styled.div``;

export const NewsCont = styled.div`
  padding: 64px 0;

  h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(19, 50, 68, 1);
    max-width: 850px;
    margin-bottom: 5px;
  }

  p {
    font-family: Poppins;
    max-width: 850px;
    color: rgba(101, 122, 133, 1);
    margin-bottom: 30px;

    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  a {
    color: var(--primary, #f52218);
  }

  h5 {
    color: var(--primary, #f52218);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 180% */
    margin-bottom: 8px;
  }

  img {
    width: 65%;
    margin-bottom: 10px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export const Header = styled.h1`
  font-family: Anton;
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0.06em;
  text-align: left;
  color: rgba(245, 34, 24, 1);
  max-width: 850px;
  margin: 0 64px;

  @media (max-width: 800px) {
    margin: 0 16px;
  }
`;

export const Div = styled.div`
  margin: 20px 64px 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    margin: 20px 16px 20px;
  }

  b {
    color: rgba(19, 50, 68, 1);
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100vw;

  img {
    width: 100vw;
  }
`;

export const BodyCont = styled.div`
  margin: 40px 64px 0;

  @media (max-width: 800px) {
    margin: 40px 16px 0;
  }

  h3:first-child {
    margin-bottom: 20px;
  }

  p:nth-child(3) {
    margin-bottom: 40px;
  }
`;

export const ProgCont = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 64px 100px;
  align-items: center;

  @media (max-width: 880px) {
    margin: 0 16px 100px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }

  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(19, 50, 68, 1);
  }

  @media (min-width: 800px) {
    div:first-child {
      width: 88px;
    }

    div:last-child {
      width: 108px;
    }
  }

  div {
    display: flex;
    align-items: center;

    justify-content: space-between;
    :hover {
      p {
        color: rgba(245, 34, 24, 1);
      }

      cursor: pointer;
    }
  }

  p {
    white-space: nowrap;
  }

  svg {
    min-width: 24px;
    min-height: 24px;
  }

  svg:first-child {
    transform: rotate(180deg);
  }
`;

export const ProgBar = styled.div`
  height: 1px;
  border: 0.6px solid rgba(181, 198, 206, 1);
  min-width: 70%;
  position: relative;

  @media (max-width: 800px) {
    min-width: 100%;
    margin: auto;
  }
`;

interface props {
  width: string;
}

export const Prog = styled.div<props>`
  transition: 0.2s linear;
  min-width: ${({ width }) => `${width}% !important`};
  width: ${({ width }) => `${width}% !important`};

  height: 3px;
  background-color: #133244;
  position: absolute;
  top: -1.5px;
  max-width: 100%;
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

export const SourcesCont = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1000px;
  a {
    padding: 16px 50px;
    border-radius: 4px;
    background: var(--primary, #f52218);
    color: var(--background, #f8f8f8);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    width: 178px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;

interface iArrow {
  disabled: boolean;
}

export const ArrowDiv = styled.div<iArrow>`
  pointer-events: ${({ disabled }) => disabled && "none"};
  opacity: ${({ disabled }) => (disabled && "0.5") || (!disabled && "1")};
`;
