import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import useWindowSize, { Size } from "../../../utils/getWindowSize";
import TeamRight from "./right";
import TeamRightMobile from "./rightMobile";
import { ButtonCont, Header, Left, RedBtn, TeamCont, Text } from "./styles";

const AboutTeam = () => {
  const navigate = useNavigate();

  const size: Size = useWindowSize();

  return (
    <TeamCont>
      <Left>
        <Header>OUR TEAM</Header>
        <Text>
          As individuals, we’ve amassed decades of experience working within the
          commercial property and leasing sectors.
        </Text>
        <Text>
          And as a team, Cedar Dean combines their expertise and years within
          the field to work on behalf of you and your business. We’re immensely
          committed to ensuring tenants across the UK receive the best advice
          possible, and that a positive result is secured for everyone involved.
        </Text>
        <Text>
          We may not be the most sizeable team, but we more than make up for it
          with our heart and determination to succeed.
        </Text>
        <Text>
          Discover more about the amazing work we conduct by clicking on any of
          the accompanying profiles here: or, be sure to get in touch with us
          today and one of the Cedar Dean team will be happy to help you.
        </Text>
        <ButtonCont onClick={() => navigate("/contact")}>
          <RedBtn>
            <span>Get In Touch</span>
          </RedBtn>
          <div>
            <img src={img.rightArrow} alt="rightArrow" />
          </div>
        </ButtonCont>
      </Left>
      {size.width! > 750 ? <TeamRight /> : <TeamRightMobile />}
    </TeamCont>
  );
};

export default AboutTeam;
