import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsSixteen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(64));
  }, []);

  return (
    <NewsCont>
      <Header>OUR COVID19 RENT SURVEY</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>June 25, 2021 - 1:20pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigSixteen : thoughtImg.sSixteen} />
      </ImageContainer>
      <BodyCont>
        <h3>September 2020 Covid 19 Rent Survey</h3>
        <h3>Question 1</h3>
        <p>
          Since the start of the pandemic, have you agreed new terms with
          the majority of your landlords?
        </p>
        <img src={thoughtImg.bar1} />
        <img src={thoughtImg.bar2} />
        <p>Total Respondents: 240</p>
        <h3>Question 2</h3>
        <p>
          Do you believe the new terms you have agreed with your landlord will
          be sufficient to support your business through the next phase of
          hospitality restrictions?
        </p>
        <img src={thoughtImg.bar3} />
        <img src={thoughtImg.bar4} />
        <p>Total Respondents: 233</p>
        <h3>Question 3</h3>
        <p>
          In light of this are you being forced to consider potential
          restructuring/insolvency options?
        </p>
        <img src={thoughtImg.bar5} />
        <img src={thoughtImg.bar6} />
        <p>Total Respondents: 240</p>
        <h3>Question 4</h3>
        <p>Have you or do you plan to pay the September Quarter rent?</p>
        <img src={thoughtImg.bar7} />
        <img src={thoughtImg.bar8} />
        <p>Total Respondents: 238</p>
        <h3>Question 5</h3>
        <p>Where are the majority of your businesses?</p>
        <img src={thoughtImg.bar9} />
        <img src={thoughtImg.bar10} />
        <p>Total Respondents: 239</p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsSixteen;
