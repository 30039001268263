import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LandingContact from "../../landing/contact";
import Form from "../../landing/form";
import { RootState } from "../../../redux";
import {
  Bottom,
  ServicePageCont,
  FormCont,
  Header,
  SmallerText,
  Top,
} from "./styles";

import { useInView } from "react-intersection-observer";

const NewsHero = () => {
  const { loaded } = useSelector((state: RootState) => state.loaded);

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <ServicePageCont>
      <Top>
        <Header>NEW THINKING</Header>
        <div>
          <SmallerText>
            Cedar Dean prides itself on constantly doing the best for its
            clients, as well as being an educational and advocatory body on
            modern renting and commercial lease issues. You can find out more
            about our work and major industry events in the articles below.
          </SmallerText>
        </div>
      </Top>
    </ServicePageCont>
  );
};

export default NewsHero;
