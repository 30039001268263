import { configureStore } from "@reduxjs/toolkit";

import loadedReducer from "./reducers/loaded";
import articlesReducer from "./reducers/articles";

// ...

export const store = configureStore({
  reducer: {
    loaded: loadedReducer,
    articles: articlesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
