import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsFive = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(18));
  }, []);

  return (
    <NewsCont>
      <Header>COULD A SUSTAINABLE LEASE SAVE YOUR LOCAL CHIPPY?</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>July 25, 2022 - 10:26am</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigFive : thoughtImg.sFive} />
      </ImageContainer>
      <BodyCont>
        <h3>
          Fish and chip shops are closing at a rate of ‘several a week’ as they
          struggle to keep up with astronomical growth in fish, energy, and
          cooking oil prices.
        </h3>
        <p>
          Rising prices are battering us all right now. Smaller businesses up
          and down our high streets are desperately trying to meet
          ever-increasing costs, while attempting to keep prices as low as
          possible for their customers. Fish and chip shops are closing at a
          rate of ‘several a week’ according to the{" "}
          <a href="https://www.nfff.co.uk/" target="_blank">
            {" "}
            National Federation of Fish Friers,
          </a>{" "}
          as they struggle to keep up with astronomical growth in fish, energy,
          and cooking oil prices.
        </p>
        <p>
          For independent businesses with bricks and mortar premises, rent costs
          are also a major factor to consider. The last thing small business
          owners need right now is their rent to increase at the point of their
          next rent review. That’s why we’re calling upon fish and chip shop
          owners, and any other business owners feeling the pinch of inflation,
          to start negotiating with their landlords now for a more sustainable
          and affordable lease.
        </p>

        <h3>Tough times hitting small businesses</h3>
        <p>
          As the cost of living bites and inflation reaches levels not seen for
          decades, a number of sectors are already feeling the pinch. Just as
          they were starting to recover from the nightmare that was the Covid-19
          pandemic, restaurants, pubs, retailers, leisure businesses and gyms
          are now on the front line when it comes to cost pressures.
        </p>
        <p>
          Businesses in these sectors are having to grapple with increased
          costs, but also fewer customers, as consumers attempt to tighten their
          belts in response to growing bills and prices. When consumers are
          faced with higher fuel bills and food costs, while salaries are
          falling, in real terms, what are they going to cut first? Most will
          look at non-essential spending, which means cutting back on eating
          out, buying new clothes and quitting their gym memberships.
        </p>
        <h3>A ‘profit crisis’ looms</h3>
        <p>
          <a
            href="https://www.alvarezandmarsal.com/insights/uk-retailers-face-profit-crisis-consumers-refuse-compromise"
            target="_blank"
          >
            Alvarez & Marsel
          </a>
          , alongside Retail Economics, recently undertook a study on the impact
          of the cost of living crisis on UK retailers. They predict that retail
          profit margins will fall to 5.1% this year, down from 8.3% in 2012.
        </p>
        <p>
          During times of higher costs, consumers often turn to bargain options,
          hoping to save a few pounds. As a result, we’re likely to see
          lower-cost grocery stores and fast-food outlets doing well. However,
          long gone are the days when fish and chips was a bargain meal for a
          family of four. These days, fish and chips can cost upwards of £10 per
          meal and people just aren’t willing to pay these prices. Chippy owners
          are all-too-aware of these price pressures, but their hands are tied
          as unavoidable costs just keep going up.
        </p>
        <p>
          If you’re looking for a solution to the current crisis, we believe
          that driving efficiencies will be the key to survival for many
          businesses on our high streets. And what better place to start than
          with your commercial lease. We want small businesses up and down the
          country to consider the impact a rent increase would have on them
          right now. Landlords are also facing increased costs and will try to
          up your rent if given the chance. We help businesses to negotiate more
          sustainable commercial leases, working with landlords to find a more
          affordable way forward.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsFive;
