import styled from "styled-components";

export const HelpContainer = styled.div`
  background: #ebf0f2;
  padding: 95px 64px 20px;

  @media (max-width: 800px) {
    padding: 73px 16px 20px;
  }
`;

export const Header = styled.h1`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #133244;
  @media (max-width: 800px) {
    font-size: 32px;
    line-height: 42px;
    text-align: left;
  }
`;

export const Lower = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #657a85;
  max-width: 887px;
  margin: auto;
  margin-top: 16px;
  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }
`;
