import { useNavigate } from "react-router-dom";
import { Button, QuestionOneContainer, QuestionSixContainer } from "../styles";

const QuestionSix = () => {
  const navigate = useNavigate();

  return (
    <QuestionSixContainer>
      <h3>WE'VE RECEIVED YOUR ENQUIRY!</h3>
      <p>
        A member of our specialist team will be in touch with you shortly to
        discuss your enquiry. In the meantime, explore what services we can
        offer to you.
      </p>
      <Button onClick={() => navigate("/services")}>View All Services</Button>
    </QuestionSixContainer>
  );
};

export default QuestionSix;
