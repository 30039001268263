import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsTen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(36));
  }, []);

  return (
    <NewsCont>
      <Header>WHY HAS THE GYM SECTOR BEEN LEFT OUT IN THE COLD?</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>March 7, 2022 - 12:51pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigTen : thoughtImg.sTen} />
      </ImageContainer>
      <BodyCont>
        <p>
          Over the 2021 festive period, hospitality and leisure businesses faced
          new challenges because of the Omicron variant. Chancellor Rishi Sunak
          responded to pressure from the sector with his Omicron business
          support package, which allocated around £1bn in support for businesses
          ‘most impacted’ by the omicron variant. Gyms, pools and leisure
          centres were not included, and this has left the industry reeling.
        </p>

        <h3>Gyms are struggling to pay rent</h3>
        <p>
          Our clients who operate gyms tell us that they faced an extremely slow
          start to the year as people continued to avoid public places and
          crowds. Restaurants, bars and pubs are obviously affected, but so are
          gyms. The UK gym industry body, UKactive, said that the decision to
          exclude the sector from the government funding would “lead to
          businesses that support the health and wellbeing of communities going
          to the wall.”
        </p>
        <p>
          UK gym operators are still having to cover their increasingly
          unaffordable rents and relationships between large gym chains,
          independent gyms, gym franchise owners and their landlords are
          breaking down. With no financial help coming from the government at
          all, paying the rent is a growing challenge.
        </p>
        <h3>Omicron hit gyms hard</h3>
        <p>
          Very few restrictions have officially been implemented in recent
          months, but public behaviour did change nonetheless. For much of
          December 2021, avoiding crowds and staying away from public spaces
          became the norm once more. The severity of Omicron wasn’t clear for a
          number of weeks and millions of people decided to play it safe by
          staying at home as much as possible.
        </p>
        <p>
          Come early January, things hadn’t improved much. At a time when people
          would usually be signing up for the gym and booking fitness classes to
          complement their New Year health kick, they were isolating, recovering
          from Covid or, if they hadn’t yet caught Omicron, they were avoiding
          gyms like the plague!
        </p>
        <p>
          Then, for more than a month, UK workers were told to work from home
          where possible. This means that town centre gyms saw their daytime
          customers disappear almost completely. If people weren’t going to
          work, then they were no longer doing a gym class during their lunch
          hour. They stopped using the gym before or after work and were no
          longer buying their coffee after their workout.
        </p>
        <p>
          Now, as we enter spring, all restrictions have been removed and many
          of us are finally feeling confident enough to get our lives back to
          normal, but the damage has already been done for many gym operators.
        </p>
        <p>
          Neil Randall, CEO of Anytime Fitness UK told us: “Plan B measures
          caused a significant drop in both club revenue and footfall, with
          traditionally busy city centre locations seeing as much as a 70% loss
          in footfall compared to same-month performance pre-pandemic.”
        </p>
        <p>
          Summing it up, he explained: “January is always our industry’s busiest
          month of the year and is a vital springboard to set our clubs up for
          the rest of the year. However, this was the second year in succession
          that our industry has been unable to capitalise on this pivotal time
          of year.”
        </p>
        <p>
          In light of all this, the notion that gyms and leisure centres are not
          among the businesses ‘most impacted by Omicron’ is madness. Indeed, Mr
          Randall, added: “It seems unfathomable that during an ongoing health
          crisis, the very facilities that pride themselves on improving the
          health of our population were left at the back of the queue once
          again.”
        </p>
        <h3>Restructuring commercial leases can help ease the burden</h3>
        <p>
          What landlords leasing premises to gyms need to understand is the
          pressure that the pandemic as a whole has put on gym operators’ bottom
          lines. After months of severe lockdowns, gym buffs turned to home
          workouts, outdoor exercise, Peloton and online fitness classes. But
          gyms will regain their appeal as normality returns.
        </p>
        <p>
          <a href="/">We help</a> our gym operator customers to improve their
          relationship with their landlords and restructure their commercial
          lease to make sure it’s sustainable over the long term. We’ve helped
          gym operators to achieve improved lease terms, reduced rents and even
          negotiate rent relief.
        </p>
        <p>
          Our gym customers trust us to help them save money on their commercial
          leases, which provides some much-needed financial breathing space
          while the sector recovers.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsTen;
