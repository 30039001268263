import styled, { keyframes, css } from "styled-components";

const slideUp = keyframes`
  from{
    opacity: 0;
    transform: translateY(30%)
  }

  to{
    opacity: 1;
    transform: translateY(0%)
  }
`;

interface props {
  inView: boolean;
}

export const AboutInfoCont = styled.div<props>`
  padding: 74px 64px 81px;
  opacity: ${({ inView }) =>
    inView ? 1 : 0}; // Updated opacity based on inView
  position: relative;
  @media (max-width: 800px) {
    padding: 72px 16px 96px;
  }

  ${({ inView }) =>
    inView &&
    css`
      * {
        animation: ${slideUp} 1s forwards;
      }
    `}
`;

export const Header = styled.h1`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.06em;
  color: #133244;
  max-width: 760px;
  margin-bottom: 39px;

  @media (max-width: 550px) {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 20px;
  }
`;

export const Div = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  div:first-child {
    margin-right: 40px;
  }

  div {
    max-width: 616px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #657a85;
      margin-bottom: 20px;

      @media (max-width: 550px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`;

export const ButtonCont = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
  }

  div {
    background: #a4150f;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 18px;
    }
  }
`;

export const RedBtn = styled.button`
  background: #f52218;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #f8f8f8;
  width: 180px;
  border: none;
  transition: 0.05s linear;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 168px;
  }

  &:hover {
    cursor: pointer;
    width: 220px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #a4150f;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.1s ease-in-out;
    z-index: 1;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  span {
    position: relative;
    z-index: 2;
  }
`;

export const Image = styled.img`
  position: absolute;
  right: 0px;
  bottom: 16px;
  z-index: -1;
  @media (max-width: 600px) {
    display: none;
  }
`;
