import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsTwo = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(4.5));
  }, []);

  return (
    <NewsCont>
      <Header>
        OXFORD STREET: FACING A PERIOD OF DECLINE AMIDST COVID-19 AND CHANGING
        RETAIL LANDSCAPE
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>April 27, 2023 - 9:19am</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigTwo : thoughtImg.sTwo} />
      </ImageContainer>
      <BodyCont>
        <p>
          Oxford Street, known as one of the most iconic shopping destinations
          in the UK, is currently facing a period of decline. The COVID-19
          pandemic has hit the high street hard, resulting in the closure of
          big-name brands such as Topshop, Miss Selfridge, Debenhams, Oasis, and
          Warehouse. This has caused vacancy levels to surge from 4.5% in 2019
          to 16.9% according to the Local Data Company (LDC).
        </p>

        <h3>COVID-19 and the Long-Term Problems of the High Street:</h3>
        <p>
          The pandemic has acted as a catalyst for the decline of Oxford Street,
          but it has also brought attention to the long-term issues that
          retailers face in competing with online fast fashion brands like
          Boohoo and PrettyLittleThing. These brands offer ultra-fast delivery
          times, which appeal to younger generations who enjoy showcasing their
          style on social media. Small businesses are also struggling due to
          rising business rates, energy costs, and inflation.
        </p>
        <h3>The Loss of Famous Brands and the Vacant Shells:</h3>
        <p>
          The closure of big-name brands like Topshop, House of Fraser, and
          Debenhams has left empty shells of once-ornate stonework stores. The
          iconic Oxford Circus flagship store is now vacant, although Ikea plans
          to take over the space later this year.
        </p>
        <h3>Adapting to the Changing Retail Landscape:</h3>
        <p>
          In these challenging times, it is essential for businesses to adapt to
          the changing retail landscape and secure their future success. They
          need to develop innovative strategies to attract customers and
          increase sales, such as investing in online sales channels, offering
          personalized customer experiences, and focusing on sustainable and
          ethical practices.
        </p>
        <p>
          In conclusion, Oxford Street is facing a period of decline amidst the
          COVID-19 pandemic and the changing retail landscape. The loss of
          big-name brands and the surge in vacancy levels highlights the need
          for businesses to adapt to secure their future success. By developing
          innovative strategies, businesses can attract customers, increase
          sales, and navigate through these challenging times.
        </p>
        <a href="/contact">We can help.</a>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsTwo;
