import {Button, OtherButton, QuestionOneContainer} from "../styles";

const QuestionOne = ({setData, data, setStep}: any) => {
  const onClick = (x: string) => {
    setData({...data, service: x});
    setStep(1);
  };

  return (
    <QuestionOneContainer>
      <h3>INTERESTED IN ONE OF OUR SERVICES?</h3>
      <div>
        <Button onClick={() => onClick("Restructuring Advice")}>
          Restructuring Advice
        </Button>
        <Button onClick={() => onClick("Acquisitions")}>Acquisitions</Button>
        <Button onClick={() => onClick("Lease Renewals")}>
          Lease Renewals
        </Button>
        <Button onClick={() => onClick("Lease Surrenders")}>
          Lease Surrenders
        </Button>
        <Button onClick={() => onClick("Rent Reviews")}>Rent Reviews</Button>
        <Button onClick={() => onClick("Rent Reductions")}>
          Rent Reductions
        </Button>
        <OtherButton onClick={() => onClick("Other")}>Other</OtherButton>
      </div>
    </QuestionOneContainer>
  );
};

export default QuestionOne;
