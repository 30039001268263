import { ServicesPageCont, TestimonialCont } from "./styles";

import ServicesHero from "../../components/services/top";
import ServicesGrid from "../../components/services/grid";
import LandingTestimonials from "../../components/landing/testimonials";
import LandingForm from "../../components/landing/landingForm";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateLoaded } from "../../redux/reducers/loaded";

const ServicesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(updateLoaded());
  }, []);

  return (
    <ServicesPageCont>
      <ServicesHero />
      <ServicesGrid />
      <TestimonialCont>
        <LandingTestimonials />
      </TestimonialCont>
      <LandingForm />
    </ServicesPageCont>
  );
};

export default ServicesPage;
