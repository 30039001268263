import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsSeven = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(27));
  }, []);

  return (
    <NewsCont>
      <Header>TURNOVER IS VANITY, PROFIT IS SANITY</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>May 4, 2022 - 2:06pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigSeven : thoughtImg.sSeven} />
      </ImageContainer>
      <BodyCont>
        <h3>Is business liquidation a threat despite high company turnover?</h3>
        <p>
          In the wake of the end of government business support and with the
          moratorium of commercial tenants’ evictions now over, times are still
          extremely tough for UK businesses. While the media is full of stories
          about the end of the pandemic and{" "}
          <a
            href="https://www.ft.com/content/0faecdca-bfed-4e55-9fa0-757cffbff295"
            target="_blank"
          >
            consumer spending
          </a>{" "}
          going through the roof, businesses are still struggling to survive.
        </p>
        <p>
          The issue is endlessly rising costs, alongside the end of all
          government business support. These two factors have combined as we
          move further into 2022 and the result is thousands of businesses
          unable to make their turnover translate to profit.
        </p>
        <p>
          While shops, restaurants, cafes, pubs and salons are bursting with
          customers, their overheads are reaching record levels. The sad reality
          is that a high turnover is not enough to keep a business out of
          liquidation in the current climate.
        </p>

        <h3>Backdated rent adds to business liquidation fears</h3>
        <p>
          A number of our clients are now paying their current rent, as well as
          backdated rent they were not able to cover over the past two years.
          This double whammy of rental costs is hitting businesses hard. Not
          least because they (like all of us) are also covering rising energy
          bills and taxation rises.
        </p>
        <p>
          <a
            href="https://www.fsb.org.uk/resource-report/sbi-q4-2021.html"
            target="_blank"
          >
            The Federation of Small Businesses
          </a>{" "}
          (FSB) has found that, although many businesses plan to grow over the
          coming 12 months, half are concerned about rising costs. The FSB
          Chairman, Mike Cherry, explained that “spiralling inflation, labour
          shortages and looming tax grabs” are hampering their efforts.
        </p>
        <p>
          As the moratorium on evictions comes to an end, we’re hearing that
          businesses all over the UK are having to repay rent that was unpaid
          during Covid lockdowns, or at other times during the past two years
          when money was too tight. Now that Covid restrictions have been lifted
          and government business support has all but disappeared, commercial
          tenants are on their own.
        </p>
        <p>
          While customers keep on coming through their doors, costs are higher
          than ever and many who have made it through the pandemic will fall at
          this last hurdle.
        </p>
        <h3>Businesses that were struggling in 2019 won’t survive this</h3>
        <p>
          We’ve been speaking to business owners up and down the country and
          we’re hearing the same stories. When it comes to Covid and the end of
          government business support, the recovery is maybe worse than the
          illness.
        </p>
        <p>
          Business insolvency levels in February 2022 were more than double the
          number recorded in the same month in 2021. This illustrates how
          difficult these months are going to prove for businesses as they find
          their way out of the pandemic without a safety net.
        </p>
        <p>
          As we progress through 2022 and even into 2023, it’s likely we could
          move into recession, which will further hit businesses where it hurts.
          On top of this, consumer spending is likely to fall as the rising cost
          of living continues to bite and households start to feel the pinch of
          the energy price cap increase in April.
        </p>

        <h3>How to avoid business liquidation</h3>
        <p>
          So what’s the solution? Energy costs, wages bills and taxation are all
          likely to rise unavoidably in the coming 6 months; but some costs,
          such as those involving commercial rent, can be controlled with the
          right experts on your side.
        </p>
        <p>
          We would like to see landlords beginning to see the light over these
          difficult months, increasingly writing off unpaid rent bills during
          the pandemic. Additionally, rent restructuring, with a view to
          creating more sustainable rental agreements between commercial tenants
          and landlords, simply must play a part in preventing unnecessary
          business insolvencies.
        </p>
        <p>
          If you’re a business owner whose rent bills and lease terms are
          threatening your future, get in touch today to talk over your options
          with our experts.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsSeven;
