import { useEffect, useState } from "react";
import img from "../../../assets";
import FooterSocials from "../../../layout/footer/socials";
import ContactIcons from "./icons";
import {
  ContactContainer,
  Container,
  ContainerTwo,
  Header,
  Inner,
  Smaller,
} from "./styles";

import { useInView } from "react-intersection-observer";
import { BackgroundImage } from "../hero/styles";
import useWindowSize, { Size } from "../../../utils/getWindowSize";

const LandingContact = () => {
  const [page, setPage] = useState(false);
  const size: Size = useWindowSize();

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/contact") {
      setPage(true);
    } else {
      setPage(false);
    }
  }, [window.location.pathname]);

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <ContactContainer page={page} ref={ref}>
      <Inner inView={inView}>
        <Header>FIND OUT HOW CEDAR DEAN CAN HELP YOU TODAY</Header>
        <Smaller>
          {page
            ? "Get in touch with us directly or leave a message in the form."
            : "Get in touch with us directly, or leave us a message using the contact form here: a member of our team will then be in touch with you soon to discuss your enquiry."}
        </Smaller>
        <Container>
          <img src={img.mail} alt="mail" />
          <div>
            <h3>General Support</h3>
            <a href="mailTo:info@cedardean.com">info@cedardean.com</a>
          </div>
        </Container>
        <Container>
          <img src={img.phone} alt="phone" />
          <div>
            <h3>Let's Talk</h3>
            <a href="tel:info@cedardean.com">(0) 207 100 6013</a>
          </div>
        </Container>{" "}
        <Container>
          <img src={img.location} alt="location" />
          <div>
            <h3>Address</h3>
            <p>
              30 Berwick Street,
              <br />
              Soho, London,
              <br />
              W1F 8RH
            </p>
          </div>
        </Container>{" "}
        <ContainerTwo>
          <img src={img.person} alt="person" />
          <div>
            <h3>Follow Us</h3>
            <ContactIcons />
          </div>
        </ContainerTwo>
      </Inner>
      <picture>
        <source srcSet={img.contactWebP} type="image/webp" />
        <BackgroundImage src={img.contactBg} loading="lazy" />
      </picture>
    </ContactContainer>
  );
};

export default LandingContact;
