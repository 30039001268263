import { Notify } from "notiflix";
import { useState } from "react";
import img from "../../assets";
import sendEmail from "../../utils/sendEmail";
import FooterAddress from "./address";
import FooterLinks from "./links";
import FooterSocials from "./socials";
import {
  BottomHalfContainer,
  FooterContainer,
  Header,
  InputDiv,
  Logo,
  SubText,
  TopHalfContainer,
  UnderneathCont,
} from "./styles";
import Touch from "./touch";
import { emailValidation } from "../../services/emailValidation/EmailValidation";

const terms = require("../../legals/terms.pdf");
const priv = require("../../legals/priv.pdf");

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleEmail = async () => {
    if (email.length > 0) {
      let validEmail = await emailValidation(email);

      if (validEmail) {
        sendEmail(email)
          .then((res: any) => {
            setEmail("");
            Notify.success(
              "You have successfully signed up to our newsletter!"
            );
          })
          .catch((err: any) => {
            Notify.failure(`Sorry, ${err.response?.data?.message}`);
          });
      } else {
        Notify.failure("Please enter a valid email address");
      }
    } else {
      Notify.failure("Please enter an email address");
    }
  };

  //TODO the email input
  return (
    <FooterContainer>
      <TopHalfContainer>
        <Header>STAY IN THE KNOW</Header>
        <SubText>
          Sign up to our newsletter to stay up to date on all our news and
          services with Cedar Dean.
        </SubText>
        <InputDiv>
          <input
            placeholder="Enter your email address..."
            value={email}
            maxLength={40}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div onClick={handleEmail}>
            <img src={img.rightArrow} alt="right arrow" />
          </div>
        </InputDiv>
        <Logo src={img.logo} />
      </TopHalfContainer>
      <BottomHalfContainer>
        <FooterLinks />
        <Touch />
        <FooterAddress />
        <FooterSocials />
      </BottomHalfContainer>
      <UnderneathCont>
        <div>
          <a href={terms} target="_blank">
            Terms & Conditions
          </a>
          <a href={priv} target="_blank">
            Privacy Policy
          </a>
        </div>
        <p>© Copyright 2023 Cedar Dean</p>
        <img
          src={img.opop}
          alt="opop"
          onClick={() => window.open("https://opopmedia.co.uk/", "_blank")}
        />
      </UnderneathCont>
    </FooterContainer>
  );
};

export default Footer;
