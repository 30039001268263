import styled, { keyframes, css } from "styled-components";

interface props {
  showing: boolean;
}

const fadeIn = keyframes`
    from{
        opacity: 0;

    }

    to{
        opacity: 1;

    }
`;

const fadeOut = keyframes`

from{

        opacity: 1;

    }

    to{
        opacity: 0;

    }`;

export const HeaderServCont = styled.div<props>`
  position: absolute;
  background: #f8f8f8;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  height: 508px;
  width: 836px;
  top: 83px;
  right: 0px;
  padding: 32px 64px 32px 32px;

  @media (max-width: 835px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    display: none;
  }

  /* :hover {
    border-bottom: none;

    opacity: 1;
    pointer-events: all;
  } */

  transition: 0.5s linear;
  visibility: ${(props) => (props.showing ? "visible" : "hidden")};
  opacity: ${(props) => (props.showing ? 1 : 0)};
  pointer-events: ${(props) => (props.showing ? "all" : "none")};

  ${(props) =>
    props.showing
      ? css`
          animation: ${fadeIn} 0.5s forwards;
        `
      : css`
          animation: ${fadeOut} 0.5s forwards;
        `}
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b5c6ce;
  padding-bottom: 24px;
  margin-bottom: 24px;
`;

export const MiddleRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b5c6ce;
  padding-bottom: 24px;
  margin-bottom: 24px;
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: flex-start;

  div:nth-child(2) {
    margin-left: 40px;
  }
`;

export const Box = styled.div`
  width: 220px;
  * {
    transition: 0.1s linear;
  }
  :hover {
    cursor: pointer;

    h2 {
      color: #f52218;
    }

    p {
      color: #f52218;
    }
  }
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #133244;
    }
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #657a85;
  }
`;

interface lineProps {
  showing: boolean;
}

const showAnimation = keyframes`
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 112.5px;
    opacity: 1;
  }
`;

const hideAnimation = keyframes`
  from {
    width: 112.5px;
    opacity: 1;
  }
  to {
    width: 0;
    opacity: 0;
  }
`;

export const RedLine = styled.div<lineProps>`
  width: 0;
  min-height: 4px;
  background: #f52218;
  position: absolute;
  top: -4.5px;
  right: 264.5px;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  ${({ showing }) =>
    showing
      ? css`
          animation-name: ${showAnimation};
        `
      : css`
          animation-name: ${hideAnimation};
        `}
`;
