import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsThree = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(9));
  }, []);

  return (
    <NewsCont>
      <Header>IS YOUR BUSINESS HURTING?</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>February 22, 2023 - 2:50pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigThree : thoughtImg.sThree} />
      </ImageContainer>
      <BodyCont>
        <h3>
          As the UK economy continues to face various challenges, businesses of
          all sizes are feeling the impact. Here are some key signs that your
          business may be hurting, as well as some tips on what you can do to
          turn things around.
        </h3>
        <p>
          As the UK economy continues to face various challenges, businesses of
          all sizes are feeling the impact. From rising costs of living to the
          ongoing effects of COVID-19 and the recent increase in inflation, many
          companies are struggling to keep up.
        </p>
        <p>
          If you’re a business owner who is currently experiencing a downturn,
          it’s essential to assess the situation and take appropriate steps to
          minimise the damage.
        </p>
        <p>
          Here are some key signs that your business may be hurting, as well as
          some tips on what you can do to turn things around.
        </p>

        <h5>• Decreasing Sales</h5>
        <p>
          If you’ve noticed a decline in your sales, it’s a clear indication
          that your business is hurting. It could be due to a decrease in
          consumer spending, changes in customer behaviour, or increased
          competition. To address this issue, you may need to re-evaluate your
          marketing strategies and consider ways to attract new customers or
          retain existing ones.
        </p>

        <h5>• Staff Shortages</h5>
        <p>
          If you’re losing employees at an unusual rate, it’s a sign that your
          business may be struggling. This could be due to a lack of job
          security, poor management, or other factors. To address this issue,
          you may need to evaluate your workplace culture, provide more training
          opportunities, and offer competitive compensation packages.
        </p>
        <h5>• Negative Online Reviews</h5>
        <p>
          In today’s digital age, online reviews can make or break a business.
          If you’re receiving negative reviews or feedback, it’s a sign that
          customers are dissatisfied with your products or services. You may
          need to improve the quality of your offerings, provide better customer
          service, or enhance your online presence to address this issue.
        </p>
        <h5>• Cash Flow Problems</h5>
        <p>
          If your business is struggling to pay its bills or meet its financial
          obligations, it’s a sign that your cash flow is in trouble. You may
          need to review your expenses and cut back on unnecessary costs to
          improve your cash flow. Additionally, you could consider negotiating
          better payment terms with suppliers or look into reducing your bigger
          overheads, including your rent.
        </p>

        <p>
          If your business is experiencing any of these signs, it’s important to
          take action to minimise the damage and consider innovative solutions
          to stay afloat during these challenging times. For example, could you
          reduce your overheads by securing a rent reduction, or could you
          surrender a lease that is no longer required?
        </p>
        <p>
          Remember that a downturn is not necessarily a death sentence for your
          business, and with the right mindset and approach, you can navigate
          these challenges and come out stronger on the other side.
        </p>
        <p>
          <a href="/contact">Contact us</a> today for advice on your commercial
          lease or fill out this <a href="/contact">form</a> and one of our advisors will be in touch.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsThree;
