import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import { Left, Right, Text } from "./styles";

import { useInView } from "react-intersection-observer";

const Six = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "STRATEGIC LEASE MANAGEMENT",
    img: servImg.sSixBig,
    imgM: servImg.sixM,

    quote: {
      img: null,
      text: `“Cedar Dean continue to deliver savings and value across our existing leasehold estate of 70+ sites UK wide. This has allowed us to focus on expanding our operations with the knowledge that our existing portfolio is in safe hands.”`,
      from: (
        <h4>
          <b>Chopstix</b>
        </h4>
      ),
    },

    body: [
      "A sizeable portfolio of properties can be a blessing, but it can also become a nightmare for any business owner or commercial leaseholder to manage efficiently and affordably. ",
      "Cedar Dean is highly experienced in working with businesses who have complex portfolios of properties, offering expert advice and guidance to our clients and helping them identify advantageous, money-saving procedures. ",
      "We often find that business owners with large portfolios or particularly valuable properties are in need of specialist services that better structure their properties. We’re proud to say we’ve saved our clients more than £500m in reduced leasehold liabilities, helping them boast better profits and achieve their longer-term business goals with ease. ",
      "Whether you need to completely overhaul your leasehold property portfolio, review specific terms of your leases or seek advice on a mid-term lease restructure across a number of locations, we’re here to help. Enquire about our services today and a member of our team will be in touch with you soon.",
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>{data.header}</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>

        <BannerInner>
          <p>{data.quote.text}</p>

          {data.quote.from}
        </BannerInner>
      </Banner>
      <Text inView={inView}>
        <Left>
          <p>{data.body[0]}</p>
          <p ref={ref}>{data.body[1]}</p>
        </Left>
        <Right>
          <p>{data.body[2]}</p>
          <p>{data.body[3]}</p>

          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
    </Container>
  );
};

export default Six;
