import img from "../../../assets";
import headerServData from "./data";
import {
  BottomRow,
  Box,
  HeaderServCont,
  MiddleRow,
  RedLine,
  TopRow,
} from "./styles";

import { ReactComponent as Arrow } from "../../../assets/right1.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

const top = [headerServData[0], headerServData[1], headerServData[2]];

const middle = [headerServData[3], headerServData[4], headerServData[5]];

const bottom = [headerServData[6], headerServData[7]];

const HeaderServ = ({ showing, setShowing }: any) => {
  const navigate = useNavigate();

  const [items, setItems] = useState<any>({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
  });

  const place = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
  };

  const handleBoxHover = (event: any, num: any) => {
    setItems({ ...place, [num]: true });
    const paths = event.currentTarget.getElementsByTagName("path");
    for (let i = 0; i < paths.length; i++) {
      paths[i].setAttribute("fill", "red");
    }
  };

  const handleBoxLeave = (event: any, num: any) => {
    setItems({ ...place });

    const paths = event.currentTarget.getElementsByTagName("path");
    for (let i = 0; i < paths.length; i++) {
      paths[i].removeAttribute("fill");
    }
  };

  return (
    <HeaderServCont
      showing={showing}
      onMouseEnter={() => setShowing(true)}
      onMouseLeave={() => setShowing(false)}
    >
      <RedLine showing={showing}></RedLine>
      <TopRow>
        {top.map((x: any) => {
          return (
            <Box
              onMouseEnter={(e) => handleBoxHover(e, x.num)}
              onMouseLeave={(e) => handleBoxLeave(e, x.num)}
              onClick={() => navigate(x.link)}
              key={x.num}
            >
              <div>
                <h2>{x.header}</h2>
                <Arrow
                  stroke={items[x.num] ? "red" : "black"}
                  fill={items[x.num] ? "red" : "black"}
                />
              </div>

              <p>{x.text}</p>
            </Box>
          );
        })}
      </TopRow>
      <MiddleRow>
        {middle.map((x: any) => {
          return (
            <Box
              onMouseEnter={(e) => handleBoxHover(e, x.num)}
              onMouseLeave={(e) => handleBoxLeave(e, x.num)}
              onClick={() => navigate(x.link)}
              key={x.num}
            >
              <div>
                <h2>{x.header}</h2>
                <Arrow
                  stroke={items[x.num] ? "red" : "black"}
                  fill={items[x.num] ? "red" : "black"}
                />
              </div>

              <p>{x.text}</p>
            </Box>
          );
        })}
      </MiddleRow>
      <BottomRow>
        {bottom.map((x: any) => {
          return (
            <Box
              onMouseEnter={(e) => handleBoxHover(e, x.num)}
              onMouseLeave={(e) => handleBoxLeave(e, x.num)}
              onClick={() => navigate(x.link)}
              key={x.num}
            >
              <div>
                <h2>{x.header}</h2>
                <Arrow
                  stroke={items[x.num] ? "red" : "black"}
                  fill={items[x.num] ? "red" : "black"}
                />
              </div>

              <p>{x.text}</p>
            </Box>
          );
        })}
      </BottomRow>
    </HeaderServCont>
  );
};

export default HeaderServ;
