import axios from "axios";

const sendEmail = async (x: any) => {
  const sendData = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API}/newsletter`,
    data: { email: x },
  });

  return sendData;
};

export default sendEmail;
