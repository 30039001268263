import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsEight = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(31.5));
  }, []);

  return (
    <NewsCont>
      <Header>
        LIFE AFTER COVID BUSINESS SUPPORT ENDS – ARE SERVICED OFFICES THE RIGHT
        SOLUTION FOR YOU?
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>April 28, 2022 - 3:03pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigEight : thoughtImg.sEight} />
      </ImageContainer>
      <BodyCont>
        <h3>
          Heading back to the office? Do serviced offices have a future now that
          Covid business support has ended?
        </h3>
        <p>
          The worst of the pandemic seems to be behind us. After two long years
          of living under its shadow, we are finally beginning to get our lives
          back to normal. However, normal has changed for many of us, especially
          when it comes to our working lives.
        </p>
        <p>
          Here at Cedar Dean, we have been discussing the impact of the end of
          covid business support on businesses that lease offices, and we’d like
          to share our thoughts.
        </p>

        <h3>
          Businesses are embracing flexible working following end of covid
          support
        </h3>
        <p>
          Flexible working, remote working and working from home have become
          much more commonplace. Despite the end of covid business support,
          which helped businesses to help staff on furlough or working in
          different ways, many employers are still happy for a level of
          flexibility to remain in the post-covid world. However, a great number
          of us are now starting to head back into the office, at least some of
          the time, and are enjoying the benefits.
        </p>

        <h3>Why return to the office?</h3>
        <p>
          For the serviced office industry, the impact of covid has been
          complex. The industry relies on people actively heading to offices to
          work, but many are remaining at home. Those who are returning to
          offices are enjoying some major benefits. For example, there really is
          no way to replace the power of face-to-face collaboration when it
          comes to the creative process and for many businesses wishing to
          attract top-level employees, they’re struggling to lure them in
          without the added value of their top-notch offices.
        </p>
        <p>
          For younger staff members, and for those just starting with a company
          or in a new role, being able to meet colleagues in person, share
          backgrounds and personalities, and learn from more experienced staff
          is essential. Therefore, most businesses of any size have maintained
          the view that having an office of some kind, even simply as a hub from
          which a selection of staff can work and collaborate, is something they
          will continue to support and, therefore, pay for.
        </p>

        <h3>Serviced offices offer a solution</h3>
        <p>
          For a great number of businesses, switching to a serviced office space
          for their employees could be a great solution. Switching from a
          long-term commercial lease to a serviced office means flexible office
          space that adapts to your needs.
        </p>
        <p>
          Serviced offices are also usually conveniently located for office
          staff, often being positioned near transport hubs, shops and eateries,
          bringing life and economic support back to the UK’s town centres.
          However, some obstacles remain for businesses hoping to switch to
          serviced offices.
        </p>
        <h3>Are you struggling to exit your existing commercial lease?</h3>
        <p>
          We’re hearing from commercial tenants that they are struggling to
          switch to a more suitable serviced office arrangement because they
          can’t get out of their existing office leases. This is despite the
          fact that they are often completely unsustainable, having become
          unsuitable for the way they operate post-covid.
        </p>
        <p>
          Serviced offices should, in theory, be a sustainable solution for
          businesses wanting to return to the office on a part-time basis.
          Ideally, businesses should be able to exit their existing leases if
          they are no longer affordable, directly due to changes that have taken
          place as a result of Covid. They should then be able to opt for a
          lease with a serviced office on a part-time basis, paying only for the
          days they want to use the offices and having their service fees
          adjusted on a suitable pro-rata basis.
        </p>
        <p>
          Unfortunately, this isn’t happening. However, we are helping
          businesses to exit their unstainable leases now that their covid
          business support has ended.
        </p>
        <h3>What’s the right price for office space in the UK?</h3>
        <p>
          The question, though, is how much can you charge a company for using
          space only part of the time? Is it really practical for serviced
          office providers to be charging the same as they always did,
          pre-covid, for space that’s only in use part of the time? It’s going
          to take some time for the new way of working, post covid business
          support, to bed in.
        </p>
        <h3>The question of service charges</h3>
        <p>
          As well as the price of renting a serviced office, there’s also the
          cost of service fees to contend with. Over the various lockdowns in
          the past two years, some companies have been faced with full service
          charges for offices they haven’t been able to use. Serviced office
          providers are often tenants themselves, however, so the buck really
          should stop with the landlords.
        </p>
        <h3>
          Can rent reviews help leases to reflect the new way of working after
          coronavirus?
        </h3>
        <p>
          Commercial landlords should be listening to their serviced office
          tenants and ensuring that{" "}
          <a href="/services/rent-reviews">rent reviews</a> take place sooner
          rather than later. Rent reviews that take into account the new
          approach to office working are essential for the sustainability of
          this business model.
        </p>
        <p>
          There really is no reason why the serviced office industry should
          struggle in the post-covid world. After all, the demand for flexible
          working space was up by 37% in the first half of last year, according
          to research carried out by the{" "}
          <a
            href="https://www.fmj.co.uk/demand-for-flexible-office-space-up-37-per-cent-in-h1-2021/"
            target="_blank"
          >
            Facilities Management Journal
          </a>
          . However, leases for serviced office operators are likely to need
          restructuring, while rent reviews for serviced office users may also
          be necessary to reflect the new way we use offices.
        </p>
        <p>
          There are a number of unknowns as we move into the future of office
          working and only time will allow us to fully understand the new world
          of white-collar work. However, in the meantime, our sustainable lease
          specialists can help serviced office operators to adapt and thrive
          with more flexible leases and rent terms that work for them and their
          customers.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsEight;
