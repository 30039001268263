import styled, { keyframes } from "styled-components";

export const Container = styled.div``;

const slideLeft = keyframes`
  
from{
opacity: 0;
transform: translateX(-50%);

}
to{
  opacity: 1;
transform: translateX(0%);
}

`;

const fadeIn = keyframes`

from{
opacity: 0;


}
to{
  opacity: 1;

}`;

const slideUp = keyframes`

from{
opacity: 0;
transform: translateY(50%);


}
to{
  opacity: 1;
transform: translateY(0%);

}`;

export const Header = styled.h1`
  opacity: 0;

  animation: ${slideLeft} 1s forwards;
  font-family: Anton;
  font-size: 64px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: 0.06em;
  text-align: left;
  color: rgba(19, 50, 68, 1);
  margin: 64px;

  @media (max-width: 800px) {
    margin: 50px 16px;
    font-size: 48px;
    line-height: 56px;
  }
`;

interface props {
  img: string;
}

export const Banner = styled.div<props>`
  width: 100%;
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  background-position: center;
  position: relative;

  @media (min-width: 1000px) {
    min-height: 376px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 75px;

  p {
    color: white;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;

    @media (max-width: 800px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  img {
    margin: 0;
  }

  h4 {
    margin-top: 10px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(248, 248, 248, 1);

    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 22px;
    }

    b {
      font-weight: 600;
    }
  }
`;

export const BannerBg = styled.div`
  background: rgba(0, 0, 0, 0.7);
  min-width: 100%;
  min-height: 100%;
  position: absolute;

  opacity: 0;

  animation: ${fadeIn} 1s forwards;
`;

export const BannerInner = styled.div`
  padding: 40px 64px;
  animation: ${slideUp} 1s forwards;

  max-width: calc(850px + 128px);
  margin: auto;
  position: relative;
  z-index: 99;

  @media (max-width: 800px) {
    padding: 40px 16px;
    max-width: calc(850px + 32px);
  }
`;

export const ButtonCont = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
  }

  div {
    background: #a4150f;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 18px;
    }
  }
`;

export const RedBtn = styled.button`
  background: #f52218;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #f8f8f8;
  width: 180px;
  border: none;
  transition: 0.05s linear;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 168px;
  }

  &:hover {
    cursor: pointer;
    width: 220px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #a4150f;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.1s ease-in-out;
    z-index: 1;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  span {
    position: relative;
    z-index: 2;
  }
`;
