import { useEffect, useState } from "react";
import QuestionFive from "./questions/five";
import QuestionFour from "./questions/four";
import QuestionOne from "./questions/one";
import QuestionSix from "./questions/six";
import QuestionThree from "./questions/three";
import QuestionTwo from "./questions/two";
import { FormContainer } from "./styles";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const Form = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    service: "",
    enquiry: "",
    annualRent: "",
    ltdCompany: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subscribed: false,
  });
  const [page, setPage] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/contact") {
      setPage(true);
    } else {
      setPage(false);
    }
  }, [window.location.pathname]);

  const questions = [
    <QuestionOne setData={setData} data={data} setStep={setStep} />,
    <QuestionTwo setData={setData} data={data} setStep={setStep} />,
    <QuestionThree setData={setData} data={data} setStep={setStep} />,
    <QuestionFour setData={setData} data={data} setStep={setStep} />,
    <QuestionFive setData={setData} data={data} setStep={setStep} />,
    <QuestionSix />,
  ];

  return (
    <FormContainer page={page}>
      <TransitionGroup>
        <CSSTransition key={step} timeout={500} classNames="fade">
          {questions[step]}
        </CSSTransition>
      </TransitionGroup>
    </FormContainer>
  );
};

export default Form;
