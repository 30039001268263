import img from "../../../assets";
import servImg from "../../../assets/services";
import useWindowSize, { Size } from "../../../utils/getWindowSize";
import servicesData from "../../landing/services/data";
import { Background, Box, ServicesGridContainer } from "./styles";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const animations = ["one", "two", "three", "one", "two", "three", "one", "two"];

const ServicesGrid = () => {
  const size: Size = useWindowSize();
  const navigate = useNavigate();

  return (
    <ServicesGridContainer>
      {servicesData.map((x: any, i: number) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { ref, inView } = useInView({
          triggerOnce: true, // The animation will only trigger once
        });
        return (
          <Box
            onClick={() => navigate(`/services/${x.link}`)}
            ref={ref}
            inView={inView}
            ani={animations[i]}
            img={size.width! > 850 ? x.img : x.imgM}
            key={i}
          >
            <h3>{x.header}</h3>
            <p>{x.text}</p>
            <div>
              <p>View Service</p>
              <img src={img.serviceArrow} />
            </div>
            {/* <Background></Background> */}
          </Box>
        );
      })}
      {size.width! > 850 ? (
        <Box inView={false} ani={""} img={servImg.sOne}>
          <h3></h3>
          <p></p>
          <div></div>
          <Background></Background>
        </Box>
      ) : null}
    </ServicesGridContainer>
  );
};

export default ServicesGrid;
