import styled, { keyframes } from "styled-components";

const marquee = keyframes`
  from {
    transform: translate(-100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 60px 0;
  bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 10;
  position: relative;
  background: #ebf0f2;

  @media (max-width: 800px) {
    height: 65px;
    top: -5px;
  }

  @media (max-width: 800px) {
    top: 0;
  }
`;

export const Ticker = styled.div`
  display: inline-block;
  animation: ${marquee} 20s linear infinite;
  animation-delay: 0s;
  max-width: none;
`;

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  max-width: none;
`;

export const Li = styled.li`
  padding: 0 60px;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    transform: scale(0.7);
    padding: 0 10px;
  }
`;

export const Img = styled.img`
  max-width: max-content;
`;
