import styled from "styled-components";

export const ServicesPageCont = styled.div``;

export const TestimonialCont = styled.div`
  background: rgba(235, 240, 242, 1);
  padding: 64px;

  @media (max-width: 800px) {
    padding: 64px 16px;
  }

  div {
    margin: 0;
  }
`;
