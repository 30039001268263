import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import { Left, Right, Text } from "./styles";

import { useInView } from "react-intersection-observer";

const Five = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "RENT REVIEWS",
    img: servImg.sFiveBig,
    imgM: servImg.fiveM,

    quote: {
      img: null,
      text: `“Cedar Dean were a pleasure to work with. Not only were they highly professional, but they achieved outstanding results in a short time period.”`,
      from: (
        <h4>
          Richard Morris, Managing Director - <b>Tortilla Mexican Grill</b>
        </h4>
      ),
    },

    body: [
      "While rents may not decrease following a rent review, the process can pose significant challenges, especially for commercial tenants and leaseholders. Unaffordable and unfair rent increases can have a profound financial impact on UK businesses. Many find themselves in the difficult position of wanting to contest these hikes but feeling unable to, fearing potential conflict or escalation",
      "Thankfully, Cedar Dean is here to help: working with us can strengthen your position against rising rent costs and price increases, with our expert team analysing your competitors and neighbours to ensure any rent revisions remain comparable, fair, and affordable for your business.",
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>{data.header}</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>

        <BannerInner>
          <p>{data.quote.text}</p>

          {data.quote.from}
        </BannerInner>
      </Banner>
      <Text inView={inView} ref={ref}>
        <Left>
          <p>{data.body[0]}</p>
          <p>{data.body[1]}</p>
        </Left>
        <Right>
          <p>{data.body[2]}</p>

          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
    </Container>
  );
};

export default Five;
