import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import {
  BodyCont,
  Div,
  Header,
  ImageContainer,
  NewsCont,
  SourcesCont,
} from "../styles";

const NewsTwenty = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(81));
  }, []);

  return (
    <NewsCont>
      <Header>THE SOLVENT CVA</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>April 25, 2021 - 2:27pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigTwenty : thoughtImg.sTwenty} />
      </ImageContainer>
      <BodyCont>
        <h3>Mediating between tenants and landlords in a reactionary time</h3>
        <p>
          <b>
            The whole world seems to have gone CVA mad over the last few weeks;
            businesses that can do a CVA are taking advantage of the bandwagon
            and doing so.
          </b>
        </p>
        <p>
          But landlords are claiming the move as unfair and are trying to stop
          them, now with some increasing success.
        </p>
        <p>
          Tenants who are unable to do a CVA now want CVA clauses written into
          their leases, so they can be on the same rents as their competitors to
          avoid an otherwise unfair advantage.
        </p>
        <p>
          This eclectic mix of agendas really comes down to one thing: ‘turnover
          is vanity, profit is sanity’.
        </p>
        <p>
          CVAs are needed because properties are held by upward-only rent
          reviews and rents have reached unsustainable levels, in-line with
          their turnovers, in large swathes of the country. This is at a time
          when retailers are also under pressure from increased competition, the
          internet and economic challenges, caused by Brexit and the cost of
          inflation.
        </p>
        <p>
          The question is: is a CVA via an accountancy firm the only way to
          manage your poorly performing leases? The answer from Cedar Dean
          Group’s perspective is an emphatic no.
        </p>
        <p>
          Since 2009, we have been successfully mediating win-win arrangements
          with landlords and tenants where open, honest discussions can be had
          around current trading performances of each specific business. Through
          this, we can find bespoke agreements that work for the businesses but
          also help landlords take control of the asset they have a long-term
          strategy for.
        </p>
        <p>
          Whereas in many cases, the CVA is a ‘one size fits all’, the
          mediations and restructurings that we carry out are a way of having,
          sometimes difficult – but upfront – discussions between parties
          without actually firing a gun.
        </p>
        <p>You could call it ‘CVA diplomacy’.</p>
        <p>
          The challenge that many businesses and landlords find is once a CVA is
          in place, it automatically becomes a hostile conversation bound by
          legalities and – more importantly – time, where everybody is under
          pressure.
        </p>
        <p>
          There are countless instances where we have found that businesses and
          their landlords prefer the option to agree an arrangement that works
          for both parties. So, why isn’t the solvency CVA alternative that we
          have described above, used more often?’
        </p>
        <p>
          It’s quite simple, from the businesses perspective, financial
          directors and their legal advisors see a legal agreement in-place in
          the lease. As they don’t believe that this can be negotiated, they can
          go to any accounting and legal lengths to get out of such
          arrangements.
        </p>
        <p>
          From the landlords’ perspective they are, in many cases, out of touch
          with the profitability of their specific unit and are simply hoping
          that each rent invoice is paid to avoid worrying about an empty
          property.
        </p>
        <p>
          We think the tide is definitely changing. Landlords are starting to
          take notice of the turnovers of their tenants and the affordability
          that lies within it. The only way forward to resolve the current
          mismatch between rents and turnover is to have these grown-up
          conversations, as well as looking at the relationship between
          landlords and tenants as that of supplier and customer.
        </p>
        <p>
          While this seems to have been lost in translation over the decades,
          the pendulum seems to be swinging the other way again and there are
          certainly interesting times ahead.
        </p>
        <p>
          As always, we are here to give our opinion and go to the great lengths
          of being able to avoid such insolvency processes where long-term
          reputational damage and empty properties can be avoided.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsTwenty;
