import servImg from "../../../assets/services";

const servicesData = [
  {
    img: servImg.sOne,
    imgM: servImg.sOneM,
    header: "Restructuring Advice",
    text: "Restructuring your commercial property lease can deliver unparalleled cost savings for businesses of all sizes.",
    link: "restructuring-advice",
  },
  {
    img: servImg.sTwo,
    imgM: servImg.sTwoM,

    header: "Acquisitions",
    text: "Realise your property-owning dream, or ensure a smooth transfer of a leasehold from one party to another, acquisitions are just another of the specialist services we advise on. ",
    link: "acquisitions",
  },

  {
    img: servImg.sThree,
    imgM: servImg.sThreeM,

    header: "Lease Renewals",
    text: "If your lease is approaching renewal, don’t miss the opportunity to negotiate more favourable terms, or even reduce your rent. ",
    link: "lease-renewals",
  },
  {
    img: servImg.sFour,
    imgM: servImg.sFourM,

    header: "Lease Surrenders",
    text: "If your business is tied into a lease it no longer requires, Cedar Dean can help.",
    link: "lease-surrenders",
  },
  {
    img: servImg.sFive,
    imgM: servImg.sFiveM,

    header: "Rent Reviews",
    text: "Rent reviews can be problematic for commercial tenants.",
    link: "rent-reviews",
  },
  {
    img: servImg.sSix,
    imgM: servImg.sSixM,

    header: "Rent Reductions",
    text: "There are various opportunities for rent reductions on your commercial leasehold properties.",
    link: "rent-reductions",
  },
  {
    img: servImg.sSeven,
    imgM: servImg.sSevenM,

    header: "Strategic Lease Management",
    text: "If you have a portfolio of leasehold properties, you could benefit from talking to us.",

    link: "strategic-lease-management",
  },
  {
    img: servImg.sEight,
    imgM: servImg.sEightM,

    header: "View All Services",
    text: "Not sure which service you interested in yet? No worry, check out all of our services here!",
    link: "",
  },
];

export default servicesData;
