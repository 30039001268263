import { FooterLinksContainer, Smaller } from "../styles";

const Touch = () => {
  return (
    <FooterLinksContainer>
      <p>Get In Touch</p>
      <Smaller>General Support</Smaller>
      <a href="mailTo:info@cedardean.com">info@cedardean.com</a>
      <Smaller>Let's Talk</Smaller>
      <a href="tel:(0) 207 100 6013">(0) 207 100 6013</a>
    </FooterLinksContainer>
  );
};

export default Touch;
