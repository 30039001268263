import React, { useEffect, useReducer } from "react";
import projectsLogo from "./data";
import useWindowSize from "../../../utils/getWindowSize";
import { Section, Ticker, Ul, Li, Img } from "./styles";

const Companies: React.FC<{}> = () => {
  const size = useWindowSize();
  const isMob = size.width! < 768;
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    setTimeout(() => forceUpdate, 100);
  });

  const renderProjectsMarquee = () => {
    const list = projectsLogo.map((item, index) => (
      <Li
        key={item.id}
        className={isMob ? "" : "wow fadeIn"}
        data-wow-delay={`.${index}s`}
      >
        <Img src={item.logo} />
      </Li>
    ));
    return <Ul>{list}</Ul>;
  };

  return (
    <Section>
      <Ticker>{renderProjectsMarquee()}</Ticker>
      <Ticker>{renderProjectsMarquee()}</Ticker>
      <Ticker>{renderProjectsMarquee()}</Ticker>
      <Ticker>{renderProjectsMarquee()}</Ticker>
      <Ticker>{renderProjectsMarquee()}</Ticker>
      <Ticker>{renderProjectsMarquee()}</Ticker>
    </Section>
  );
};

export default Companies;
