import { useState } from "react";
import { Button, Continue, QuestionTwoContainer } from "../styles";

const QuestionTwo = ({ setData, data, setStep }: any) => {
  const [message, setMessage] = useState("");

  const onClick = () => {
    if (message.length > 0) {
      setData({ ...data, enquiry: message });
      setStep(2);
    }
  };

  const onChange = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <QuestionTwoContainer>
      <h3>TELL US A LITTLE MORE ABOUT YOUR ENQUIRY</h3>
      <label>Tell us a little more about your enquiry</label>
      <textarea placeholder="Enter your message here..." onChange={onChange} />
      <Continue onClick={onClick}>Continue</Continue>
    </QuestionTwoContainer>
  );
};

export default QuestionTwo;
