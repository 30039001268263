import { Cont } from "./styles";

const NotFoundPage = () => {
  return (
    <Cont>
      <h1>PAGE NOT FOUND</h1>
    </Cont>
  );
};

export default NotFoundPage;
