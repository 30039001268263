/* eslint-disable array-callback-return */
import img from "../../../assets";
import servImg from "../../../assets/services";
import useWindowSize, { Size } from "../../../utils/getWindowSize";
import newsData from "../../landing/thoughts/data";
import {
  AllCont,
  Background,
  Box,
  BtnCont,
  ServicesGridContainer,
  ShowMoreCont,
  TopLeft,
} from "./styles";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { DesktopButton } from "../../landing/services/styles";
import { useState } from "react";

const animations = [
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
];

const NewsGrid = () => {
  const size: Size = useWindowSize();
  const navigate = useNavigate();

  const [showMore, setShowMore] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);

  const [showingNum, setShowingNum] = useState(6);

  const handleClick = () => {
    setShowMore(true);

    if (!showMore) {
      setShowMore(true);
      setShowingNum(12);
    } else if (!showMore2) {
      setShowMore2(true);
      setShowingNum(18);
    } else if (!showMore3) {
      setShowMore3(true);
      setShowingNum(22);
    }
  };

  return (
    <>
      <AllCont>
        <ServicesGridContainer>
          {newsData.map((x: any, i: number) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { ref, inView } = useInView({
              triggerOnce: true, // The animation will only trigger once
            });
            if (i < 6) {
              return (
                <Box
                  onClick={() => navigate(`/news/${x.link}`)}
                  ref={ref}
                  inView={inView}
                  ani={animations[i]}
                  img={size.width! > 850 ? x.img : x.imgM}
                  key={i}
                >
                  <TopLeft>{x.date}</TopLeft>
                  <h3>{x.header}</h3>
                  <p>{x.text}</p>
                  <div>
                    <p>Read more</p>
                    <img src={img.serviceArrow} />
                  </div>
                  <Background></Background>
                </Box>
              );
            }
          })}
        </ServicesGridContainer>
        <ShowMoreCont showMore={showMore}>
          {newsData.map((x: any, i: number) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { ref, inView } = useInView({
              triggerOnce: true, // The animation will only trigger once
            });
            if (i >= 6 && i < 12) {
              return (
                <Box
                  onClick={() => navigate(`/news/${x.link}`)}
                  ref={ref}
                  inView={inView}
                  ani={animations[i]}
                  img={size.width! > 850 ? x.img : x.imgM}
                  key={i}
                >
                  <TopLeft>{x.date}</TopLeft>
                  <h3>{x.header}</h3>
                  <p>{x.text}</p>
                  <div>
                    <p>Read more</p>
                    <img src={img.serviceArrow} />
                  </div>
                  <Background></Background>
                </Box>
              );
            }
          })}
        </ShowMoreCont>
        <ShowMoreCont showMore={showMore2}>
          {newsData.map((x: any, i: number) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { ref, inView } = useInView({
              triggerOnce: true, // The animation will only trigger once
            });
            if (i >= 12 && i < 18) {
              return (
                <Box
                  onClick={() => navigate(`/news/${x.link}`)}
                  ref={ref}
                  inView={inView}
                  ani={animations[i]}
                  img={size.width! > 850 ? x.img : x.imgM}
                  key={i}
                >
                  <TopLeft>{x.date}</TopLeft>
                  <h3>{x.header}</h3>
                  <p>{x.text}</p>
                  <div>
                    <p>Read more</p>
                    <img src={img.serviceArrow} />
                  </div>
                  <Background></Background>
                </Box>
              );
            }
          })}
        </ShowMoreCont>
        <ShowMoreCont showMore={showMore3}>
          {newsData.map((x: any, i: number) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { ref, inView } = useInView({
              triggerOnce: true, // The animation will only trigger once
            });
            if (i >= 18) {
              return (
                <Box
                  onClick={() => navigate(`/news/${x.link}`)}
                  ref={ref}
                  inView={inView}
                  ani={animations[i]}
                  img={size.width! > 850 ? x.img : x.imgM}
                  key={i}
                >
                  <TopLeft>{x.date}</TopLeft>
                  <h3>{x.header}</h3>
                  <p>{x.text}</p>
                  <div>
                    <p>Read more</p>
                    <img src={img.serviceArrow} />
                  </div>
                  <Background></Background>
                </Box>
              );
            }
          })}
        </ShowMoreCont>
      </AllCont>
      <BtnCont>
        {showingNum < 22 && (
          <DesktopButton onClick={handleClick}>
            View More Articles
          </DesktopButton>
        )}
        <p>Showing {showingNum} out of 22</p>
      </BtnCont>
    </>
  );
};

export default NewsGrid;
