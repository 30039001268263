import axios from "axios";

const sendEnquiry = async (x: any) => {
  const sendData = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API}/enquiry`,
    data: x,
  });

  return sendData;
};

export default sendEnquiry;
