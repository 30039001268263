import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsFourteen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(54));
  }, []);

  return (
    <NewsCont>
      <Header>
        FOR STRUGGLING BUSINESSES WHO PAID THEIR RENT, IS THE CODE OF PRACTICE
        TOO LITTLE TOO LATE?
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>November 18, 2021 - 5:25pm</p>
      </Div>
      <ImageContainer>
        <img
          src={width! > 450 ? thoughtImg.bigFourteen : thoughtImg.sFourteen}
        />
      </ImageContainer>
      <BodyCont>
        <h3>
          With a third of UK small businesses highly indebted and insolvencies
          reaching their highest levels, the damage has already been done.
        </h3>
        <p>
          With a third of UK small businesses highly indebted and insolvencies
          reaching their highest levels since the beginning of the pandemic, the
          damage has already been done for many commercial tenants.
        </p>
        <p>
          The government has released new laws and a{" "}
          <a
            href="https://www.gov.uk/government/publications/commercial-rents-code-of-practice-november-2021/code-of-practice-for-commercial-property-relationships-following-the-covid-19-pandemic"
            target="_blank"
          >
            Code of Practice
          </a>{" "}
          to try to help commercial tenants and landlords resolve any remaining
          rent debts. The laws will protect tenants from CCJs, HCJs, and
          bankruptcy orders, which is very welcome news indeed.
        </p>
        <p>
          The Code of Practice states that, in cases where tenants and landlords
          are yet to come to an agreement about the payment of rent arrears
          accumulated during national lockdowns, tenants should aim to meet the
          obligations of their lease “where it is affordable”. However, it goes
          on to explicitly state that “tenants should never have to take on more
          debt – or restructure their business – in order to pay their rent.”
        </p>
        <p>
          Although this may come as a comfort to those tenants who have dug
          their heels in over the past months, some business owners may have
          accumulated debt in order to pay their rent. We would argue that, for
          anyone who borrowed money, made redundancies, or restructured their
          business in order to pay their rent, this{" "}
          <a
            href="https://www.gov.uk/government/publications/commercial-rents-code-of-practice-november-2021/code-of-practice-for-commercial-property-relationships-following-the-covid-19-pandemic"
            target="_blank"
          >
            Code of Practice
          </a>{" "}
          comes too late. It offers business owners who have paid rent, but who
          are now struggling financially, no comfort or recompense for their
          previous sacrifices. Some of these businesses may be facing insolvency
          as a result of trying to do the right thing by their landlords.
        </p>
        <p>
          If you’re one of the many businesses that are now struggling to
          survive, under a heavy burden of debt,{" "}
          <a href="/" target="_blank">
            speak to Cedar Dean today
          </a>
          . We can help you restructure your commercial lease, even mid-term, to
          ensure a viable business in the future.
        </p>

        <h3>Heavy debt burden weighing down small business sector</h3>
        <p>
          The Insolvency Service found that 1,446 businesses were declared
          insolvent in September 2021, 56% higher than in September 2020. This
          illustrates the pressures businesses are under as we move into a
          period of less government support for Covid-impacted businesses.
        </p>
        <p>
          The pressure of debt is driving these insolvencies and the number of
          debt relief orders (DROs) in September 2021 was up by 41% on September
          2020 figures. Additionally, the Bank of England has reported that a
          third of UK small businesses have debts of more than 10x their cash
          balances, up from 14% before the pandemic.
        </p>
        <p>
          These figures show that small businesses all over the UK have been
          borrowing money to meet costs – the greatest of which for many
          businesses is their rent. Despite the moratorium on commercial
          evictions being in place throughout the pandemic, many business owners
          made massive sacrifices or took out significant loans, to pay their
          rent. They did so for many different reasons. Some may have felt a
          sense of duty to their landlords, others may have simply been too
          scared of the consequences of missing payments, regardless of the
          government support.
        </p>
        <p>
          Others may have simply felt it was the right thing to do or had come
          under immense pressure from landlords. Whatever their reasons, many
          will be regretting their choice as they face a very uncertain future.
          For them, the new Code of Practice, which finally states that
          businesses shouldn’t take on debt to pay rent, offers no solution.
        </p>

        <h3>A Code of Practice full of ambiguity</h3>
        <p>
          For those business owners who stood their ground and still have rent
          arrears, the{" "}
          <a
            href="https://www.gov.uk/government/publications/commercial-rents-code-of-practice-november-2021/code-of-practice-for-commercial-property-relationships-following-the-covid-19-pandemic"
            target="_blank"
          >
            Code of Practice
          </a>{" "}
          will throw up yet more questions and areas of uncertainty for both
          themselves and their landlords.
        </p>
        <p>
          Although the laws protecting tenants from legal action regarding their
          rent arrears are clear, we would argue that much of the Code of
          Practice is still up for dispute and misinterpretation. Although it
          does make attempts to set out the meanings of terms like ‘viability’
          and ‘affordability’ we still expect these terms to mean different
          things to different people.
        </p>
        <p>
          Then there’s the issue of the dates of closure and reopening. The laws
          that have been brought in apply to commercial rent debts accrued by
          businesses that were mandated to close, either in full or in part,
          from March 2020 until the date restrictions were lifted for their
          sector. Again, interpretation of these dates will differ between
          landlords and tenants. Tenants are likely to view partial reopening
          differently to their landlords in terms of their liability to pay
          rent. This could further damage relationships between tenants and
          landlords and increase the likelihood that a case will end up in
          arbitration.
        </p>
        <p>
          Whatever your current situation with regards to Covid-specific rent
          arrears, as a commercial business tenant, you have{" "}
          <a href="/">Cedar Dean</a> on your side. We understand the
          complexities of this situation and appreciate the nuances in each
          lease and each set of circumstances. We can help rebuild your
          relationship with your landlord through a process of sustainable lease
          restructuring, providing stability for landlords and affordability for
          tenants.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsFourteen;
