import styled, { keyframes, css } from "styled-components";

export const Container = styled.div``;

const slideUp = keyframes`
  from{
    opacity: 0;
    transform: translateY(30%)
  }

  to{
    opacity: 1;
    transform: translateY(0%)
  }
`;

interface props {
  inView: boolean;
}

export const Text = styled.div<props>`
  display: flex;
  justify-content: space-between;

  margin: 30px 64px 50px;

  opacity: ${({ inView }) =>
    inView ? 1 : 0}; // Updated opacity based on inView

  ${({ inView }) =>
    inView &&
    css`
      * {
        animation: ${slideUp} 1s forwards;
      }
    `}

  @media (max-width: 800px) {
    flex-direction: column;
    margin: 30px 16px 50px;
  }
  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(101, 122, 133, 1);
    margin-bottom: 20px;

    @media (max-width: 800px) {
      font-size: 14px;

      line-height: 22px;
    }
  }
`;

export const Left = styled.div`
  max-width: 616px;
  margin-right: 20px;
`;

export const Right = styled.div`
  max-width: 616px;
  width: 100%;
`;
