import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsSeventeen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(68.5));
  }, []);

  return (
    <NewsCont>
      <Header>DEBT IS A FOUR LETTER WORD!</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>June 22, 2021 - 4:34pm</p>
      </Div>
      <ImageContainer>
        <img
          src={width! > 450 ? thoughtImg.bigSeventeen : thoughtImg.sSeventeen}
        />
      </ImageContainer>
      <BodyCont>
        <h3>
          After 12 years in Lease restructuring, it was clear to me that the
          most important word in the landlord and tenant relationship is
          affordability!
        </h3>
        <p>
          The restaurants, bars, clubs and retailers that we represent are being
          asked to increase their debt by both the Government and Landlords in
          the forms of CBILS (Coronavirus Business Interruption Loans) and Rent
          deferrals.
        </p>
        <p>
          My response is that accepting these larger debts is like businesses
          becoming lambs to the slaughter in the medium/ long term. We all know
          the next 2 + years will be tough, so why would any business want to
          make It harder by adding more long-term debt, just so the government
          or banks’/ landlords balance sheet doesn’t look as bad as it really
          should.
        </p>
        <h3>Our advice is a simple 5 D’s :</h3>
        <h5>Debt is a 4 – letter word :</h5>
        <p>Be careful when using it excessively</p>
        <h5>Don’t accept Rent Deferrals :</h5>
        <p>
          Rent deferrals should not be acceptable to businesses as they simply
          don’t know if they will have the earnings to afford repayment, this
          could be a huge issue down the track. Headline Rents will probably
          need to come down long term and this will only happen with landlords
          who want to have a Win/ Win relationship with their tenants.
        </p>
        <h5>Don’t use loans to prop up average / poor businesses :</h5>
        <p>
          CBILS (if you can get one) should not be used to prop up average or
          poor performing locations. The funds should be used to sustain and
          support only locations which have a likely 12- month recovery plan to
          decent profitability. Poor performing location should be kept closed
          and placed into an insolvency process if needed.
        </p>
        <h5>Don’t put all your eggs in one basket :</h5>
        <p>
          Each location should ideally be in its own company to protect exposure
          to future uncertainty and risk to the wider business. At the very
          least, there should be some division of leases amongst estates to
          reduce risk.
        </p>
        <h5>Don’t fear restructuring / insolvency :</h5>
        <p>
          Businesses should start to embrace restructuring and insolvency as a
          solution rather than fearing reputational issues. The world will
          understand that companies need to do what it takes to survive. For a
          private discussion on how to survive, protect and strengthen your
          business at this time, please email me on{" "}
          <a href="mailto:david@cedardean.com">david@cedardean.com</a>
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsSeventeen;
