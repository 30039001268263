import styled, { keyframes } from "styled-components";
import img from "../../../assets";

export const HeroContainer = styled.div`
  background-image: url(${img.aboutHeroBg});
  background-size: 100%;
  padding-top: 191px;
  padding-bottom: 187px;
  background-size: cover;
  margin-top: -83px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 800px) {
    background-size: cover;
    background-position: 50% 20%;
    margin-top: -96px;
    padding-top: 143px;
    padding-right: 16px;
    padding-bottom: 100px;

    background-image: url(${img.aboutHeroMobile});
  }

  @media (max-width: 375px) {
    padding-bottom: 80px;
  }
`;

const slideIn = keyframes`
  from{
    opacity: 0;
    transform: translateX(-50%);
  }
  to{
    opacity: 1;
    transform: translateX(0%);

  }
`;

const slideInTwo = keyframes`
  from{
    opacity: 0;
    right: -30%;
  }
  to{
    opacity: 1;
right: 0;

  }
`;

export const MainText = styled.h1`
  opacity: 0;
  transform: translateX(-50%);
  animation: ${slideIn} 1s forwards;
  font-weight: 400;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: 0.06em;
  color: #f8f8f8;
  max-width: 850px;
  margin: 0 64px;

  @media (max-width: 800px) {
    margin: 0 16px;
    font-size: 48px;
    line-height: 56px;
  }
`;

export const SmallerText = styled.p`
  opacity: 0;
  transform: translateX(-50%);
  animation: ${slideIn} 1s forwards;
  margin-left: 64px;
  margin-right: 64px;

  max-width: 850px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  color: #f8f8f8;

  @media (max-width: 800px) {
    margin-left: 16px;
    margin-right: 16px;

    font-size: 16px;
    line-height: 24px;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: 64px;
  margin-top: 40px;

  @media (max-width: 800px) {
    margin-left: 16px;
  }
`;

export const IconsCont = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;

  @media (max-width: 990px) {
    bottom: 30px;
    top: auto;
    left: 42px;
  }

  @media (max-width: 800px) {
    bottom: 30px;
    top: auto;
    left: -6px;
  }

  animation: ${slideInTwo} 0.6s forwards;
`;
