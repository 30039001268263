import { useEffect, useState } from "react";
import { updateLoaded } from "../../redux/reducers/loaded";
import { useDispatch, useSelector } from "react-redux";
import { RemoveScroll } from "react-remove-scroll";
import { RootState } from "../../redux";
import React, { lazy, Suspense } from "react";
import useWindowSize, { Size } from "../../utils/getWindowSize";
import { LandingPageContainer, Placeholder } from "./styles";

const LandingAuthority = lazy(
  () => import("../../components/landing/authority")
);
const Companies = lazy(() => import("../../components/landing/companies"));
const CompaniesMobile = lazy(
  () => import("../../components/landing/companiesMobile")
);
const LandingFaq = lazy(() => import("../../components/landing/faq"));
const LandingForm = lazy(() => import("../../components/landing/landingForm"));
const LandingHelp = lazy(() => import("../../components/landing/help"));
const LandingHero = lazy(() => import("../../components/landing/hero"));
const RightSide = lazy(() => import("../../components/landing/hero/rightSide"));
const LandingServices = lazy(() => import("../../components/landing/services"));
const LandingTestimonials = lazy(
  () => import("../../components/landing/testimonials")
);
const LandingTrusted = lazy(() => import("../../components/landing/trusted"));
const LandingValues = lazy(() => import("../../components/landing/values"));
const LandingVideo = lazy(() => import("../../components/landing/video"));
const LandingThoughts = lazy(() => import("../../components/landing/thoughts"));

const LandingPage = () => {
  const size: Size = useWindowSize();
  const dispatch = useDispatch();
  const { loaded } = useSelector((state: RootState) => state.loaded);
  const [show, setShow] = useState(true);

  const [scrollLock, setScrollLock] = useState(true);
  const [num, setNum] = useState(0);

  useEffect(() => {
    if (num < 5 && !loaded) {
      scroll();
    }
  }, [num]);

  const scroll = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      setNum(num + 1);
    }, 250);
  };

  useEffect(() => {
    if (!loaded) {
      scroll();
    }

    window.scrollTo(0, 0);

    setTimeout(() => {
      dispatch(updateLoaded());
    }, 4000);

    setTimeout(() => {
      setScrollLock(false);
    }, 2500);
  }, []);

  return (
    <LandingPageContainer>
      <Suspense fallback={<Placeholder show={true} />}>
        <RemoveScroll enabled={scrollLock && !loaded}>
          <p></p>
        </RemoveScroll>
        <Placeholder show={false} />
        <LandingHero />
        {size.width! < 1080 ? <RightSide /> : null}
        <Companies />
        {size.width! < 1080 ? <CompaniesMobile /> : null}

        <LandingTrusted />
        <LandingValues />
        <LandingAuthority />
        <LandingVideo />
        <LandingTestimonials />
        <LandingServices />
        <LandingHelp />
        <Companies />
        {size.width! < 1080 ? <CompaniesMobile /> : null}

        <LandingFaq />
        <LandingThoughts />
        <LandingForm />
      </Suspense>
    </LandingPageContainer>
  );
};

export default LandingPage;
