import { useEffect, useRef, useState } from "react";
import testimonialData from "./data";
import {
  Inner,
  InnerDiv,
  Line,
  LinesDiv,
  TestimonialsContainer,
} from "./styles";

import { useInView } from "react-intersection-observer";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const LandingTestimonials = () => {
  const [num, setNum] = useState<number>(0);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const data = testimonialData[num];

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const onClick = (newNum: number) => {
    if (newNum !== num) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
      setNum(newNum);
    }
  };

  useEffect(() => {
    if (inView && timeoutId.current === null) {
      timeoutId.current = setTimeout(() => {
        setNum((prevNum: number) => (prevNum + 1) % 3);
        timeoutId.current = null;
      }, 5000) as NodeJS.Timeout; // Type assertion
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, [num, inView]);

  return (
    <TestimonialsContainer isVisible={inView}>
      <Inner ref={ref}>
        <TransitionGroup>
          <CSSTransition key={num} timeout={500} classNames="fade">
            <InnerDiv>
              {data.body}
              {data.person}
            </InnerDiv>
          </CSSTransition>
        </TransitionGroup>
        <LinesDiv>
          <Line selected={num === 0} onClick={() => onClick(0)}></Line>
          <Line selected={num === 1} onClick={() => onClick(1)}></Line>
          <Line selected={num === 2} onClick={() => onClick(2)}></Line>
        </LinesDiv>
      </Inner>
    </TestimonialsContainer>
  );
};

export default LandingTestimonials;