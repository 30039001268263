import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../src/assets/right1.svg";

import img from "../../assets";
import { updateLoaded } from "../../redux/reducers/loaded";
import useWindowSize from "../../utils/getWindowSize";
import NotFoundPage from "../404";
import { ArrowDiv, BottomRow, Cont, Prog, ProgBar, ProgCont } from "./styles";

import NewsOne from "./one";
import { RootState } from "../../redux";
import NewsTwo from "./two";
import NewsThree from "./three";
import NewsFour from "./four";
import NewsFive from "./five";
import NewsSix from "./six";
import NewsSeven from "./seven";
import NewsEight from "./eight";
import NewsNine from "./nine";
import NewsTen from "./ten";
import NewsEleven from "./eleven";
import NewsTwelve from "./twelve";
import NewsThirteen from "./thirteen";
import NewsFourteen from "./fourteen";
import NewsFifteen from "./fifteen";
import NewsSixteen from "./sixteen";
import NewsSeventeen from "./seventeen";
import NewsEighteen from "./eighteen";
import NewsNineteen from "./nineteen";
import NewsTwenty from "./twenty";
import NewsTwentyone from "./twentyone";
import NewsTwentytwo from "./twentytwo";
import { Notify } from "notiflix";

const IndividualNews = () => {
  const [news, setNews] = useState("");
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const { articlePercent } = useSelector((state: RootState) => state.articles);

  const [items, setItems] = useState<any>({
    one: false,
    two: false,
  });

  const place = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
  };

  const handleBoxHover = (event: any, num: any) => {
    setItems({ ...place, [num]: true });
    const paths = event.currentTarget.getElementsByTagName("path");
    for (let i = 0; i < paths.length; i++) {
      paths[i].setAttribute("fill", "red");
    }
  };

  const handleBoxLeave = (event: any, num: any) => {
    setItems({ ...place });

    const paths = event.currentTarget.getElementsByTagName("path");
    for (let i = 0; i < paths.length; i++) {
      paths[i].removeAttribute("fill");
    }
  };

  const params = useParams();

  useEffect(() => {
    let string: any = "";
    window.scrollTo(0, 0);

    const { news } = params;

    string = news;

    setNews(string);
  }, [params]);

  useEffect(() => {
    console.log(news, "NEWS");
  }, [news]);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(updateLoaded());
  }, []);

  const newsObj: any = {
    "1": <NewsOne />,
    "2": <NewsTwo />,
    "3": <NewsThree />,
    "4": <NewsFour />,
    "5": <NewsFive />,
    "6": <NewsSix />,
    "7": <NewsSeven />,
    "8": <NewsEight />,
    "9": <NewsNine />,
    "10": <NewsTen />,
    "11": <NewsEleven />,
    "12": <NewsTwelve />,
    "13": <NewsThirteen />,
    "14": <NewsFourteen />,
    "15": <NewsFifteen />,
    "16": <NewsSixteen />,
    "17": <NewsSeventeen />,
    "18": <NewsEighteen />,
    "19": <NewsNineteen />,
    "20": <NewsTwenty />,
    "21": <NewsTwentyone />,
    "22": <NewsTwentytwo />,
  };

  const handleNext = () => {
    if (news === "22") {
      Notify.failure("Sorry you have reached the end of our articles");
    } else {
      navigate(`/news/${+news + 1}`);
    }
  };
  const handlePrev = () => {
    if (news === "1") {
      Notify.failure("Sorry you have reached the end of our articles");
    } else {
      navigate(`/news/${+news - 1}`);
    }
  };

  return (
    <Cont>
      {newsObj[news] ? newsObj[news] : <NotFoundPage />}

      {width! > 800 ? (
        <ProgCont>
          <ArrowDiv
            disabled={news === "1" ? true : false}
            onMouseEnter={(e) => handleBoxHover(e, "one")}
            onMouseLeave={(e) => handleBoxLeave(e, "one")}
            onClick={handlePrev}
          >
            <Arrow
              stroke={items.one ? "red" : "black"}
              fill={items.one ? "red" : "black"}
            />
            <p>Previous</p>
          </ArrowDiv>

          <ProgBar>
            <Prog width={`${articlePercent}`}></Prog>
          </ProgBar>
          <ArrowDiv
            disabled={news === "22" ? true : false}
            onMouseEnter={(e) => handleBoxHover(e, "two")}
            onMouseLeave={(e) => handleBoxLeave(e, "two")}
            onClick={handleNext}
          >
            <p>Next Article</p>
            <Arrow
              stroke={items.two ? "red" : "black"}
              fill={items.two ? "red" : "black"}
            />
          </ArrowDiv>
        </ProgCont>
      ) : (
        <ProgCont>
          <ProgBar>
            <Prog width={`${articlePercent}`}></Prog>
          </ProgBar>
          <BottomRow>
            <ArrowDiv
              disabled={news === "1" ? true : false}
              onMouseEnter={(e) => handleBoxHover(e, "one")}
              onMouseLeave={(e) => handleBoxLeave(e, "one")}
              onClick={handlePrev}
            >
              <Arrow
                stroke={items.one ? "red" : "black"}
                fill={items.one ? "red" : "black"}
              />
              <p>Previous</p>
            </ArrowDiv>
            <ArrowDiv
              disabled={news === "22" ? true : false}
              onMouseEnter={(e) => handleBoxHover(e, "two")}
              onMouseLeave={(e) => handleBoxLeave(e, "two")}
              onClick={handleNext}
            >
              <p>Next Article</p>
              <Arrow
                stroke={items.two ? "red" : "black"}
                fill={items.two ? "red" : "black"}
              />
            </ArrowDiv>
          </BottomRow>
        </ProgCont>
      )}
    </Cont>
  );
};

export default IndividualNews;
