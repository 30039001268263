import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import {
  BodyCont,
  Div,
  Header,
  ImageContainer,
  NewsCont,
  SourcesCont,
} from "../styles";

const NewsTwentyone = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(92));
  }, []);

  return (
    <NewsCont>
      <Header>WE CHALLENGE A GOVERNMENT RATE FUND</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>March 25, 2021 - 2:38pm</p>
      </Div>
      <ImageContainer>
        <img
          src={width! > 450 ? thoughtImg.bigTwentyone : thoughtImg.sTwentyone}
        />
      </ImageContainer>
      <BodyCont>
        <h3>
          In response to the Government and DCLG’s consultation on the proposed
          £300m Business Rates relief fund, CDG have made representations.
        </h3>
        <p>
          This relates to business occupiers facing hardship as a result of the
          Revaluation and increases in rateable values from 1st April 2017.
        </p>
        <p>
          We fully support and welcome the £300m relief fund for business
          occupiers facing hardship because of the revaluation. However, we are
          concerned that it falls way short of what is needed and is too
          centrally defined/capped. Relief should target those experiencing
          hardship where their ability to trade has been severely affected due
          to the cost of business rates. This should be regardless of Rateable
          Value size (currently proposed less than £200,000) since otherwise
          those with larger RV’s, which could include significant employers and
          companies requiring support have no access to any relief fund.
        </p>
        <p>
          Also, it should not be limited to those facing an increase in their
          bills following revaluation. Business rates are a significant overhead
          and one that can affect the viability of a business. Relief should be
          available where there are legitimate business reasons why relief would
          ensure the viability of that business.
        </p>
        <h3>CDG’S ALTERNATIVE PROPOSALS ARE THAT:</h3>
        <p>
          All ratepayers should have access to Business Rates Relief regardless
          of size. The current proposals ignore the largest ratepayers. The
          larger ratepayers contribute the largest amounts in rates payable and
          should be listened to and have access to relief where they are
          experiencing hardship.The Business Rates Relief fund should be split
          to Local Authorities as per the total amount of Rateable Value within
          individual Local Authorities and all ratepayers should have access to
          the fund.The fund could be subject to a call in say the first half of
          the rating year and an allocation in the second half of the rating
          year, with this rolling through the Rating List and not limited to 4
          years of the list.If individual Local Authorities have surplus funds
          in a rating year, these to be redistributed to other Local Authorities
          within the rating year.An alternative is to cap 2017 list increases
          across the board to those areas particularly hit by increases ie
          London and the South. Present Transitional Relief arrangements are
          wholly inadequate and more punitive than in previous Rating Lists.
          Either a relief fund or cap should cover this or Transitional
          Arrangements need fully adjusting.Ratepayers facing hardship because
          of the 2017 Revaluation extend across small, medium and large
          businesses with the latter being significant employers and
          contributors to rates revenues. As the proposals currently stand, only
          those with smaller RV’s and increases can access the fund and
          significant rates contributors are inequitably not allowed access to a
          fund which should be seeking to address hardship and business
          viability regardless of size or increase in rates payable.
        </p>
        <p>
          If you would like to discuss or seek advice regarding business rates
          or any hardship/relief, feel free to have a chat with our Landlord &
          Tenant specialist David Ford on{" "}
          <a href="tel:0207 100 5520">0207 100 5520</a> or{" "}
          <a href="mailto:davidf@cdgleisure.com">davidf@cdgleisure.com</a>
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsTwentyone;
