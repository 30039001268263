import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../index";

interface props {
  articlePercent: number;
}

const initialState: props = {
  articlePercent: 0,
};

export const articlesSlice = createSlice({
  name: "loaded",

  initialState,
  reducers: {
    updateArticles: (state, action: PayloadAction<number>) => {
      state.articlePercent = action.payload;
    },
  },
});

export const { updateArticles } = articlesSlice.actions;

export const getLoaded = (state: RootState) => state.articles;

export default articlesSlice.reducer;
