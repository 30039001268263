import {useNavigate} from "react-router-dom";
import {FooterLinksContainer} from "../styles";

const FooterLinks = () => {
  const navigate = useNavigate();

  return (
    <FooterLinksContainer>
      <p>Links</p>
      <a onClick={() => navigate("/about")}>About Us</a>
      <a onClick={() => navigate("/services")}>Services</a>
      <a onClick={() => navigate("/news")}>News</a>
      <a onClick={() => navigate("/contact")}>Contact</a>
    </FooterLinksContainer>
  );
};

export default FooterLinks;
