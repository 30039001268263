import one from "./one.png";
import two from "./two.png";
import three from "./three.png";
import four from "./four.png";
import five from "./five.png";
import six from "./six.png";
import seven from "./seven.png";
import eight from "./eight.png";
import nine from "./nine.png";
import ten from "./ten.png";
import eleven from "./eleven.png";
import twelve from "./twelve.png";
import oneM from "./oneM.png";
import twoM from "./twoM.png";
import threeM from "./threeM.png";
import fourM from "./fourM.png";
import fiveM from "./fiveM.png";
import sixM from "./sixM.png";
import sevenM from "./sevenM.png";
import eightM from "./eightM.png";
import nineM from "./nineM.png";
import tenM from "./tenM.png";
import elevenM from "./elevenM.png";
import twelveM from "./twelveM.png";

import new1 from "./new1.png";
import new1M from "./new1M.png";
import new2 from "./new2.webp";
import new2M from "./new2M.png";
import new3 from "./new3.png";
import new3M from "./new3M.png";
import new4 from "./new4.png";
import new4M from "./new4M.png";
import new5 from "./new5.png";
import new5M from "./new5M.png";
import new6 from "./new6.png";
import new6M from "./new6M.png";
import new7 from "./new7.png";
import new7M from "./new7M.png";
import new8 from "./new8.png";
import new8M from "./new8M.png";

import twoBig from "./twoBig.png";
import newsSmall from "./newsSmall.png";

import bigOne from "./bigOne.png";
import bigTwo from "./bigTwo.png";
import bigThree from "./bigThree.png";
import bigFour from "./bigFour.png";
import bigFive from "./bigFive.png";
import bigSix from "./bigSix.png";
import bigSeven from "./bigSeven.png";
import bigEight from "./bigEight.png";
import bigNine from "./bigNine.png";
import bigTen from "./bigTen.png";
import bigEleven from "./bigEleven.png";
import bigTwelve from "./bigTwelve.png";
import bigThirteen from "./bigThirteen.png";
import bigFourteen from "./bigFourteen.png";
import bigFifteen from "./bigFifteen.png";
import bigSixteen from "./bigSixteen.png";
import bigSeventeen from "./bigSeventeen.png";
import bigEighteen from "./bigEighteen.png";
import bigNineteen from "./bigNineteen.png";
import bigTwenty from "./bigTwenty.png";
import bigTwentyone from "./bigTwentyone.png";
import bigTwentytwo from "./bigTwentytwo.png";

import sOne from "./sOne.png";
import sTwo from "./sTwo.png";
import sThree from "./sThree.png";
import sFour from "./sFour.png";
import sFive from "./sFive.png";
import sSix from "./sSix.png";
import sSeven from "./sSeven.png";
import sEight from "./sEight.png";
import sNine from "./sNine.png";
import sTen from "./sTen.png";
import sEleven from "./sEleven.png";
import sTwelve from "./sTwelve.png";
import sThirteen from "./sThirteen.png";
import sFourteen from "./sFourteen.png";
import sFifteen from "./sFifteen.png";
import sSixteen from "./sSixteen.png";
import sSeventeen from "./sSeventeen.png";
import sEighteen from "./sEighteen.png";
import sNineteen from "./sNineteen.png";
import sTwenty from "./sTwenty.png";
import sTwentyone from "./sTwentyone.png";
import sTwentytwo from "./sTwentytwo.png";

import bar1 from "./bar1.svg";
import bar2 from "./bar2.svg";
import bar3 from "./bar3.svg";
import bar4 from "./bar4.svg";
import bar5 from "./bar5.svg";
import bar6 from "./bar6.svg";
import bar7 from "./bar7.svg";
import bar8 from "./bar8.svg";
import bar9 from "./bar9.svg";
import bar10 from "./bar10.svg";

const thoughtImg = {
  bar1,
  bar2,
  bar3,
  bar4,
  bar5,
  bar6,
  bar7,
  bar8,
  bar9,
  bar10,
  sOne,
  sTwo,
  sThree,
  sFour,
  sFive,
  sSix,
  sSeven,
  sEight,
  sNine,
  sTen,
  sEleven,
  sTwelve,
  sThirteen,
  sFourteen,
  sFifteen,
  sSixteen,
  sSeventeen,
  sEighteen,
  sNineteen,
  sTwenty,
  sTwentyone,
  sTwentytwo,
  bigOne,
  bigTwo,
  bigThree,
  bigFour,
  bigFive,
  bigSix,
  bigSeven,
  bigEight,
  bigNine,
  bigTen,
  bigEleven,
  bigTwelve,
  bigThirteen,
  bigFourteen,
  bigFifteen,
  bigSixteen,
  bigSeventeen,
  bigEighteen,
  bigNineteen,
  bigTwenty,
  bigTwentyone,
  bigTwentytwo,
  one,
  two,
  three,
  four,
  five,
  six,
  newsSmall,
  seven,
  eight,
  twoBig,
  nine,
  ten,
  eleven,
  twelve,
  oneM,
  twoM,
  threeM,
  fourM,
  fiveM,
  sixM,
  sevenM,
  eightM,
  nineM,
  tenM,
  elevenM,
  twelveM,
  new1,
  new1M,
  new2,
  new2M,
  new3,
  new3M,
  new4,
  new4M,
  new5,
  new5M,
  new6,
  new6M,
  new7,
  new7M,
  new8,
  new8M,
};

export default thoughtImg;
