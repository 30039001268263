import { Address, FooterLinksContainer } from "../styles";

const FooterAddress = () => {
  return (
    <FooterLinksContainer>
      <p>Our Address</p>
      <Address>
        30 Berwick Street,
        <br />
        Soho, London, <br />
        W1F 8RH
      </Address>
    </FooterLinksContainer>
  );
};

export default FooterAddress;
