import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsFour = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(13.5));
  }, []);

  return (
    <NewsCont>
      <Header>
        HOW TO REDUCE YOUR RENT AS A BUSINESS OWNER FACING THE COST-OF-LIVING
        CRISIS
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>February 9, 2023 - 4:28pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigFour : thoughtImg.sFour} />
      </ImageContainer>
      <BodyCont>
        <h3>
          If you're a business owner facing the cost-of-living crisis, paying
          rent can feel like a serious challenge.
        </h3>
        <p>
          However, there is a solution to help ease the burden:
          <b> negotiating with your landlord</b>. Rent is a large, fixed cost
          that can impact your business’s bottom line, so it’s important to
          consider all options to reduce it
        </p>
        <p>
          Here are some tips to help you successfully re-negotiate your rent:
        </p>

        <h5>1. Do your research.</h5>
        <p>
          Before negotiating, research the local rental market to determine what
          other businesses in your area are paying for similar space. Use this
          information to support your case for a lower rent.
        </p>

        <h5>2. Prepare a solid argument.</h5>
        <p>
          When negotiating with your landlord, it’s important to have a case for
          why you’re asking for a rent reduction. Explain your financial
          situation and provide evidence of your current struggles.
        </p>
        <h5>3. Be flexible</h5>
        <p>
          Offer to make concessions, such as a longer lease term, in exchange
          for a lower rent. This can show your landlord that you’re serious
          about making a commitment to the space and can help build a stronger
          relationship between you.
        </p>
        <h5>4. Consider alternative arrangements</h5>
        <p>
          If your landlord is unwilling to reduce your rent, consider
          alternative arrangements such as a rent deferment or a rent holiday.
          This can provide temporary relief while you work to improve your
          financial situation.
        </p>
        <p>Look to sublet your lease and find a more affordable site.</p>
        <p>
          Negotiating your rent can be a difficult task, but it’s essential for
          businesses facing the cost-of-living crisis. Burying your head in the
          sand often makes things worse. The sooner you take action, the better.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsFour;
