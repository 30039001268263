import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import {
  BodyCont,
  Div,
  Header,
  ImageContainer,
  NewsCont,
  SourcesCont,
} from "../styles";

const NewsTwentytwo = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(100));
  }, []);

  return (
    <NewsCont>
      <Header>WORRIED ABOUT YOUR RENT REVIEW?</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>February 25, 2021 - 2:45pm</p>
      </Div>
      <ImageContainer>
        <img
          src={width! > 450 ? thoughtImg.bigTwentytwo : thoughtImg.sTwentytwo}
        />
      </ImageContainer>
      <BodyCont>
        <h3>
          London 2017 is proving to be a very uncertain and unpredictable market
          in the light of the surprise election, Brexit, terrorism and other
          related factors which are having impacts.
        </h3>
        <p>
          It is well known that rents have risen dramatically in the centre of
          London over the last five years and therefore many operators are
          worried about their next rent review. The issue is that once these
          rent reviews pass, normally the landlords will appoint a surveyor to
          get the evidence for them in the neighbouring area. This will usually
          involve you needing to employ a surveyor as well which will then take
          you down a track governed by the RICS which is simply process driven.
          The RICS, which has for many decades not changed its stance in
          relation to economic circumstances, does not take into account
          affordability, and so rents can double, treble, quadruple whether the
          new tenant can afford it or not and assumes that there would be a
          plethora of new tenants to take up such rents at the new level.
        </p>
        <p>
          Our advice and recommendation to operators is to consider upcoming
          rent reviews up to twelve months in advance of any trigger date and
          have honest upfront conversations with landlords based on current
          evidence.
        </p>
        <p>
          What we are doing for many of our clients is taking early action,
          giving them the current evidence and then approaching directly to try
          and agree terms based on their economic affordability rather than
          simply the pounds per square foot and this will often result in a fair
          increase for the landlords and also avoid hefty costs from the RICS
          appointed arbitrators and unnecessary delay and increased uncertainty.
        </p>
        <p>
          We would like to urge the RICS to look at how they consider the
          settling of rents, especially in a changing economic environment but
          our main call is to operators to take early action and to contact us
          in order that we can support and assist companies through what could
          be a challenging trading period.
        </p>
        <h3>
          “THE KEY IS TO AVOID THE SURVEYOR SCIENTIFIC ROUTE AND WORK ON ACTUAL
          REAL COMMERCIALITY.”
        </h3>
        <p>
          It is also to be pointed out that if any operators are looking to
          assign their leases these will need to be assigned once rent reviews
          are settled and it will be very hard to assign these leases with hefty
          rent reviews outstanding. The key is to avoid the surveyor scientific
          route and work on actual real commerciality, obviously with the basis
          of what evidence has been achieved in the area.
        </p>
        <p>
          We actually find that many landlords and asset managers that we deal
          with are open minded to agree terms with operators they like and they
          see as being able to work with in the longer term, and are not
          necessarily committed to achieving the highest rent but actually
          understand that the right tenant mix should be the focus. However, the
          driver for many of the landlords’ surveyors is to achieve the highest
          rent possible as their fees are often linked to this.
        </p>
        <p>
          Once again the key is that a strategic proactive approach is needed.
        </p>
        <p>
          If you would like to discuss or seek advice regarding your rent review
          and business objectives, feel free to have a chat with our Landlord &
          Tenant specialist David Ford on{" "}
          <a href="tel:0207 100 5520">0207 100 5520</a> or{" "}
          <a href="mailto:davidf@cdgleisure.com">davidf@cdgleisure.com</a>.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsTwentytwo;
