import valuesData from "../../landing/values/data";

import { useInView } from "react-intersection-observer";
import { Block, RightContainer } from "./styles";

const IndValues = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });
  return (
    <RightContainer ref={ref}>
      {valuesData.map((val: any, i: any) => {
        return (
          <Block key={i} isVisible={inView} delay={`0.${i}s`}>
            <img src={val.img} />
            <div>
              <h1>{val.header}</h1>
              <p>{val.text}</p>
            </div>
          </Block>
        );
      })}
    </RightContainer>
  );
};

export default IndValues;
