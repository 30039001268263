import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsSix = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(21.5));
  }, []);

  return (
    <NewsCont>
      <Header>
        PERSONAL GUARANTEES FOR COMMERCIAL LEASES – WHY YOU SHOULD BE SAYING
        ‘NO’ WHEN ASKED FOR A PERSONAL GUARANTEE
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>May 31, 2022 - 1:01pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigSix : thoughtImg.sSix} />
      </ImageContainer>
      <BodyCont>
        <h3>
          The risks of personal guarantees for commercial leases. Why you should
          avoid signing for a personal guarantee.
        </h3>
        <p>
          Some landlords still ask commercial tenants for personal guarantees
          when they sign a lease. As champions of commercial tenants’ rights,
          Cedar Dean advise our clients to steer clear of personal guarantees.
          Here we explore why you should be saying no to personal guarantees for
          commercial leases.
        </p>

        <h3>What is a personal guarantee on a commercial lease?</h3>
        <p>
          When signing a tenant up to a commercial lease, some landlords ask for
          a personal guarantee, usually from the director of the limited company
          taking on the lease. The purpose is to provide a backup if the limited
          company is unable to pay the rent for any reason.
        </p>
        <p>
          This means that a director who has signed a personal guarantee could
          be responsible for personally covering the rent on the property if
          their business goes under.
        </p>
        <p>
          The structure of a limited company protects directors from personal
          responsibility for a company’s financial liabilities. If you sign a
          personal guarantee, you are foregoing this protection – and agreeing
          to cover outstanding rent from your own pocket.
        </p>
        <p>
          This can damage your personal finances and your personal credit score.
          In worst-case scenarios, a landlord could claim rent arrears through
          court proceedings, which could result in bankruptcy if you can’t pay.
        </p>
        <h3>Why do people sign a personal guarantee?</h3>
        <p>
          Company directors continue to sign personal guarantees for a number of
          reasons.
        </p>
        <p>
          Often, a landlord requests a personal guarantee because a business has
          a limited credit history. If a business is relatively new, the owner
          may not have leased property before and lacks the track record that
          some landlords require.
        </p>
        <p>
          Another reason a business director might agree to a personal guarantee
          is to secure what they view to be a perfect property at the perfect
          address. Sometimes, a business owner’s company is in such a strong
          financial position that they cannot foresee any problems arising. This
          false sense of security can lead to some questionable decision-making
          when under pressure to find an appropriate premises.
        </p>
        <p>
          These reasons are all understandable, but a personal guarantee is
          never a good option. Giving a personal guarantee allows you to become
          liable for your business’s commercial failings or responsibilities.
          Keep personal, personal and business, business. Blurring those lines
          always creates issues at some point down the line.
        </p>

        <h3>
          Why are personal guarantees still sometimes requested when taking on
          commercial properties?
        </h3>
        <p>
          Some landlords feel that, in some situations, it’s too risky to offer
          a lease to a particular business without a fall-back option should the
          tenant be unable to pay the rent they are contractually obliged to
          pay. A number of reasons are given for requesting personal guarantees.
          These include the tenants being a relatively new business, or the
          business operating in an industry the landlord deems to be ‘higher
          risk’ such as hospitality or leisure.
        </p>
        <h3>
          According to the law, do you have to pay the commercial lease arrears
          for your property if your business can’t?
        </h3>
        <p>
          If the landlord decides to pursue the case, then yes. There are
          situations where landlords might not attempt to retrieve the rent from
          a director personally, especially if it’s clear that they will not
          have the funds to cover the arrears. This is due to the fact that
          filing a bankruptcy petition does cost money and it might not be worth
          the landlord’s while, especially if they will find it relatively easy
          to find a new tenant for the premises.
        </p>
        <h3>How to avoid agreeing to a lease guarantee</h3>
        <p>
          Business directors who agree to personal guarantees often do so with a
          misguided sense that their business will never fall into financial
          difficulty. Although a business’s situation may be great right now,
          things can change in a heartbeat (we have all learned this from the
          pandemic)
        </p>
        <p>
          If you have signed a personal guarantee, with a little support, you
          may be able to remove it from your lease if you have supporters such
          as Cedar Dean fighting your corner. If you’re considering signing a
          personal guarantee in an attempt to secure your dream property, think
          again. It’s always better to lose a property than to sign a personal
          guarantee.
        </p>
        <p>
          We’ve seen clients who have lost everything simply because they signed
          a personal guarantee. Most people can cope with a business going
          under, but when your personal finances also suffer, that’s a whole
          other story.
        </p>
        <p>
          • Pressure to sign as the tenant has little to know credit history{" "}
          <br />• Pressure to not miss “the perfect space/ perfect address”
          <br /> • Businesses in a strong financial position that don’t see the
          risk
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsSix;
