import trophy from "./trophy.svg";
import fingerprint from "./fingerprint.svg";
import bulb from "./bulb.svg";
import target from "./target.svg";

const values = {
  trophy,
  fingerprint,
  bulb,
  target,
};

export default values;
