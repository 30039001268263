import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import {
  AboutInfoCont,
  ButtonCont,
  Div,
  Header,
  Image,
  RedBtn,
} from "./styles";

import { useInView } from "react-intersection-observer";
import useWindowSize, { Size } from "../../../utils/getWindowSize";

const AboutInfo = () => {
  const navigate = useNavigate();

  const size: Size = useWindowSize();

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <AboutInfoCont inView={inView}>
      <Header>
        CEDAR DEAN: YOUR GO-TO ADVISER IN THE COMMERCIAL TENANCY SECTOR
      </Header>
      <Div>
        <div>
          <p ref={size.width! < 800 ? ref : null}>
            Following the Lehman Brothers crash and the financial crisis in
            September 2008, Cedar Dean’s founder David Abramson was inundated
            with requests for support and advice on commercial rents. Working as
            a prominent hospitality prospect adviser, David saw just how many
            businesses needed support and set about his mission to create
            change. 
          </p>
          <p ref={size.width! > 800 ? ref : null}>
            Historically, businesses faced insolvency when rents became
            unaffordable. This inspired David to create the concept of
            sustainable lease restructuring. This service offered businesses
            greater hope regarding renegotiating the terms of their lease before
            it was up for renewal to avoid an insolvency process.
          </p>
        </div>
        <div>
          <p>
            Together with Lee Isaacs, a chartered surveyor with over a decade of
            lease restructuring experience, David embarked on a journey to
            revolutionise an unfair rent system and better base it on
            affordability.
          </p>
          <p>
            Post-pandemic, the work of Cedar Dean has become even more essential
            and relevant than ever before. As businesses across the globe are
            impacted by rising prices and rents, it is even more vital that they
            are based on affordability and not simply a pounds per square foot
            basis.
          </p>
          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </div>
      </Div>
      <Image src={img.aboutInfo} alt="background shape" />
    </AboutInfoCont>
  );
};

export default AboutInfo;
