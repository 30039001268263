import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import { Left, Right, Text } from "./styles";

import { useInView } from "react-intersection-observer";

const Two = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "ACQUISITIONS",
    img: servImg.sTwoBig,
    imgM: servImg.twoM,

    quote: {
      img: servImg.geox,
      text: `“I strongly recommend Cedar Dean: Our group engaged them in the frame of a challenging restructuring project of our retail chain in the UK. Cedar Dean’s team is excellent in the deep understanding of business needs in a post-pandemic retail perspective and were extraordinary in the negotiations.”`,
      from: (
        <h4>
          Valentino Maffei, Director of Legal Affairs - <b>GEOX</b>
        </h4>
      ),
    },

    body: [
      "The acquisition process can often prove to be a complex, intricate process which many businesses go through or at least plan to go through.",
      " Acquisition refers to the legal transfer of ownership or rights to a property, though in reality it’s a longer, far less simple process which can quickly amount to a lot of stress and pressure on all parties involved.",
      " Cedar Dean is here to be your expert guide on the whole acquisition process: our team will review the property you intend to acquire, give our recommendations and advice, and help you negotiate a fair, beneficial agreement between you and the current property owners to ensure you get the best deal possible.",
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>{data.header}</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>
        <BannerInner>
          <p>{data.quote.text}</p>
          <img src={data.quote.img} />
          {data.quote.from}
        </BannerInner>
      </Banner>
      <Text inView={inView}>
        <Left>
          <p>{data.body[0]}</p>
          <p ref={ref}>{data.body[1]}</p>
          <p>{data.body[2]}</p>
        </Left>
        <Right>
          <p>{data.body[3]}</p>
          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
    </Container>
  );
};

export default Two;
