import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import { Left, Right, Text } from "./styles";

import { useInView } from "react-intersection-observer";

const Three = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "LEASE RENEWALS",
    img: servImg.sThreeBig,
    imgM: servImg.threeM,

    quote: {
      img: servImg.revolution,
      text: `“Time and time again Cedar Dean have delivered for us, providing a professional service and outstanding results on our lease negotiations when we most need it.”`,
      from: (
        <h4>
          Rob Pitcher, CEO - <b>Revolution Bars Group</b>
        </h4>
      ),
    },

    body: [
      "  If your lease is approaching renewal, don’t miss the opportunity to negotiate more favourable terms, or even reduce your rent. Cedar Dean looks to help our clients to make the most of this opportunity. We can help you even if your original tenancy agreements didn’t include the right to a lease renewal. ",
      "When we oversee a lease renewal for a client, we can help negotiate more favourable terms and actual rent reductions. Although rent reviews can only result in an increase in rent, a lease renewal is your opportunity to reduce the rent you have to pay for your commercial property: it’s our mission to make sure you don’t miss this opportunity and take full advantage of it.",
      "Our team has the experience and expertise to make a difference to the outcome of your lease renewal. Starting the process of negotiation early is key to success, so start thinking about seeking lease renewal advice around 24 months before it expires. Contact us today to get started.",
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>{data.header}</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>

        <BannerInner>
          <p>{data.quote.text}</p>
          <img src={data.quote.img} />
          {data.quote.from}
        </BannerInner>
      </Banner>
      <Text inView={inView}>
        <Left>
          <p>{data.body[0]}</p>
          <p ref={ref}>{data.body[1]}</p>
        </Left>
        <Right>
          <p>{data.body[2]}</p>

          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
    </Container>
  );
};

export default Three;
