import styled, { keyframes, css } from "styled-components";
import img from "../../../assets";

const shrink = keyframes`
from{
min-height: 100vh ;
}

to{


min-height: 0px ;
}
`;

const slideIn = keyframes`
from{
transform: translateX(-100%);
opacity: 0;
}

to{

  transform: translateX(0%);
opacity: 1;
}
`;

interface props {
  loaded: boolean;
}

export const HeroContainer = styled.div<props>`
  ${({ loaded }) =>
    loaded
      ? css`
          min-height: 0vh;
        `
      : css`
          animation: ${shrink} 3s 1.5s forwards;
          min-height: 100vh;
        `}

        


background-image: url(${img.landingHeroBg}); 
  background-size: 100%; 
  padding-top: 149px;
  padding-bottom: 72px;
  margin-top: -83px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #133244;

overflow: hidden;

/* picture{
display: none;
} */
  @media (max-width: 800px) {
    padding-right: 50px;
    margin-top: -116px;

  }





};
`;
export const MainText = styled.h1<props>`
  ${({ loaded }) =>
    !loaded &&
    css`
      animation: ${slideIn} 1.5s 2.4s forwards;
      opacity: 0;
    `}

  font-weight: 400;
  font-size: 64px;
  line-height: 84px;
  letter-spacing: 0.06em;
  color: #f8f8f8;
  max-width: 585px;
  margin-left: 64px;

  @media (max-width: 800px) {
    margin-left: 16px;
    font-size: 48px;
    line-height: 56px;
  }
`;

export const SmallerText = styled.p<props>`
  ${({ loaded }) =>
    !loaded &&
    css`
      animation: ${slideIn} 1.5s 2.4s forwards;
      opacity: 0;
    `}

  margin-left: 64px;
  max-width: 563px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  color: #f8f8f8;

  @media (max-width: 800px) {
    margin-left: 16px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ButtonContainer = styled.div<props>`
  ${({ loaded }) =>
    !loaded &&
    css`
      animation: ${slideIn} 1.5s 2.4s forwards;
      opacity: 0;
    `}

  margin-left: 64px;
  margin-top: 40px;

  @media (max-width: 800px) {
    margin-left: 16px;
  }
`;

export const Inner = styled.div``;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
 
    object-fit:contain;

  background-color: #133244;
  @media(min-width:801px){
    height: 100%;
    object-fit:cover;

  }

  @media(max-width:380px){
    height: 100%;
    object-fit:cover;
  
  }
`;
