import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import img from "../../assets";
import HeroIcons from "../../components/landing/hero/icons";
import RedButton from "../../components/reusable/redButton";
import { RootState } from "../../redux";
import useWindowSize, { Size } from "../../utils/getWindowSize";
import FooterSocials from "../footer/socials";
import HeaderServ from "./services";
import {
  Burger,
  BurgerPage,
  ButtonCont,
  Div,
  HeaderContainer,
  IconsContainer,
  Logo,
  RedBtn,
  RightSide,
  Test,
  X,
} from "./styles";

const Header = () => {
  const { loaded } = useSelector((state: RootState) => state.loaded);

  const [page, setPage] = useState(true);
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const size: Size = useWindowSize();
  const [burgerOpen, setBurgerOpen] = useState(false);

  const [dark, setDark] = useState(false);
  const [sticky, setSticky] = useState(false);

  const [showing, setShowing] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const [about, setAbout] = useState<boolean>(false);

  useEffect(() => {
    const path = window.location.pathname;

    setBurgerOpen(false);

    if (path === "/" || path === "/about") {
      setPage(true);
    } else {
      setPage(false);
    }

    if (path === "/about") {
      setAbout(true);
    }
  }, [window.location.pathname]);

  const onClick = () => {
    navigate("/");
  };

  const [logoAnimationFinished, setLogoAnimationFinished] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDark(true);
      setLogoAnimationFinished(true);
    }, 2150);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && logoAnimationFinished) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [logoAnimationFinished]);

  const [logoVisible, setLogoVisible] = useState(true);

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/") {
      setTimeout(() => {
        setDisabled(false);
      }, 3400);
    } else {
      setDisabled(false);
    }
  }, []);

  const [headerBackground, setHeaderBackground] = useState(false);

  const handleNav = (x: string) => {
    navigate(x);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setHeaderBackground(true);
        setHover(true);
      } else {
        setHeaderBackground(false);
        setHover(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const clickSocial = (url: string) => {
    window.open(url, "_blank");
  };

  //fix this on big screens

  return (
    <Test>
      {" "}
      {logoVisible && (
        <Logo
          src={headerBackground || !page ? img.logoDark : img.logo}
          style={{
            cursor: loaded ? "pointer" : "default",
          }}
          onClick={onClick}
          alt="logo"
          loaded={loaded}
          sticky={sticky}
          page={page}
        />
      )}
      <HeaderContainer
        about={about}
        loaded={loaded}
        page={page}
        style={{
          background: headerBackground || !page ? "#f8f8f8" : "transparent",
          borderBottom:
            headerBackground || !page
              ? "0.6px solid #b5c6ce"
              : "1px solid white",
        }}
      >
        <RightSide page={page} scroll={hover}>
          <p onClick={() => (disabled ? null : handleNav("/about"))}>
            About Us
          </p>
          <div onClick={() => (disabled ? null : navigate("/services"))}>
            <p
              onMouseEnter={() => setShowing(true)}
              onMouseLeave={() => setShowing(false)}
            >
              Services
            </p>
            <img
              src={hover ? img.dropdown2 : !page ? img.dropdown2 : img.dropdown}
            />
          </div>
          <p onClick={() => (disabled ? null : handleNav("/news"))}>News</p>
          <button
            disabled={disabled}
            onClick={() => (disabled ? null : navigate("/contact"))}
          >
            Contact Us
          </button>
        </RightSide>
        <HeaderServ showing={showing} setShowing={setShowing} />

        <Burger
          src={headerBackground || !page ? img.burger : img.burgerWhite}
          alt="burger"
          onClick={() => setBurgerOpen(true)}
        />
      </HeaderContainer>
      <BurgerPage open={burgerOpen}>
        <img src={img.logoDark} onClick={onClick} alt="logo" />
        <X src={img.burgerX} alt="x" onClick={() => setBurgerOpen(false)} />

        <Div onClick={() => navigate("/about")}>
          <h3>About Us</h3>
          <img src={img.burgerRight} />
        </Div>
        <Div onClick={() => navigate("/services")}>
          <h3>Services</h3>
          <img src={img.burgerRight} />
        </Div>

        <p onClick={() => navigate("/services/restructuring-advice")}>
          Restructuring Advice
        </p>
        <p onClick={() => navigate("/services/acquisitions")}>Acquisitions</p>
        <p onClick={() => navigate("/services/lease-renewals")}>
          Lease Renewals
        </p>
        <p onClick={() => navigate("/services/lease-surrenders")}>
          Lease Surrenders
        </p>
        <p onClick={() => navigate("/services/rent-reviews")}>Rent Reviews</p>
        <p onClick={() => navigate("/services/rent-reductions")}>
          Rent Reductions
        </p>
        <p onClick={() => navigate("/services/strategic-lease-management")}>
          Strategic Lease Management
        </p>
        <Div onClick={() => navigate("/news")}>
          <h3>News</h3>
          <img src={img.burgerRight} />
        </Div>

        <ButtonCont onClick={() => navigate("/contact")}>
          <RedBtn>
            <span>Contact Us</span>
          </RedBtn>
          <div>
            <img src={img.rightArrow} alt="rightArrow" />
          </div>
        </ButtonCont>

        <IconsContainer>
          <img
            src={img.linkedinRed}
            onClick={() =>
              clickSocial("https://www.linkedin.com/company/cedar-dean/")
            }
          />
          <img
            src={img.facebookRed}
            onClick={() => clickSocial("https://www.facebook.com/CedarDeanUK/")}
          />
          <img
            src={img.twitterRed}
            onClick={() =>
              clickSocial("https://twitter.com/cedardean?lang=en-GB")
            }
          />
          <img
            src={img.instagramRed}
            onClick={() => clickSocial("https://www.instagram.com/cedar_dean/")}
          />
        </IconsContainer>
      </BurgerPage>
    </Test>
  );
};

export default Header;
