import styled, { keyframes, css } from "styled-components";
import img from "../../../assets";

interface props {
  page: boolean;
}

export const ContactContainer = styled.div<props>`
  width: 680px;
  overflow: hidden;
  position: relative;
  min-height: ${({ page }) => (page && "645px") || (!page && "841px")};
  height: ${({ page }) => (page && "645px") || (!page && "841px")};

  @media (max-width: 850px) {
    width: 80%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    min-height: ${({ page }) => page && "545px"};

    height: ${({ page }) => page && "545px"};
  }
`;

const slideIn = keyframes`
  from{
    opacity: 0;
    transform: translateX(-100%);
  }

  to{

    opacity: 1;
    transform: translateX(0%);

  }
`;

interface showProps {
  inView: boolean;
}

export const Inner = styled.div<showProps>`
  background: rgba(164, 21, 15, 0.8);
  opacity: 0;
  height: 100%;
  z-index: 800;
  position: relative;

  ${({ inView }) =>
    inView &&
    css`
      animation: ${slideIn} 1s forwards;
    `}

  padding: 84px 105px 125px;
  @media (max-width: 1000px) {
    padding: 84px 20px 50px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 64px 16px 69px;
  }
`;

export const Header = styled.h1`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.06em;
  color: #f8f8f8;

  margin-bottom: 8px;

  @media (max-width: 550px) {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 16px;
  }
`;

export const Smaller = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #f8f8f8;

  margin-bottom: 32px;

  @media (max-width: 550px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  div {
    margin-left: 15px;

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #f8f8f8;
      margin-bottom: 3px;

      @media (max-width: 550px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    a {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #f8f8f8;
      text-decoration: none;

      @media (max-width: 550px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #f8f8f8;

      @media (max-width: 550px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export const ContainerTwo = styled(Container)`
  div {
    h3 {
      margin-bottom: 10px;
    }
    div {
      margin: 0;
    }

    p {
      font-weight: 600;
    }
  }
`;
