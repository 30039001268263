import logo from "./logo.svg";
import dropdown from "./dropdown.svg";
import dropdown2 from "./dropdown2.svg";
import logoDark from "./logoDark.svg";
import rightArrow from "./rightArrow.svg";
import linkedin from "./linkedin.svg";
import facebook from "./facebook.svg";
import twitter from "./twitter.svg";
import instagram from "./instagram.svg";
import opop from "./opop.svg";
import tdr from "./tdr.svg";
import faqArrow from "./faqArrow.svg";
import serviceArrow from "./serviceArrow.svg";
import left1 from "./left1.svg";
import left2 from "./left2.svg";
import right1 from "./right1.svg";
import right2 from "./right2.svg";
import redArrow from "./redArrow.svg";
import mail from "./mail.svg";
import phone from "./phone.svg";
import location from "./location.svg";
import person from "./person.svg";
import linkedinRed from "./linkedinRed.svg";
import facebookRed from "./facebookRed.svg";
import twitterRed from "./twitterRed.svg";
import instagramRed from "./instagramRed.svg";
import mailRed from "./mailRed.svg";
import linkedinGrey from "./linkedinGrey.svg";
import facebookGrey from "./facebookGrey.svg";
import twitterGrey from "./twitterGrey.svg";
import instagramGrey from "./instagramGrey.svg";
import mailGrey from "./mailGrey.svg";
import burger from "./burger.svg";
import burgerX from "./burgerX.svg";
import burgerRight from "./burgerRight.svg";
import burgerWhite from "./burgerWhite.svg";
import aboutInfo from "./aboutInfo.svg";

import landingHeroBg from "./landingHeroBg.webp";
import landingHeroMobile from "./landingHeroMobile.png";
import landingTrust from "./landingTrust.webp";
import landingAuthority from "./landingAuthority_david.webp";
import testimonialBg from "./testimonialBg.webp";
import testimonialMobile from "./testimonialMobile.png";
import contactBg from "./contactBg.webp";
import aboutHeroBg from "./aboutHeroBg.webp";
import aboutHeroMobile from "./aboutHeroMobile.png";
import bannerBg from "./bannerBg.png";
import bannerBgMobile from "./bannerBgMobile.png";

import landingWebP from "./landingWebP.webp";
import landingMobileWebP from "./landingMobileWebP.webp";
import contactWebP from "./contactWebP.webp";
import authorityWebP from "./authorityWebP.webp";
import landingTrustWebP from "./landingTrust.webp";

const img = {
  logo,
  landingTrustWebP,
  authorityWebP,
  contactWebP,
  landingMobileWebP,
  landingWebP,
  linkedin,
  aboutHeroMobile,
  bannerBgMobile,
  aboutInfo,
  bannerBg,
  burgerWhite,
  burgerX,
  burgerRight,
  linkedinGrey,
  facebookGrey,
  twitterGrey,
  instagramGrey,
  burger,
  mailGrey,
  linkedinRed,
  facebookRed,
  phone,
  twitterRed,
  mailRed,
  location,
  instagramRed,
  person,
  facebook,
  twitter,
  contactBg,
  aboutHeroBg,
  instagram,
  left1,
  left2,
  right1,
  right2,
  dropdown,
  redArrow,
  mail,
  opop,
  logoDark,
  dropdown2,
  landingHeroBg,
  rightArrow,
  landingHeroMobile,
  landingTrust,
  landingAuthority,
  testimonialBg,
  tdr,
  testimonialMobile,
  faqArrow,
  serviceArrow,
};

export default img;
