import styled, { keyframes, css } from "styled-components";
import img from "../../../assets";

const slideUp = keyframes`
  from{
    opacity: 0;
    transform: translateY(100%);
  }
  to{
    opacity: 1;
    transform: translateY(0%);
  }
`;

const fadeIn = keyframes`
  from{
    opacity: 0;

  }
  to{
    opacity: 1;

  }
`;

interface props {
  inView: boolean;
}

export const BannerContainer = styled.div`
  background-image: url(${img.bannerBg});
  background-size: cover;
  background-position: center;
  position: relative;
  overflow-y: hidden;
  @media (max-width: 800px) {
    background-image: url(${img.bannerBgMobile});
  }
`;

export const Header = styled.h1`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  position: relative;
  z-index: 99;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #f8f8f8;
  max-width: 914px;
  margin: auto;
  @media (max-width: 800px) {
    font-size: 38px;
    line-height: 48px;
  }
`;

export const Inner = styled.div<props>`
  position: relative;
  min-width: 100%;
  padding: 76px 64px;
  background: rgba(245, 34, 24, 0.6);
  opacity: 0;
  ${({ inView }) =>
    inView &&
    css`
      animation: ${fadeIn} 1s 0.5s forwards;
    `}

  h1 {
    ${({ inView }) =>
      inView &&
      css`
        animation: ${slideUp} 1s 0.5s forwards;
      `}
  }
  @media (max-width: 800px) {
    padding: 90px 16px 98px;
  }
`;
