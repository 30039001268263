import styled from "styled-components";

export const ButtonCont = styled.div`
  display: flex;
  width: fit-content;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  div {
    background: #a4150f;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 18px;
    }
  }
`;

export const RedBtn = styled.button`
  background: #f52218;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #f8f8f8;
  width: 180px;
  border: none;
  transition: 0.05s linear;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 168px;
  }

  &:hover {
    cursor: pointer;
    width: 220px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 98%;
    height: 100%;
    background: #a4150f;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.1s ease-in-out;
    z-index: 1;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  span {
    position: relative;
    z-index: 2;
  }
`;
