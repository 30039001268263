import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsFifteen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(59.5));
  }, []);

  return (
    <NewsCont>
      <Header>
        COULD SHORT-TERM TURNOVER LEASES HELP DRIVE POST-COVID RECOVERY?
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>July 28, 2021 - 10:59am</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigFifteen : thoughtImg.sFifteen} />
      </ImageContainer>
      <BodyCont>
        <h3>
          Summer 2021 has brought the welcome site of shoppers and diners back
          to the UK high streets.
        </h3>
        <p>
          Things are starting to get back to normal and the hustle and bustle of
          our town centres is starting to reappear. So far, so good.
        </p>
        <p>
          Behind the scenes, the relationship between commercial property
          landlords and tenants has gone through a seismic change. The days
          where passive landlords could sit back and collect rents from the same
          tenants, on the same long-term leases, for years on end are behind us.
          Instead, tenants are insisting on more flexibility, including the use
          of short-term leases and radically different leases where rents are
          based on turnover generated from a particular location.
        </p>
        <p>
          Although there is still plenty of resistance from some commercial
          landlords, some are beginning to see the light. And we couldn’t be
          happier. Here’s why
        </p>
        <h3>What are turnover leases?</h3>
        <p>
          Traditionally, commercial leases were structured on a pounds per sq.
          ft. model. Increasingly this would create an unsustainable situation
          where tenants could no longer afford their rents and would have close
          locations down. In some particularly maddening examples, these would
          be successful locations, but rents were still untenable.
        </p>
        <p>
          Turnover leases require tenants to pay rents as a percentage of the
          turnover generated by a particular site. This means the landlords
          receive better rents when the tenants are doing well, but accept lower
          rent payments when a tenant is experiencing tougher times. If recent
          economic trends are anything to go by, these ‘tougher times’ are
          usually reflected locally and nationally, meaning any potential
          tenants would be experiencing similar challenges, so it makes sense
          for landlords to accept less in response.
        </p>
        <h3>Are turnover leases becoming more popular?</h3>
        <p>
          As covid struck and more and more business premises had to close down,
          at least temporarily, landlords were forced to accept more flexible
          lease agreements and rent structures. It was that, or find themselves
          with no rental income, and often no tenants.
        </p>
        <p>
          These flexible terms took many different forms. Some introduced
          deferred rental payments, rent-free periods or rent reductions. These
          measures aren’t long-term solutions, instead acting like a bandage
          over the wound. Those landlords that agreed to new lease structures,
          such as turnover leases, were taking a longer-term view and many such
          agreements are likely to remain in place as we emerge from the
          pandemic.
        </p>
        <h3>Why are short-term turnover leases a good idea?</h3>
        <p>
          Shorter-term leases, such as 6-12 months leases, allow tenants of all
          kinds to flourish and succeed on the high street. By encouraging
          newer, smaller and more innovative businesses to take locations in our
          town centres, we are driving the commercial property market forward,
          into the future.
        </p>
        <p>
          Large retail chains initially moved to retail parks a decade or two
          ago, and are now going fully online. Retail unit landlords and
          consumers alike are crying out for fresh blood and short-term turnover
          leases are the perfect way to attract some new faces.
        </p>
        <h3>How to make flexible leases work over the long-term</h3>
        <p>
          Some landlords are cautious about offering these more flexibly
          structured leases. Their concerns aren’t baseless and it’s true that
          turnover leases offer less predictable income for landlords. For
          turnover leases to become a more accepted lease structure in the UK,
          tenants need to be honest and realistic about their expected
          performance.
        </p>
        <p>
          In the past, turnover leases have only been popular in depressed
          markets, which immediately makes landlords more wary about them as a
          viable option. However, now is the perfect time for savvy landlords to
          reap the benefits of their open minded approach to their lease
          agreements. Those that offered turnover leases to their tenants during
          the pandemic could see their income soar over the coming months as
          shoppers and revellers flood our towns and high streets laden down
          with heavy pockets, ready to spend.
        </p>
        <p>
          In May 2021, the first month of full trading following the third UK
          lockdown,{" "}
          <a
            target="_blank"
            href="https://brc.org.uk/insight/content/retail-sales/retail-sales-monitor/reports/202105_uk_rsm/"
          >
            British Retail Consortium
          </a>{" "}
          members reported a 10% rise in sales for the month, compared with May
          two years before. The same rush to spend is taking place among{" "}
          <a
            target="_blank"
            href="https://www.independent.co.uk/news/uk/home-news/covid-restaurant-pubs-sales-up-b1850895.html"
          >
            British diners
          </a>
          , with restaurants and bars reporting an almost 25% increase in sales
          on the first Monday of full trading after lockdown compared with the
          same Monday before the pandemic.
        </p>
        <p>
          A recent{" "}
          <a
            target="_blank"
            href="https://www2.deloitte.com/uk/en/pages/consumer-business/articles/what-next-for-the-high-street.html"
          >
            report from Deloitte
          </a>
          , which asked ‘What next for the High Street’ predicts a vibrant
          future for the high street, adding that flexible, cheaper leases,
          which encourage new faces and reinvention, will be part of recovery.
          The report states: ‘The perceived weaknesses of the high street model,
          its fragmented ownership, lack of centralised coordination, low rents
          and high vacancy rates become strengths as they lower the barriers to
          entry for new concepts and operators.
        </p>
        <p>
          ‘They also enable experimentation and the fast failures that will
          ultimately lead to a more flourishing and diverse environment on our
          local high streets.’
        </p>
        <p>
          In our view, landlords that understand the changing face of our high
          streets and embrace a new, flexible approach to commercial property,
          will be able to build a more sustainable model for themselves and
          their investors.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsFifteen;
