import { Header, HelpContainer, Lower } from "./styles";

const LandingHelp = () => {
  return (
    <HelpContainer>
      <Header>WE'RE HERE TO HELP YOUR BUSINESS</Header>
      <Lower>
        No matter the size of your business, or what industry you work within,
        our compassionate, expert team is on-hand to build the ideal solution
        for you. Please see here just a number of the UK-based businesses that
        have enlisted the help of Cedar Dean.
      </Lower>
    </HelpContainer>
  );
};

export default LandingHelp;
