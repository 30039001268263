import styled, { keyframes, css } from "styled-components";

interface props {
  page: boolean;
  scroll: boolean;
}

const slideIn = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

const slideDown = keyframes`
from{
  transform: translateY(-100%);

  opacity: 0;

}


to{
  transform: translateY(0%);
opacity: 1;




}
`;
const logoAni = keyframes`
0% {
  left: 50%;
  top: 45vh;
  opacity: 0;
  transform: translate(-50%, -50%) scale(2.5);




}



60%{
  left: 50%;
  top: 45vh;
  opacity: 1;
  transform: translate(-50%, -50%) scale(2.5);




}

100% {
  left: 64px;
  top: 18px;
  opacity: 1;
  transform: translate(0%, 0%) scale(1);




}
`;

const fadeInAndEnlarge = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) ;
      left: 50%;
  top: 45vh;
  width:455px;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%)  ;
        left: 50%;
  top: 45vh;
  width:455px;

  }
`;

const shrinkAndReposition = keyframes`
  0% {
    left: 50%;
    top: 45vh;
    transform: translate(-50%, -50%)  ;
    width: 455px;
  }
  100% {
    left: 64px;
    top: 18px;
    transform: translate(0%, 0%) scale(1);
    width: 154px;
  }
`;

const logoAniMobile = keyframes`
0% {
  left: 50%;

  top: 45vh;
  opacity: 0;
  transform: translate(-50%, -50%) ;
  width:289px;


}

40%{
  left: 50%;

  top: 45vh;
  opacity: 1;
  transform: translate(-50%, -50%) ;
  width:289px;


}

100% {
  left: 16px;
  top: 45px;
  opacity: 1;
  transform: translate(0%, 0%) ;
  width:123px;

}
`;

interface headerProps {
  page: boolean;
  loaded: boolean;
  about: any;
}

export const HeaderContainer = styled.div<headerProps>`
  opacity: 0;

  ${({ page, about }) =>
    page && !about
      ? css`
          animation: ${slideDown} 0.5s 1.3s forwards;
        `
      : css`
          animation: ${slideDown} 0.5s 0s forwards;
        `};

  position: sticky;
  top: 0;
  width: 100%;
  height: 83px;

  display: flex;
  justify-content: flex-end;
  padding: 0px 64px;
  border-bottom: 1px solid white;
  z-index: 888;
  transition: 0.1s linear;
  align-items: center;

  @media (max-width: 800px) {
    padding: 0px 24px 16px 16px;
    height: 96px;
    align-items: flex-end;

    div {
      p {
      }
    }
  }

  img:first-child {
    &:hover {
      cursor: pointer;
    }
  }

  :hover {
    div {
      p {
      }
    }
    background: #f8f8f8;
  }
`;

export const RightSide = styled.div<props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 100%;
  align-items: center;

  @media (max-width: 800px) {
    display: none;
  }

  * {
    &:hover {
      cursor: pointer;
    }
  }

  p {
    color: ${({ scroll, page }) =>
      (!page && "#657A85") || (!scroll && "#F8F8F8") || (scroll && "#657A85")};
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    :hover {
      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #f52218;
        transform-origin: left;
        transform: scaleX(0);
        animation: ${slideIn} 0.3s forwards;
      }
    }
  }

  div {
    display: flex;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 16px;

    :hover {
      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #f52218;
        transform-origin: left;
        transform: scaleX(0);
        animation: ${slideIn} 0.3s forwards;
      }
    }

    img {
      margin-left: -10px;
    }
  }

  button {
    width: 110px;
    height: 45px;
    background: #f52218;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #f8f8f8;
    border: none;
    margin-left: 16px;
    transition: 0.1s linear;
    &:hover {
      background: #dd1f15;
    }
  }
`;

export const Burger = styled.img`
  @media (min-width: 800px) {
    display: none;
  }
`;

interface burgerProps {
  open: boolean;
}

export const BurgerPage = styled.div<burgerProps>`
  @media (min-width: 800px) {
    display: none;
  }
  padding: 48px 16px 0 16px;
  transition: 0.3s linear;
  transform: ${({ open }) => open && "translateX(-100%)"};

  left: 100%;
  top: 0px;
  position: fixed;
  background: #ebf0f2;
  min-width: 100vw;
  min-height: 100vh;

  flex-direction: column;
  align-items: flex-start;
  z-index: 999;

  img:first-child {
    margin-bottom: 15px;
    @media (min-height: 820px) {
      margin-bottom: 25px;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #133244;
    margin-top: 10px;
    margin-bottom: 15px;
    cursor: pointer;

    @media (min-height: 730px) {
      margin: 15px 0 20px;
    }

    @media (min-height: 820px) {
      margin: 20px 0 25px;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #657a85;
    cursor: pointer;
    @media (min-height: 730px) {
      margin-top: 3px;
    }
    @media (min-height: 820px) {
      margin-top: 7px;
    }
  }
`;

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const X = styled.img`
  position: absolute;
  top: 56px;
  right: 24px;
`;

export const IconsContainer = styled.div`
  margin-top: 20px;
  display: flex;

  img {
    margin: 0 !important;
    margin-right: 10px !important;
  }
`;

interface loadProp {
  loaded: boolean;
  sticky: boolean;
  page: boolean;
}

// const logoOpacity = keyframes`
// 0% {
//   opacity: 0;
// }

// 60% {
//   opacity: 1;
// }

// 100% {
//   opacity: 1;
// }
// `;
export const Logo = styled.img<loadProp>`
  z-index: 999;
  position: absolute;
  top: 18px;
  left: 64px;
  transform: translateZ(0);
  ${({ sticky }) =>
    sticky &&
    css`
      position: absolute !important;
      top: 18px;
      left: 64px;
    `}

  @media (min-width: 800px) {
    ${(props) =>
      !props.page
        ? css`
            animation: ${slideDown} 0.6s 0s forwards;
            opacity: 0%;
          `
        : props.loaded
        ? css`
            animation: none;
            opacity: 1;
            // ... any other final state properties
          `
        : css`
            animation: ${fadeInAndEnlarge} 1s 0s forwards,
              ${shrinkAndReposition} 1s 1.2s forwards;
          `}
  }

  @media (max-width: 800px) {
    ${(props) =>
      !props.page
        ? css`
            animation: ${slideDown} 0.6s 0s forwards;
            opacity: 0%;
          `
        : props.loaded
        ? css`
            animation: none;
            opacity: 1;
            // ... any other final state properties
          `
        : css`
            animation: ${logoAniMobile} 2.3s 0s forwards;
          `}
    top: 45px;
    left: 16px;
    width: 123px;

    ${({ sticky }) =>
      sticky &&
      css`
        position: absolute !important;
        top: 37px !important;
        left: 16px !important;
      `}
  }
`;

export const Test = styled.div`
  max-height: 100vh;
  height: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const ButtonCont = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  div {
    background: #f52218;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 18px;
    }
  }
`;

export const RedBtn = styled.button`
  background: #f52218;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #f8f8f8;
  width: 180px;
  border: none;
  transition: 0.05s linear;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    width: 220px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 98%;
    height: 100%;
    background: #a4150f;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.1s ease-in-out;
    z-index: 1;
  }

  &:hover:after {
    transform: scaleX(1);
  }

  span {
    position: relative;
    z-index: 2;
  }
`;
