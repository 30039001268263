import styled from "styled-components";

export const FooterContainer = styled.div`
  background: #133244;
  padding: 49px 64px 35px;
  position: relative;
  @media (max-width: 600px) {
    padding: 49px 16px 35px;
  }
`;

export const Header = styled.h2`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.06em;
  color: #f8f8f8;
  margin-bottom: 16px;
  @media (max-width: 600px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const SubText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #f8f8f8;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  @media (max-width: 650px) {
    top: auto;
    right: auto;
    bottom: -96px;
    left: 0px;
  }
`;

export const InputDiv = styled.div`
  display: flex;

  input {
    width: 421px;
    height: 45px;
    background: #f8f8f8;
    border: 1px solid #657a85;
    padding-left: 16px;
    font-size: 14px;
    border-right-width: 0px;

    :focus {
      outline: none;
    }
  }

  div {
    background: #b5c6ce;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
    border: 1px solid #657a85;
    border-left-width: 0px;

    justify-content: center;
    height: 45px;

    :hover {
      cursor: pointer;
    }

    img {
      margin: 18px;
    }
  }
`;

export const TopHalfContainer = styled.div`
  padding-bottom: 48px;
  border-bottom: 0.6px solid #b5c6ce;
  margin-bottom: 48px;
  position: relative;
  @media (max-width: 650px) {
    margin-bottom: 152px;
  }
`;

export const BottomHalfContainer = styled.div`
  display: flex;
  padding-bottom: 48px;
  border-bottom: 0.6px solid #b5c6ce;
  margin-bottom: 48px;
  flex-wrap: wrap;
  gap: 80px;

  @media (max-width: 970px) {
    gap: 40px;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;

  @media (max-width: 875px) {
    width: fit-content;
  }

  @media (max-width: 768px) {
    width: 160px;
  }

  div {
    display: flex;
  }

  .image-container {
    position: relative;
    width: 28px; // change these values according to your image size
    height: 28px; // change these values according to your image size
    margin-right: 15px;

    @media (max-width: 800px) {
      margin-bottom: 0px;
      margin-right: 15px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.2s;
      cursor: pointer;
    }

    img:first-child {
      z-index: 1;
    }

    img:nth-child(2) {
      z-index: 0;
    }

    &:hover img:first-child {
      opacity: 0;
    }
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #f8f8f8;
    margin-bottom: 15px;
  }
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #b5c6ce;
    margin-bottom: 8px;
    text-decoration: none;

    :hover {
      cursor: pointer;
    }
  }
`;

export const Smaller = styled.p`
  font-size: 14px !important;
  margin-bottom: 8px !important;
`;

export const UnderneathCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 925px) {
    p {
      margin: 0 20px 20px;
    }
  }

  @media (max-width: 872px) {
    p {
      margin: 0 0px 20px 0;
    }
  }

  @media (max-width: 544px) {
    P {
      margin: 0 0 20px;
    }
  }

  * {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #b5c6ce;
    @media (max-width: 925px) {
      margin-bottom: 20px;
    }
  }
  div {
    @media (max-width: 872px) {
      margin-right: 20px;
    }
    a {
      :hover {
        cursor: pointer;
      }
    }
    a:first-child {
      margin-right: 32px;
    }
  }
  img {
    :hover {
      cursor: pointer;
    }
  }
`;

export const Address = styled.p`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #b5c6ce !important;
  margin-bottom: 8px !important;
  :hover {
    cursor: default !important;
  }
`;
