import img from "../../../assets";
import { FooterLinksContainer } from "../styles";

const FooterSocials = () => {
  const onClick = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <FooterLinksContainer>
      <p>Follow Us</p>
      <div>
        <div
          className="image-container"
          onClick={() =>
            onClick("https://www.linkedin.com/company/cedar-dean/")
          }
        >
          <img src={img.linkedin} alt="LinkedIn" />
          <img
            src={img.linkedinRed}
            alt="LinkedIn Red"
            style={{ opacity: 1 }}
          />
        </div>
        <div
          className="image-container"
          onClick={() => onClick("https://www.facebook.com/CedarDeanUK/")}
        >
          <img src={img.facebook} alt="Facebook" />
          <img
            src={img.facebookRed}
            alt="Facebook Red"
            style={{ opacity: 1 }}
          />
        </div>
        <div
          className="image-container"
          onClick={() => onClick("https://twitter.com/cedardean?lang=en-GB")}
        >
          <img src={img.twitter} alt="Twitter" />
          <img src={img.twitterRed} alt="Twitter Red" style={{ opacity: 1 }} />
        </div>
        <div
          className="image-container"
          onClick={() => onClick("https://www.instagram.com/cedar_dean/")}
        >
          <img src={img.instagram} alt="Instagram" />
          <img
            src={img.instagramRed}
            alt="Instagram Red"
            style={{ opacity: 1 }}
          />
        </div>
      </div>
    </FooterLinksContainer>
  );
};

export default FooterSocials;
