import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import { Left, Right, Text } from "./styles";

import { useInView } from "react-intersection-observer";

const Four = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "LEASE SURRENDERS",
    img: servImg.sFourBig,
    imgM: servImg.fourM,

    quote: {
      img: servImg.tdr,
      text: `“Cedar Dean have been an invaluable support to TDR and Stonegate over many years. They think differently and get results. We highly recommend them.”
      Brian Magnus, Partner - TDR Capital`,
      from: (
        <h4>
          Brian Magnus, Partner - <b>TDR Capital</b>
        </h4>
      ),
    },

    body: [
      " It’s often the case that businesses find themselves tied into leasehold agreements that they no longer require and would like to be free from. These can sometimes prove to be very expensive and unsuccessful when negotiated improperly: this is where Cedar Dean is here to help you.",
      "Utilising our creative methods, we can expertly negotiate the best possible terms of a surrender settlement that benefits you. We’ve helped everyone from individual tenants to multinational corporations better navigate the exit terms of their commercial leases, with our unique and innovative strategies ensuring all parties can leave the negotiating table satisfied with the outcome.",
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>{data.header}</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>

        <BannerInner>
          <p>{data.quote.text}</p>
          <img src={data.quote.img} />
          {data.quote.from}
        </BannerInner>
      </Banner>
      <Text inView={inView}>
        <Left>
          <p>{data.body[0]}</p>
          <p ref={ref}>{data.body[1]}</p>
        </Left>
        <Right>
          <p>{data.body[2]}</p>

          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
    </Container>
  );
};

export default Four;
