import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsNine = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(31.5));
  }, []);

  return (
    <NewsCont>
      <Header>
        CAN ARBITRATION HELP COMMERCIAL TENANTS AVOID BUSINESS LIQUIDATION AND
        INSOLVENCY?
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>March 23, 2022 - 1:52pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigNine : thoughtImg.sNine} />
      </ImageContainer>
      <BodyCont>
        <h3>
          Many of our clients will be concerned about the end of the moratorium
          and what this means for them.
        </h3>
        <p>
          On 25 March, the extended moratorium on the eviction of commercial
          tenants impacted by Covid-19 measures comes to an end. Many of our
          clients will be concerned about the{" "}
          <a href="/news/14">end of the moratorium</a> and what this means for
          them. If you still have unresolved disputes with your landlords
          hanging over you like a dark cloud, you’ll be understandably
          concerned.
        </p>

        <h3>Business owners face liquidation as arbitration looms</h3>
        <p>
          As businesses all over the UK were forced to shut up shop for months
          on end, many businesses found themselves unable to pay rent to their
          landlords. Some landlords have been understanding and have offered
          generous rent relief, while others have come to agreements with
          tenants over rent payments and lease terms. Others, however, have been
          far less flexible leading to long-term disputes, many of which have
          not yet been resolved.
        </p>
        <p>
          As a result of this uncertainty, hundreds, if not thousands, of UK
          businesses will be concerned about the prospect of business
          liquidation if they are forced to pay large rent bills that they
          cannot afford. Unfortunately, the outcomes of the arbitration process
          are binding and cannot be appealed, so this concern is extremely
          understandable. We’re here, alongside the{" "}
          <a target="_blank" href="https://thecta.co.uk/">
            Commercial Tenants Association
          </a>{" "}
          (CTA) to support and inform business owners through these worrying
          times.
        </p>

        <h3>When does the arbitration process start?</h3>
        <p>
          Any commercial tenants who have been unable to resolve disputes with
          their landlords over arrears built up over the past two years may need
          to be prepared for the arbitration process that can kick in
          post-moratorium.
        </p>

        <h5>1. Pre-application stage</h5>
        <p>
          <b>Stage 1: Letter of notification</b>
        </p>
        <p>
          Either the landlord or the tenant issues a notice to the other party
          that they are pursuing binding arbitration. They must also submit a
          proposal for the settlement of the rent arrears in question in line
          with the guidance set out in the <a href="https://www.gov.uk/government/publications/commercial-rents-code-of-practice-november-2021" target="_blank">Code of Conduct</a> with regards to
          factors like viability and solvency. Stage 2. The other party can
          accept the proposal or submit their own counterproposal. The party who
          initiated the arbitration process can then respond and can submit a
          further proposal if they wish.
        </p>
        <h5>2. Application stage:</h5>
        <p>
          <b>Stage 1.</b> The initiator must then submit an application for
          arbitration and pay the fee. The application must include the
          proposals and notifications issued in the pre-application stages.
        </p>
        <p>
          <b>Stage 2.</b> Both parties will then have a set period of time in
          which to submit further proposals as to what they consider to be the
          right outcome of the arbitration process.
        </p>
        <p>
          <b>Stage 3.</b> An arbitration hearing should then be set within two
          weeks and the arbitrators must base their decision on the written
          evidence provided by both parties, including any evidence they have
          included.
        </p>

        <h5>3. Decision</h5>
        <p>
          The arbitrators will then make their decision as to any award,
          according to the official arbitration guidelines: “the award will
          adopt whichever proposal is consistent with the principles, or if both
          are consistent the proposal which is most consistent. Otherwise, the
          arbitrator will make the award they consider is appropriate”.
        </p>
        <h3>Our view</h3>
        <p>
          In our <a href="/news/14">blog</a> about the publication of the Code
          of Practice back in November 2021, we talked about how the commercial
          rent arrears crisis could drive businesses into liquidation. The
          latest statistics from the Insolvency Service shows that, in the third
          quarter of 2021, the number of company insolvencies was 17% higher
          than the previous quarter and 43% higher than the same quarter in
          2020.
        </p>
        <h3>Creditors’ voluntary liquidations on the rise</h3>
        <p>
          The increase was driven by a rise in creditors’ voluntarily
          liquidations (CVLs) to their highest quarterly level for well over a
          decade. We consider this rise in CVLs to indicate that many smaller
          businesses are still struggling to avoid business liquidation despite
          the government’s Coronavirus support, which includes the moratorium on
          commercial evictions.
        </p>
        <p>
          As the official Code of Practice still includes ambiguities that will
          be open to interpretation in different ways by different arbitrators,
          some commercial tenants will no doubt receive a negative outcome from
          the binding arbitration process and business liquidation figures will
          continue to rise.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsNine;
