import useWindowSize, { Size } from "../../../utils/getWindowSize";
import RedButton from "../../reusable/redButton";
import valuesData from "./data";
import {
  Block,
  ButtonCont,
  Header,
  LeftContainer,
  RightContainer,
  Smaller,
  ValuesContainer,
} from "./styles";

import { useInView } from "react-intersection-observer";

const LandingValues = () => {
  const size: Size = useWindowSize();

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <ValuesContainer>
      <LeftContainer>
        <Header>OUR VALUES</Header>
        <Smaller>
          We pride ourselves on being professional, committed advocates for all
          businesses up and down the country.
        </Smaller>
        <Smaller>
          Every action we take has the best interests and best possible outcome
          for our clients in mind, meaning you can rest assured knowing that
          Cedar Dean is doing all it can to secure you the best terms concerning
          your commercial lease.
        </Smaller>
        {size.width! > 800 ? (
          <ButtonCont>
            <RedButton />
          </ButtonCont>
        ) : null}
      </LeftContainer>
      <RightContainer ref={ref}>
        {valuesData.map((val: any, i: any) => {
          return (
            <Block key={val.header} isVisible={inView} delay={`0.${i}s`}>
              <img src={val.img} />
              <div>
                <h1>{val.header}</h1>
                <p>{val.text}</p>
              </div>
            </Block>
          );
        })}
      </RightContainer>
      {size.width! < 800 ? (
        <ButtonCont>
          <RedButton />
        </ButtonCont>
      ) : null}
    </ValuesContainer>
  );
};

export default LandingValues;
