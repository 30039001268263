import img from "../../../../assets";
import teamData from "../data";
import { Background, Card, Div, Links, LinksLeft, RightCont } from "./styles";
import { useInView } from "react-intersection-observer";

const animations = [
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
  "two",
  "three",
  "one",
];

const TeamRight = () => {
  return (
    <RightCont>
      {teamData.map((x: any, i: number) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { ref, inView } = useInView({
          triggerOnce: true, // The animation will only trigger once
        });

        return (
          <Div inView={inView} ani={animations[i]} key={i}>
            <Card ref={ref} img={x.img} key={i}>
              <h1>{x.name}</h1>
              <h2>{x.pos}</h2>
              <div>
                <p>{x.text}</p>
              </div>
              <Background></Background>
            </Card>
            <Links>
              <LinksLeft>
                {x.linkedin.length > 0 && (
                  <a href={x.linkedin} target="_blank">
                    <div className="image-container">
                      <img src={img.linkedinRed} />
                      <img src={img.linkedinGrey} style={{ opacity: 1 }} />
                    </div>
                  </a>
                )}
                {x.twitter.length > 0 && (
                  <a href={x.twitter} target="_blanke">
                    <div className="image-container">
                      <img src={img.twitterRed} />
                      <img src={img.twitterGrey} style={{ opacity: 1 }} />
                    </div>
                  </a>
                )}
                {x.email.length > 0 && (
                  <a href={`mailto:${x.email}`}>
                    <div className="image-container">
                      <img src={img.mailRed} />
                      <img src={img.mailGrey} style={{ opacity: 1 }} />
                    </div>
                  </a>
                )}
              </LinksLeft>

              <a href={`tel:${x.number}`}>{x.number}</a>
            </Links>
          </Div>
        );
      })}
    </RightCont>
  );
};

export default TeamRight;
