import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import { Left, Right, Text } from "./styles";

import { useInView } from "react-intersection-observer";

const Seven = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "RENT REDUCTIONS",
    img: servImg.sSevenBig,
    imgM: servImg.sevenM,

    quote: {
      img: null,
      text: `“Cedar Dean delivers! On a number of commercial negotiations the team advised on strategy, ensured access to the decision makers and got the deals accross the line.”`,
      from: (
        <h4>
          Alexander Salussolia, Managing Director -{" "}
          <b>Glendola Leisure Group</b>
        </h4>
      ),
    },

    body: [
      "Negotiating for a rent reduction can be a daunting experience for any tenant or commercial leaseholder. Identifying the right moment and opportunity to request it is crucial, which is why Cedar Dean can provide you with the strategic analysis and information necessary to best prepare yourself during these negotiations.",
      "One of our key aims here at Cedar Dean is to foster a more positive, mutually-beneficial relationship between tenants and their landlords, and we believe this process can help achieve that. Let us help you secure your rent reduction, and launch an enquiry with us today!",
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>{data.header}</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>

        <BannerInner>
          <p>{data.quote.text}</p>

          {data.quote.from}
        </BannerInner>
      </Banner>
      <Text inView={inView}>
        <Left>
          <p>{data.body[0]}</p>
          <p ref={ref}>{data.body[1]}</p>
        </Left>
        <Right>
          <p>{data.body[2]}</p>

          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
    </Container>
  );
};

export default Seven;
