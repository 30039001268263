import { Button, OtherButton, QuestionOneContainer } from "../styles";

const QuestionFour = ({ setData, data, setStep }: any) => {
  const onClick = (x: string) => {
    setData({ ...data, ltdCompany: x });
    setStep(4);
  };

  return (
    <QuestionOneContainer>
      <h3>IS YOUR LEASE HELD IN A LTD COMPANY?</h3>
      <div>
        <Button onClick={() => onClick("Yes")}>Yes</Button>
        <Button onClick={() => onClick("No")}>No</Button>

        <OtherButton onClick={() => onClick("Unsure")}>Unsure</OtherButton>
      </div>
    </QuestionOneContainer>
  );
};

export default QuestionFour;
