import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;

  @media(min-width:1500px){
  max-width: fit-content;
  margin: 0 auto;
}

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`;
