import img from "../../../assets";
import Header from "../../../layout/header";
import useWindowSize, { Size } from "../../../utils/getWindowSize";
import RedButton from "../../reusable/redButton";
import HeroIcons from "./icons";
import RightSide from "./rightSide";
import {
  ButtonContainer,
  HeroContainer,
  IconsCont,
  MainText,
  SmallerText,
} from "./styles";

const AboutHero = () => {
  const size: Size = useWindowSize();

  return (
    <HeroContainer>
      <MainText>BE PART OF THE REVOLUTION</MainText>
      <SmallerText>
        As the UK’s principal restructuring specialist, it’s our mission to
        implement greater change in the commercial property and renting market.
        We’re all about getting real results for you and our clients.
      </SmallerText>
      <IconsCont>
        <HeroIcons />
      </IconsCont>
    </HeroContainer>
  );
};

export default AboutHero;
