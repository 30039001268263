import img from "../../../../assets";
import { IconsContainer } from "./styles";

const ContactIcons = () => {
  const onClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <IconsContainer>
      <div
        className="image-container"
        onClick={() => onClick("https://www.linkedin.com/company/cedar-dean/")}
      >
        <img src={img.linkedin} alt="LinkedIn" />
        <img
          src={img.linkedinGrey}
          alt="LinkedIn Grey"
          style={{ opacity: 1 }}
        />
      </div>
      <div
        className="image-container"
        onClick={() => onClick("https://www.facebook.com/CedarDeanUK/")}
      >
        <img src={img.facebook} alt="Facebook" />
        <img
          src={img.facebookGrey}
          alt="Facebook Grey"
          style={{ opacity: 1 }}
        />
      </div>
      <div
        className="image-container"
        onClick={() => onClick("https://twitter.com/cedardean?lang=en-GB")}
      >
        <img src={img.twitter} alt="Twitter" />
        <img src={img.twitterGrey} alt="Twitter Grey" style={{ opacity: 1 }} />
      </div>
      <div
        className="image-container"
        onClick={() => onClick("https://www.instagram.com/cedar_dean/")}
      >
        <img src={img.instagram} alt="Instagram" />
        <img
          src={img.instagramGrey}
          alt="Instagram Grey"
          style={{ opacity: 1 }}
        />
      </div>
    </IconsContainer>
  );
};

export default ContactIcons;
