import thoughtImg from "../../../assets/thoughts";

const newsData = [
  {
    img: thoughtImg.new1,
    imgM: thoughtImg.new1M,
    header:
      "Cedar Dean has been recognised as one of Sunday Times best places to work",
    text: "We are thrilled to share the exciting news that Cedar Dean has been recognised as one of the Sunday Times’ Best Places to Work in",
    date: "May 25, 2023",
    link: "1",
  },
  {
    img: thoughtImg.new2,
    imgM: thoughtImg.new2M,
    header:
      "Oxford Street: Facing a period of decline amidst COVID-19 and changing retail landscape",
    text: "Oxford Street, known as one of the most iconic shopping destinations in the UK, is currently facing a period of decline. The COVID-19 pandemic has",
    date: "April 27, 2023",
    link: "2",
  },
  {
    img: thoughtImg.one,
    imgM: thoughtImg.oneM,

    header: "Is Your Business Hurting?",
    text: "Not sure which service your interested in yet? No worry, check out all of our services here!",
    date: "February 22, 2023",
    link: "3",
  },
  {
    img: thoughtImg.two,
    imgM: thoughtImg.twoM,

    header:
      "How To Reduce Your Rent As A Business Owner Facing The Cost-Of-Living Crisis",
    text: "Not sure which service your interested in yet? No worry, check out all of our services here!",
    date: "February 22, 2023",
    link: "4",
  },
  {
    img: thoughtImg.three,
    imgM: thoughtImg.threeM,

    header: "Could A Sustainable Lease Save Your Local Chippy?",
    text: "Not sure which service your interested in yet? No worry, check out all of our services here!",
    date: "February 22, 2023",
    link: "5",
  },
  {
    img: thoughtImg.four,
    imgM: thoughtImg.fourM,
    header:
      "Personal guarantees for commercial leases - why you should be saying ‘no’ when asked for a personal guarantee ",
    text: "The risks of personal guarantees for commercial leases. Why you should avoid signing for a personal guarantee.",
    date: "May 31, 2022",
    link: "6",
  },
  {
    img: thoughtImg.five,
    imgM: thoughtImg.fiveM,
    header: "Turnover is vanity, profit is sanity.",
    text: "Is business liquidation a threat despite high company turnover?",
    date: "May 4, 2022",
    link: "7",
  },
  {
    img: thoughtImg.six,
    imgM: thoughtImg.sixM,
    header:
      "Life After Covid Business Support Ends - Are serviced offices the right solutions for you?",
    text: "Heading back to the office? Do serviced offices have a future now that Covid business support has ended?",
    date: "April 28, 2022",
    link: "8",
  },
  {
    img: thoughtImg.seven,
    imgM: thoughtImg.sevenM,
    header:
      "Can Arbitration help commercial tenants avoid busines liquidation and insolvency?",
    text: "Many of our clients will be concerned about the end of the moratorium and what this means for them.",
    date: "March 23, 2022",
    link: "9",
  },
  {
    img: thoughtImg.eight,
    imgM: thoughtImg.eightM,
    header: "Why has the gym sector been left out in the cold?",
    text: "Over the 2021 festive period, hospitality and leisure businesses faced new challenges because of the Omicron variant. Chancellor Rishi Sunak responded to pressure from the",
    date: "March 7, 2022",
    link: "10",
  },
  {
    img: thoughtImg.nine,
    imgM: thoughtImg.nineM,
    header: "Cedar Dean's CEO appointed as chair of CTA's policy committee.",
    text: "The CTA is all about creating a more equitable market place for commercial tenants. Together, we’re committed to defending the rights of commercial tenants.",
    date: "February 14, 2022",
    link: "11",
  },
  {
    img: thoughtImg.ten,
    imgM: thoughtImg.tenM,
    header: "Why do banks have such a problem with retail?",
    text: "Banks feel that retail tenants are short-term and low quality. When it comes to reviewing portfolios with more than, say, 30% retail exposure, they are suddenly preoccupied.",
    date: "February 2, 2022",
    link: "12",
  },
  {
    img: thoughtImg.eleven,
    imgM: thoughtImg.elevenM,

    header: "Are green commercial leases always sustainable?",
    text: "After Cop26, we’ve been thinking about climate change in our industry. One of the most notable changes has been the introduction of the so-called green leases.",
    date: "November 30, 2021",
    link: "13",
  },
  {
    img: thoughtImg.twelve,
    imgM: thoughtImg.twelveM,
    header:
      "For struggling businesses who paid their rent, is the code of practice too little too late?",
    text: "With a third of UK small businesses highly indebted and insolvencies reaching their highest levels, the damage has already been done.",
    date: "November 18, 2021",
    link: "14",
  },
  {
    img: thoughtImg.ten,
    imgM: thoughtImg.tenM,
    header: "Could short-term turnover leases help drive post-covid recovery?",
    text: "Summer 2021 has brought the welcome site of shoppers and diners back to the UK high streets.",
    date: "July 28, 2021",
    link: "15",
  },
  {
    img: thoughtImg.new3,
    imgM: thoughtImg.new3M,
    header: "Our COVID19 rent survey",
    text: "September 2020 Covid 19 Rent Survey",
    date: "June 25, 2021",
    link: "16",
  },
  {
    img: thoughtImg.new4,
    imgM: thoughtImg.new4M,
    header: "Debt is a 4 letter word!",
    text: "After 12 years in Lease restructuring, it was clear to me that the most important word in the landlord and tenant relationship is affordability!",
    date: "June 22, 2021",
    link: "17",
  },
  {
    img: thoughtImg.ten,
    imgM: thoughtImg.tenM,
    header: "Sustainable Restructuring",
    text: "The solution to our high street crisis",
    date: "June 21, 2021",
    link: "18",
  },
  {
    img: thoughtImg.new5,
    imgM: thoughtImg.new5M,
    header: "Passive landlord-ing is over",
    text: "This year marks 20 years that I have been active in the UK the commercial property market, specialising predominantly in Leisure and Retail. During that time, the theme for London especially has been the same.",
    date: "May 25, 2021",
    link: "19",
  },
  {
    img: thoughtImg.new6,
    imgM: thoughtImg.new6M,
    header: "The solvent CVA",
    text: "Mediating between tenants and landlords in a reactionary time",
    date: "April 25, 2021",
    link: "20",
  },
  {
    img: thoughtImg.new7,
    imgM: thoughtImg.new7M,
    header: "We challenged a government rate fund ",
    text: "In response to the Government and DCLG’s consultation on the proposed £300m Business Rates relief fund, CDG have made representations.",
    date: "March 25, 2021",
    link: "21",
  },
  {
    img: thoughtImg.new8,
    imgM: thoughtImg.new8M,
    header: "Worried about your rent review?",
    text: "London 2017 is proving to be a very uncertain and unpredictable market in the light of the surprise election, Brexit, terrorism and other related factors which are having impacts.",
    date: "February 25, 2021",
    link: "22",
  },
];

export default newsData;
