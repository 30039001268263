import LandingContact from "../contact";
import Form from "../form";
import { FormContainer } from "./styles";

const LandingForm = () => {
  return (
    <FormContainer>
      <LandingContact />
      <Form />
    </FormContainer>
  );
};

export default LandingForm;
