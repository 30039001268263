import { ServicesPageCont, TestimonialCont } from "./styles";

import ServicesHero from "../../components/services/top";
import ServicesGrid from "../../components/services/grid";
import LandingTestimonials from "../../components/landing/testimonials";
import LandingForm from "../../components/landing/landingForm";
import NewsHero from "../../components/news/top";
import NewsGrid from "../../components/news/grid";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateLoaded } from "../../redux/reducers/loaded";

const NewsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(updateLoaded());
  }, []);
  return (
    <ServicesPageCont>
      <NewsHero />
      <NewsGrid />
    </ServicesPageCont>
  );
};

export default NewsPage;
