import styled from "styled-components";

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: row;
    position: initial;
    transform: none;
    margin-top: 46px;
    margin-left: 22px;
  }

  .image-container {
    position: relative;
    width: 28px; // change these values according to your image size
    height: 28px; // change these values according to your image size
    margin-right: 15px;

    @media (max-width: 800px) {
      margin-bottom: 0px;
      margin-right: 15px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.2s;
      cursor: pointer;
    }

    img:first-child {
      z-index: 1;
    }

    img:nth-child(2) {
      z-index: 0;
    }

    &:hover img:first-child {
      opacity: 0;
    }
  }
`;
