import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize, { Size } from "../../../../utils/getWindowSize";
import img from "../../../../assets";

import teamData from "../data";
import {
  Arrow,
  ArrowCont,
  Background,
  BarCont,
  LineCont,
  ScrollableDiv,
  ServicesContainer,
} from "./styles";
import { Card, Div, Links, LinksLeft } from "../right/styles";

const TeamRightMobile = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const navigate = useNavigate();

  const size: Size = useWindowSize();

  const num = size.width! < 650 ? 1.05 : 1;

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollableDiv = scrollableDivRef.current;
      if (scrollableDiv) {
        const totalScrollWidth =
          scrollableDiv.scrollWidth - scrollableDiv.clientWidth;
        const currentScrollPos = scrollableDiv.scrollLeft;

        setScrollWidth(totalScrollWidth);
        setScrollPos(currentScrollPos);
      }
    };

    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollLeft = () => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      const newPos = scrollableDiv.scrollLeft - scrollableDiv.clientWidth * num;
      scrollableDiv.scrollTo({
        left: newPos,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      const newPos = scrollableDiv.scrollLeft + scrollableDiv.clientWidth * num;
      scrollableDiv.scrollTo({
        left: newPos,
        behavior: "smooth",
      });
    }
  };

  return (
    <ServicesContainer>
      <ScrollableDiv ref={scrollableDivRef}>
        {teamData.map((x: any, i: number) => {
          return (
            <Div inView={true} ani={"one"} key={i}>
              <Card img={x.img} key={i}>
                <h1>{x.name}</h1>
                <h2>{x.pos}</h2>
                <div>
                  <p>{x.text}</p>
                </div>
                <Background></Background>
              </Card>
              <Links>
                <LinksLeft>
                  {x.linkedin.length > 0 && (
                    <a href={x.linkedin} target="_blank">
                      <div className="image-container">
                        <img src={img.linkedinRed} />
                        <img src={img.linkedinGrey} style={{ opacity: 1 }} />
                      </div>
                    </a>
                  )}
                  {x.twitter.length > 0 && (
                    <a href={x.twitter} target="_blanke">
                      <div className="image-container">
                        <img src={img.twitterRed} />
                        <img src={img.twitterGrey} style={{ opacity: 1 }} />
                      </div>
                    </a>
                  )}
                  {x.email.length > 0 && (
                    <a href={`mailto:${x.email}`}>
                      <div className="image-container">
                        <img src={img.mailRed} />
                        <img src={img.mailGrey} style={{ opacity: 1 }} />
                      </div>
                    </a>
                  )}
                </LinksLeft>
                <a href={`tel:${x.number}`}>{x.number}</a>
              </Links>
            </Div>
          );
        })}
      </ScrollableDiv>
      <BarCont>
        <LineCont>
          <div
            style={{
              width: `${(scrollPos / scrollWidth) * 100}%`,
            }}
          ></div>
        </LineCont>
        <ArrowCont>
          <Arrow
            inactive={scrollPos === 0}
            src={scrollPos === 0 ? img.right2 : img.right1}
            onClick={scrollLeft}
            alt="left arrow"
          />
          <Arrow
            inactive={scrollPos === scrollWidth}
            src={
              scrollPos !== scrollWidth || !scrollPos ? img.right1 : img.right2
            }
            onClick={scrollRight}
            alt="right arrow"
          />
        </ArrowCont>
      </BarCont>
    </ServicesContainer>
  );
};

export default TeamRightMobile;
