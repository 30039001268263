import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import {
  BodyCont,
  Div,
  Header,
  ImageContainer,
  NewsCont,
  SourcesCont,
} from "../styles";

const NewsEighteen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(73));
  }, []);

  return (
    <NewsCont>
      <Header>SUSTAINABLE RESTRUCTURING</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>June 21, 2021 - 2:58pm</p>
      </Div>
      <ImageContainer>
        <img
          src={width! > 450 ? thoughtImg.bigEighteen : thoughtImg.sEighteen}
        />
      </ImageContainer>
      <BodyCont>
        <h3>The solution to our high street crisis</h3>
        <p>
          It feels as if the crisis on our high streets and in our shopping
          centres is reaching new heights right now. The moratorium on evictions
          has been extended again, this time until 25 March 2022, which has, at
          least, given struggling commercial tenants a little breathing space.
          However, the government has delayed the relaxation of social
          distancing until much later in the summer, leaving many businesses
          closed and many more operating on significantly reduced capacity.
        </p>
        <p>
          It’s a perfect storm that could leave our high streets and cultural
          hubs desolate and UK commercial tenants and landlords in desperate
          need of a major rethink. This is where ‘sustainable restructuring’
          comes in – and we think it’s the solution.
        </p>
        <h3>Why do we need sustainable lease restructuring?</h3>
        <p>
          As things stand, commercial leases are often unaffordable for tenants.
          A small restaurant chain or retailer may accept the terms initially,
          but somewhere down the line things change and they can no longer pay
          the same rates as the large chain next door. The rent is unaffordable
        </p>
        <p>
          So, the landlord could try to get another tenant in to take over the
          location, but they’re still asking for the same rent per sq metre as
          the large chain next door. No one can afford those rates and the prime
          site, right in the middle of the high street, sits empty and boarded
          up. No one is making any money from it, and the local community has to
          live with yet another empty shell instead of a thriving business.
        </p>
        <p>
          An example is the LloydsPharmacy chain, which has had to close around
          100 of its branches over recent months due to “cost pressures and
          declining footfalls”. Chris Keen, the business’s CFO wrote a piece in
          the{" "}
          <a
            href="https://www.retailgazette.co.uk/blog/2020/06/comment-its-time-for-a-conversation-about-high-street-rents/"
            target="_blank"
          >
            Retail Gazette
          </a>{" "}
          in June 2020 explaining the situation as he saw it: “Some of these
          large retailers are, frankly, facing imminent death from the mortal
          wounds inflicted by the pandemic. Landlords – confronting their own
          financial hardship – could be rightly concerned about the likelihood
          of ever receiving their income.”
        </p>
        <p>
          He added: “landlords need to understand that [average retailers]
          forbearance will help secure their income long term. Their interest –
          just like ours – is in a sustainable business.” We couldn’t have put
          it better ourselves!
        </p>
        <p>
          This is the crux of the problem we’re dealing with and the effects of
          the Covid-19 pandemic means this is happening up and down the country.
          Here at Cedar Dean, we’re all about giving tenants back some power.
        </p>
        <p>
          A staggering 68% of the commercial tenants we spoke to in our recent
          Covid-19 survey said they have been forced to consider lease
          restructuring or insolvency. We would argue that the former of these
          is the best option for tenants AND landlords. We also believe we can
          help tenants and landlords with developing sustainable restructuring
          that works for both parties over the long term.
        </p>
        <h3>What is sustainable restructuring?</h3>
        <p>
          There are many ways in which a lease can be restructured to become
          more sustainable. Here are some of the common lease restructuring
          techniques:
        </p>
        <p>
          • Rent – free incentives
          <br />
          • Capital contributions
          <br />
          • Rent reductions
          <br />
          • Lease extensions
          <br />
          • Service charge negotiations/capping
          <br />
          • Introducing break options
          <br />
          • Negotiating new renewable lease terms
          <br />
        </p>
        <p>
          The Mayor of London’s recent{" "}
          <a
            href="https://www.london.gov.uk/sites/default/files/ggbd_high_streets_adaptive_strategies_web_compressed_0.pdf"
            target="_blank"
          >
            High Streets and Town Centres Adaptive Strategies
          </a>{" "}
          report looked at these issues in depth. It suggested that sustainable
          restructuring can work to landlords’ benefit, just as much as tenants.
          It explained:
        </p>
        <p>
          ‘Landlords can provide long-term, wide ranging business support
          services to tenants for mutual benefit: successful businesses will
          make more resilient tenants, more able to take on longer leases.’
        </p>
        <p>
          We absolutely agree and have been working with tenants to confidently
          and effectively renegotiate their leases mid-term, to really make a
          lasting impact on their business sustainability. Landlords are
          increasingly open to negotiation as they face the prospect of their
          properties sitting empty for extended periods of time. For tenants,
          it’s a great time to re-examine your lease and see where changes can
          be made that will make it fairer and more affordable.
        </p>
        <h3>The takeaway</h3>
        <p>
          These are trying times for bricks and mortar businesses trying to
          survive in our towns and cities. As we emerge from the worst of the
          pandemic and consumers start to socialise, shop and spend again, it’s
          vital that commercial property leases reflect the times we are living
          in. Landlords need to accept that lease restructuring generates
          sustainability and that this, in turn, creates community value and
          prosperous high streets.
        </p>
        <h3>Sources</h3>
        <SourcesCont>
          <a
            href="https://moneyhighstreet.com/how-lease-restructuring-works/"
            target="_blank"
          >
            Source 1
          </a>
          <a
            href="https://www.pharmacymagazine.co.uk/lloyds-pleads-with-landlords-for-25-rent-reduction-"
            target="_blank"
          >
            Source 2
          </a>
          <a
            href="https://www.london.gov.uk/sites/default/files/ggbd_high_streets_adaptive_strategies_web_compressed_0.pdf"
            target="_blank"
          >
            Source 3
          </a>
          <a
            href="https://www.gov.uk/government/news/eviction-protection-extended-for-businesses-most-in-need"
            target="_blank"
          >
            Source 4
          </a>
          <a
            href="https://www.retailgazette.co.uk/blog/2020/06/comment-its-time-for-a-conversation-about-high-street-rents/"
            target="_blank"
          >
            Source 5
          </a>
        </SourcesCont>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsEighteen;
