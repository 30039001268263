import compImg from "../../../assets/companies";

export const imgsArr = [
  {
    id: Math.random(),
    logo: compImg.coop,
  },
  {
    id: Math.random(),
    logo: compImg.kwik,
  },
  {
    id: Math.random(),
    logo: compImg.care,
  },
  {
    id: Math.random(),
    logo: compImg.revital,
  },
  {
    id: Math.random(),
    logo: compImg.energie,
  },
  {
    id: Math.random(),
    logo: compImg.pirate,
  },
  {
    id: Math.random(),
    logo: compImg.anytime,
  },
  {
    id: Math.random(),
    logo: compImg.boom,
  },
  {
    id: Math.random(),
    logo: compImg.rawr,
  },
  {
    id: Math.random(),
    logo: compImg.poliform,
  },
  {
    id: Math.random(),
    logo: compImg.kobox,
  },
  {
    id: Math.random(),
    logo: compImg.triyoga,
  },
  {
    id: Math.random(),
    logo: compImg.three,
  },
];

export default imgsArr;
