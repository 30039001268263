import coop from "./coop.svg";
import kwik from "./kwik.svg";
import care from "./care.svg";
import revital from "./revital.svg";
import energie from "./energie.svg";
import pirate from "./pirate.svg";
import anytime from "./anytime.svg";
import boom from "./boom.svg";
import rawr from "./rawr.svg";
import poliform from "./poliform.svg";
import kobox from "./kobox.svg";
import triyoga from "./triyoga.svg";
import three from "./three.svg";

const compImg = {
  coop,
  kwik,
  care,
  revital,
  energie,
  pirate,
  anytime,
  boom,
  rawr,
  poliform,
  kobox,
  triyoga,
  three,
};

export default compImg;
