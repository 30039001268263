import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsTwelve = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(45));
  }, []);

  return (
    <NewsCont>
      <Header>WHY DO BANKS HAVE SUCH A PROBLEM WITH RETAIL?</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>February 2, 2022 - 4:01pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigTwelve : thoughtImg.sTwelve} />
      </ImageContainer>
      <BodyCont>
        <h3>
          Banks feel that retail tenants are short-term and low quality. When it
          comes to reviewing portfolios with more than, say, 30% retail
          exposure, they are suddenly preoccupied.
        </h3>
        <p>
          A report last year in Property Week suggested that banks have stopped
          financing property portfolios that are heavily exposed to retail.
          Banks will be quick to suggest that this move is a risk-aversion
          strategy at a time when they consider retail properties to have poorer
          quality tenants. We would disagree and argue that the focus is far too
          much on the sector as a whole and that banks may be throwing the baby
          out with the bath water. Residential tenants are in some ways less
          stable than commercial, but they are still seen as the most favorable
          asset class worldwide. Surely the focus should be on prime retail.
        </p>
        <h3>Why are banks avoiding retail?</h3>
        <p>
          According to the Property Week article, a source from a major private
          equity firm said that half the UK’s banks won’t consider lending on
          portfolios that are substantially geared towards retail. The report
          says that the reluctance to lend on retail had begun before the
          pandemic, but has intensified since Covid.
        </p>
        <p>
          Although the banking industry may have you believe that the change in
          tack is simply linked to the evolution of how people shop i.e. with
          more emphasis on online shopping and fewer people shopping on the high
          street. However, what it really comes down to is an obsession with
          quality of tenant and length of lease, when it comes to retail
          property.
        </p>
        <p>
          Banks, erroneously, feel that retail tenants are short-term and low
          quality. When it comes to reviewing portfolios with more than, say,
          30% retail exposure, they are suddenly preoccupied with the fact that
          the leases may be shorter term, or that tenants might be independent
          retailers or smaller, less-established businesses.
        </p>
        <h3>But what about residential?</h3>
        <p>
          We wonder why banks are so very eager to lend on residential property
          portfolios, with very little regard for quality of tenant and length
          of lease. In a residential portfolio, these factors are far more
          unstable. How long is the average residential lease? A year? Six
          months? Why does this not bother the banks, when a commercial tenancy
          agreement with a small retailer is viewed so negatively, regardless of
          whether the business is sound or not?
        </p>
        <h3>It’s time to look at the businesses, not the leases</h3>
        <p>
          We understand that in certain circumstances, such as in areas where
          independent retail is dead in the water and high streets are hanging
          on for dear life, caution around retail-heavy portfolios makes sense.
          However, the same level of caution when financing portfolios of
          properties in bustling, thriving town centres and city high streets is
          baffling.
        </p>
        <p>
          At Cedar Dean, we understand what makes a successful, sustainable and,
          ultimately lucrative, tenancy for landlords, as well as tenants. We
          also know that independent retailers are often incredibly successful,
          profitable businesses with rapid growth potential.
        </p>
        <p>
          We are appealing to banks to support our high streets and our
          independent retailers by throwing their financial heft behind
          commercial real estate portfolios with retail exposure. Banks need to
          start giving their support to retail portfolios that include
          sustainable leases for commercial tenants. This means both up and down
          rent reviews, flexible terms and an emphasis on affordability.
        </p>
        <p>
          We are appealing to banks to support our high streets and our
          independent retailers by throwing their financial heft behind
          commercial real estate portfolios with retail exposure. Banks need to
          start giving their support to retail portfolios that include
          sustainable leases for commercial tenants. This means both up and down
          rent reviews, flexible terms and an emphasis on affordability.
        </p>
        <p>
          In conclusion, there are incredible headwinds for retail property this
          year, with the increase in interest rates, banks not wanting to
          refinance and extra complications in selling retail with residential
          upper parts due to the “right to buy” laws. We fear that commercial
          retail will continue to drop in value unless banks can start to really
          decipher between good quality retail and those assets which are beyond
          saving.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsTwelve;
