import sOne from "./sOne.webp";
import sTwo from "./sTwo.png";
import sThree from "./sThree.png";
import sFour from "./sFour.png";
import sFive from "./sFive.png";
import sSix from "./sSix.webp";
import sSeven from "./sSeven.png";
import sOneBig from "./sOneBig.png";
import sTwoBig from "./sTwoBig.png";
import sThreeBig from "./sThreeBig.png";
import sFourBig from "./sFourBig.png";
import sFiveBig from "./sFiveBig.png";
import sSixBig from "./sSixBig.png";
import sSevenBig from "./sSevenBig.png";
import sEight from "./sEight.png";
import sOneM from "./sOneM.webp";
import sTwoM from "./sTwoM.png";
import sThreeM from "./sThreeM.webp";
import sFourM from "./sFourM.png";
import sFiveM from "./sFiveM.png";
import sSixM from "./sSixM.webp";
import sSevenM from "./sSevenM.webp";
import sEightM from "./sEightM.png";

import oneM from "./oneM.png";
import twoM from "./twoM.png";
import threeM from "./threeM.png";
import fourM from "./fourM.png";
import fiveM from "./fiveM.png";
import sixM from "./sixM.png";
import sevenM from "./sevenM.png";

import geox from "./geox.svg";
import revolution from "./revolution.svg";
import tdr from "./tdr.svg";

const servImg = {
  sOne,
  oneM,
  twoM,
  threeM,
  fourM,
  fiveM,
  sixM,
  sevenM,
  sOneBig,
  sTwoBig,
  sThreeBig,
  sTwo,
  sFourBig,
  sFiveBig,
  sSixBig,
  sSevenBig,
  sThree,
  sFour,
  sFive,
  sSix,
  sSeven,
  sEight,
  sOneM,
  sTwoM,
  sThreeM,
  sFourM,
  sFiveM,
  sSixM,
  sSevenM,
  sEightM,
  geox,
  revolution,
  tdr,
};

export default servImg;
