import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsThirteen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(49.5));
  }, []);

  return (
    <NewsCont>
      <Header>ARE GREEN COMMERCIAL LEASES ALWAYS SUSTAINABLE?</Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>November 30, 2021 - 12:39pm</p>
      </Div>
      <ImageContainer>
        <img
          src={width! > 450 ? thoughtImg.bigThirteen : thoughtImg.sThirteen}
        />
      </ImageContainer>
      <BodyCont>
        <h3>
          After Cop26, we’ve been thinking about climate change in our industry.
          One of the most notable changes has been the introduction of the
          so-called green leases.
        </h3>
        <p>
          As we all reflect on the Cop26 gathering and what it did and didn’t
          manage to achieve, we’ve been thinking about our own industry and
          where it plays a part in tackling climate change. One of the most
          notable changes from this perspective in recent years has been the
          introduction of so-called green leases.
        </p>
        <p>
          The idea of making sure commercial tenants and landlords work together
          to ensure a building has as little environmental impact as possible is
          a sound concept. We believe that, by building stronger relationships
          between landlords and tenants, so much more is possible.
        </p>
        <p>
          However, some green clauses and terms are potentially driving out
          commercial tenants who can no longer afford their leases, and this is
          concerning, from an ethical and sustainability perspective. So in this
          blog, we’re asking: what happens when green leases become
          unsustainable?
        </p>
        <h3>What are green leases?</h3>
        <p>
          Green leases are understood, in the commercial property industry, to
          be those that include green clauses that help to protect and enhance
          the way a building performs from an environmental and social
          perspective. They have come about as a result of increasing government
          pressure on the construction industry and on landlords, to meet
          minimum energy efficiency standards (MEES).
        </p>
        <p>
          MEES are necessary in light of research showing that buildings are a
          major contributor to climate change and energy consumption. For
          example, <a href="https://www.theccc.org.uk/wp-content/uploads/2020/12/Sector-summary-Buildings.pdf" target="_blank">this report</a> found that around 17% of UK greenhouse gas
          emissions came from buildings in 2019.
        </p>
        <p>
          If a building is not up to scratch when it comes to MEES, landlords
          could be prevented from leasing them until measures are taken to
          improve its performance. In addition to these more desperate
          situations, green leases are also introduced to:
        </p>
        <p>
          • Improve a tenant or landlord’s green or social responsibility
          credentials
          <br />
          • To reduce costs and energy efficiency
          <br />
          • To comply with corporate social responsibility policies
          <br />
          • To help landlords comply with statutory requirements
          <br />
        </p>
        <p>
          It’s not difficult to see, then, why green leases are popular.
          Especially with landlords who have to operate buildings that are
          struggling to reach the standards expected. Green clauses can improve
          their green performance and help to reduce their buildings’ impact on
          the environment.
        </p>
        <h3>So what’s the problem from a tenant’s perspective?</h3>
        <p>
          We’ve noticed some large commercial landlords have started using quite
          heavy-handed tactics to introduce green leases to their commercial
          tenants, en-masse. Tenants are being asked to invest in green leases
          at a time when many are struggling to afford their existing leases.
        </p>
        <p>
          The business owners we work with tell us that money is tight and
          conditions are still challenging. We would argue that using aggressive
          moves to try to force these tenants into fixed, unaffordable green
          leases is counter-productive, unethical, and unsustainable.
        </p>
        <p>
          At <a href="/">Cedar Dean</a>, we are great believers in
          sustainability. We very much believe in conserving tomorrow, but we
          can’t ignore today. If businesses are being driven out of their
          premises due to unaffordable green leases, something’s not right.
        </p>
        <h3>More support to help businesses reach greener heights</h3>
        <p>
          We know how important fighting climate change is and we agree that
          commercial tenants can play a major part in bringing down the impact
          that buildings have on the environment. However, if we want businesses
          to be able to operate their buildings efficiently and responsible, we
          need leases to be affordable. We also need more support to help them
          reduce energy consumption, waste, and emissions.
        </p>
        <p>
          More support like tax benefits and grants for businesses that want to
          invest in sustainability and energy efficiency is a good place to
          start. Schemes like{" "}
          <a
            href="https://www.lowcarbonworkspaces.co.uk/eligible-projects/lighting-systems/"
            target="_blank"
          >
            Low Carbon Workspaces
          </a>{" "}
          are fantastic, but the cash available is limited and this scheme is
          only available across a handful of counties.
        </p>
        <p>
          Green leases that make greener tenancies affordable only to a handful
          of businesses are not the solution. We should be careful to protect
          the industry from a situation where leases are greener but are
          completely untenable for those who need a tenancy.
        </p>

        <h3>
          The <u>Cedar Dean</u> position…
        </h3>
        <p>
          We believe in supporting relationships between landlords and tenants.
          Doing so will enable them to cooperate, with the support of the
          government, to bring down the impact commercial buildings have on the
          environment. The key to this is ensuring that leases are affordable
          for tenants of all types, not just major national chains. Green leases
          are definitely part of the solution, but they must remain affordable
          for current and future commercial tenants.
        </p>
        <p>
          Let’s champion those who make measurable improvements to reduce their
          environmental impact while supporting businesses to improve their
          green credentials without having to become martyrs to the cause.
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsThirteen;
