import img from "../../../assets";

const testimonialData = [
  {
    body: (
      <p>
        “Cedar Dean were highly effective in providing a strategic process to
        our property restructuring and surpassed our expectations in every way.
        Their approach is positively unique and we would highly recommend them.”
      </p>
    ),
    person: (
      <h5>
        Paul Quested, CFO - <span>HSS</span>
      </h5>
    ),
  },
  {
    body: (
      <>
        <p>
          “Cedar Dean have been an invaluable support to TDR and Stonegate over
          many years. They think differently and get results. We highly
          recommend them.”
        </p>
        <img src={img.tdr} />
      </>
    ),
    person: (
      <h5>
        Brian Magnus, Partner - <span>TDR Capital</span>
      </h5>
    ),
  },
  {
    body: (
      <p>
        “The Cedar Dean team has shown a unique understanding of the fluctuating
        market conditions at the start of the pandemic, and has helped is
        greatly in devising and executing a rent renegotiation strategy that was
        robust, ambitious and conducive for excellent long-term relationship
        with out landlords.”
      </p>
    ),
    person: (
      <h5>
        Noam Bar, Co-Founder - <span>Ottolenghi</span>
      </h5>
    ),
  },
];

export default testimonialData;
