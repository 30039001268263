import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import IndValues from "../../components/individualServ/values";
import LandingForm from "../../components/landing/landingForm";
import { updateLoaded } from "../../redux/reducers/loaded";
import NotFoundPage from "../404";

import servData from "./data";
import Five from "./five";
import Four from "./four";
import One from "./one";
import Seven from "./seven";
import Six from "./six";

import { Container } from "./styles";
import Three from "./three";
import Two from "./two";

interface RouteParams {
  [key: string]: string | undefined;
  service: string;
}

const IndividualService = () => {
  const [service, setService] = useState("");

  const params = useParams<RouteParams>();

  useEffect(() => {
    let string: any = "";
    window.scrollTo(0, 0);

    const { service } = params;

    string = service;

    setService(string);
  }, [params]);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(updateLoaded());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.service]);

  const servs: any = {
    "restructuring-advice": <One />,
    acquisitions: <Two />,
    "lease-renewals": <Three />,
    "lease-surrenders": <Four />,
    "rent-reviews": <Five />,
    "strategic-lease-management": <Six />,
    "rent-reductions": <Seven />,
  };

  return (
    <Container>
      {servs[params.service!] || <NotFoundPage />}
      {service !== "restructuring-advice" && servs[params.service!] ? (
        <IndValues />
      ) : null}
      <LandingForm />
    </Container>
  );
};

export default IndividualService;
