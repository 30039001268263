import teamImg from "../../../assets/team";

const teamData: any = [
  {
    name: "DAVID ABRAMSON",
    pos: "CEO",
    linkedin: "https://www.linkedin.com/in/david-abramson-26819a9/",
    // twitter: "https://twitter.com/@davidcedardean",
    twitter: "",
    email: "David@cedardean.com ",
    number: "07711 114491",
    img: teamImg.one,
    text: "David Abramson is CEO and Founder of Cedar Dean Group and one of the best known hospitality advisors and lease restructuring specialists in the UK.",
  },
  {
    name: "LEE ISAACS",
    pos: "Head of Restructuring",
    linkedin: "http://linkedin.com/in/lee-isaacs-mrics-8889a733",
    // twitter: "https://twitter.com/leecedardean",
    twitter: "",
    email: "lee@cedardean.com",
    number: "07531 684163",
    img: teamImg.two,
    text: "With a career spanning over 15 years, Lee is one of the most prominent lease restructuring specialists operating in the UK.",
  },
  {
    name: "KELLY GOWERS",
    pos: "Lease Restructuring",
    linkedin: "https://www.linkedin.com/in/kelly-gowers-52173b64 ",
    twitter: "https://twitter.com/CedarDean ",
    email: "kelly@cedardean.com",
    number: "07879 846578",
    img: teamImg.three,
    text: "Kelly brings 20 years experience managing a portfolio of circa 400 stores for a High Street retailer, dealing with all manner of lease transactions and has excellent knowledge of commercial property practices and law.",
  },
  // {
  //   name: "HARRY GREEN",
  //   pos: "Senior Advisor",
  //   linkedin: "https://www.linkedin.com/in/harrygreen1/",
  //   twitter: "https://twitter.com/harrycedardean",
  //   email: "harry@cedardean.com",
  //   number: "07803 411318",
  //   img: teamImg.five,
  //   text: "Harry is a MRICS qualified chartered surveyor, responsible for managing national leasehold portfolios on behalf of corporate and commercial clients.",
  // },
  {
    name: "MARC ERLICH",
    pos: "Senior Advisor",
    linkedin: "https://www.linkedin.com/in/johnsmith82/ ",
    twitter: "https://twitter.com/marccedardean",
    email: "marc@cedardean.com",
    number: "07500 522377",
    img: teamImg.six,
    text: "Marc has extensive experience within the retail space, dealing directly with landlords negotiating terms as well as an extensive black book of contacts.",
  },
  //{
  //  name: "GIORGIA MORAS",
  //  pos: "Office Manager",
  //  // linkedin: "https://www.linkedin.com/in/giorgia-moras/",
  //  linkedin: "",
  //  twitter: "https://twitter.com/CedarDean",
  //  email: "giorgia@cedardean.com ",
  //  number: "07598 411244",
  //  img: teamImg.eight,
  //  text: "Giorgia keeps the office running smoothly and oversees a variety of administrative and organizational tasks supporting the team and the Directors.",
  //},
  {
    name: "MARK WILLS -WILLIAMS",
    pos: "Consultant",
    linkedin: "https://www.linkedin.com/in/mark-wills-williams-a3971210/",
    twitter: "",
    email: "markw@cedardean.com",
    number: "07721 892959",
    img: teamImg.eleven,
    text: "With over 30 years' experience in the retail sector, Mark is now working with occupiers and insolvency practices in relation to Administrations and CVA's.",
  },
  {
    name: "HUGO REISSNER",
    pos: "Restructuring Advisor - Germany",
    linkedin: "",
    twitter: "",
    email: "hugo@cedardean.com",
    number: "+49 172 2987222",
    img: teamImg.twelve,
    text: "Hugo brings his extensive management and consulting experience to Cedar Dean�s continental expansion.",
  },
  {
    name: "SIEGFRIED JAROSCH",
    pos: "Restructuring Advisor - Germany",
    linkedin: "",
    twitter: "",
    email: "siegfried@cedardean.com",
    number: "+49 177 3284186",
    img: teamImg.thirteen,
    text: "Siegfried has been working as a real estate expert in Berlin and Germany since 1996 collaborating with international investors as broker, portfolio manager and advisor.",
  },
  {
    name: "Howard Berlin",
    pos: "Head of Valuation",
    linkedin: "https://www.linkedin.com/in/howard-berlin-23aa4613/",
    twitter: "https://twitter.com/howard0181",
    email: "",
    number: "07973 122170",
    img: teamImg.fourteen,
    text: "Howard is our Head of Valuation and advises on all aspects of capital markets including valuation, rent reviews, lease renewals and rating appeals. He has been a property professional for over 40 years and during that time, has built up considerable experience in both valuing and advising on all types of commercial property across the UK.",
  },
];

export default teamData;
