import { BannerContainer, Header, Inner } from "./styles";

import { useInView } from "react-intersection-observer";

const AboutBanner = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <BannerContainer ref={ref}>
      <Inner inView={inView}>
        <Header>
          We’re leading a revolution: for both tenants and landlords,
          sustainable restructuring is the way forward.
        </Header>
      </Inner>
    </BannerContainer>
  );
};

export default AboutBanner;
