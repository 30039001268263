import { useEffect } from "react";
import { useDispatch } from "react-redux";
import thoughtImg from "../../../assets/thoughts";
import { updateArticles } from "../../../redux/reducers/articles";
import useWindowSize from "../../../utils/getWindowSize";
import { BodyCont, Div, Header, ImageContainer, NewsCont } from "../styles";

const NewsEleven = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateArticles(40.5));
  }, []);

  return (
    <NewsCont>
      <Header>
        CEDAR DEAN’S CEO APPOINTED AS CHAIR OF CTA’S POLICY COMMITTEE
      </Header>
      <Div>
        <p>
          By <b>CedarDean</b>
        </p>
        <p>February 14, 2022 - 3:16pm</p>
      </Div>
      <ImageContainer>
        <img src={width! > 450 ? thoughtImg.bigEleven : thoughtImg.sEleven} />
      </ImageContainer>
      <BodyCont>
        <h3>
          The CTA is all about creating a more equitable market place for
          commercial tenants. Together, we’re committed to defending the rights
          of commercial tenants.
        </h3>
        <h3>A message from David…</h3>
        <p>
          I’m very pleased to be able to share the news that I’ve been appointed
          to the role of Chair of the{" "}
          <a target="_blank" href="https://thecta.co.uk/">
            Commercial Tenants Association’s (CTA)
          </a>
          Policy Committee. I would like to take this opportunity to speak a
          little bit more about the role and what I hope we will achieve via the
          Policy Committee, under my chairmanship.
        </p>
        <p>
          In my opinion, urgent action is needed to address the inequalities in
          the commercial tenancy market. And action is what I’m going to bring
          to the CTA in this new role. I’d like to share my vision as to how we
          might achieve this.
        </p>
        <p>
          Firstly though, here’s some background on the CTA for those who aren’t
          so familiar with it.
        </p>

        <h3>What’s the Commercial Tenants Association?</h3>
        <p>
          The CTA is all about creating a more equitable market place for
          commercial tenants. When CEO Peter Bell founded the CTA, he was
          concerned about all the power being in the landlord’s hands. Peter
          will bring some much-needed balance to the marketplace where tenants
          are treated as valued customers, with their views listened to and
          their needs met.
        </p>
        <p>
          The CTA is supported by the members of its{" "}
          <a
            href="https://thecta.co.uk/commercial-tenants-association/"
            target="_blank"
          >
            Advisory Board
          </a>
          , all of whom bring years of experience and expertise to the
          organisation. Together, we’re committed to defending the rights of
          commercial tenants from across the entire range of business sectors.
        </p>
        <h3>How will we improve things for commercial tenants?</h3>
        <p>
          The CTA wants landlords to act fairly and responsibly and will be
          encouraging them to play a role in creating a fairer market. A major
          part of my role at the CTA will be to help bring together company
          representatives from different sectors; retail and hospitality,
          leisure and fitness, late night, office and industrial. These key
          decision makers will represent their sectors and come ready to take
          action to influence the most important issues for commercial tenants.
        </p>
        <p>
          Focusing on the CTA’s core policy agenda, which includes improving
          customer service, lease and legislative reform, we will work to bring
          about real change to the existing landlord and tenant dynamic.
        </p>
        <p>
          We want commercial tenants to have better and more equal relationships
          with their landlords, where tenants are treated as valued customers
          rather than disposable commodities. Bringing powerful voices from the
          commercial tenant side together in one place will help us to gain
          influence in what is currently an unbalanced market.
        </p>
        <p>
          At <a href="/">Cedar Dean</a>, we’ve been championing commercial
          tenants and their right to affordable leases for more than a decade.
          The CTA’s mission reflects our message of sustainable commercial
          leases, and this is why I accepted the position as Chair at the CTA.
        </p>
        <p>
          I look forward to working alongside the CTA’s various Task Forces to
          influence commercial tenancy policy at a national and local level.
          We’ll be achieving this by bringing together key decision makers from
          the most vital industries represented on our high streets. Exciting
          times lie ahead!
        </p>
      </BodyCont>
    </NewsCont>
  );
};

export default NewsEleven;
