import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AboutBanner from "../../components/about/banner";
import AboutHero from "../../components/about/hero";
import AboutInfo from "../../components/about/info";
import AboutTeam from "../../components/about/team";
import Companies from "../../components/landing/companies";
import CompaniesMobile from "../../components/landing/companiesMobile";
import LandingHelp from "../../components/landing/help";
import LandingForm from "../../components/landing/landingForm";
import LandingValues from "../../components/landing/values";
import { RootState } from "../../redux";
import { updateLoaded } from "../../redux/reducers/loaded";
import useWindowSize, { Size } from "../../utils/getWindowSize";
import { AboutContainer } from "./styles";

const AboutPage = () => {
  const { loaded } = useSelector((state: RootState) => state.loaded);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
console.log("TEST");
  
  const size: Size = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(updateLoaded());
   


    // if (!loaded) {
    //   navigate("/");
    // }
  }, []);

  return (
    <AboutContainer>
      <AboutHero />
      <AboutInfo />
      <AboutBanner />
      <LandingValues />
      <AboutTeam />
      <LandingHelp />
      <Companies />
      {size.width! < 1080 ? <CompaniesMobile /> : null}
      <LandingForm />
    </AboutContainer>
  );
};

export default AboutPage;