import styled, { keyframes, css } from "styled-components";

export const ServicesGridContainer = styled.div`
  padding: 0 64px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;

  @media (max-width: 800px) {
    padding: 0 16px 0px;
  }

  @media (max-width: 560px) {
    flex-direction: column;
    justify-content: unset;
  }
`;

export const ServicesGridItem = styled.div<props>`
  flex-basis: 33.33%;
  margin-bottom: 20px;
`;

export const ServicesGridLastRow = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  ${ServicesGridItem} {
    flex-basis: unset;
  }
`;
interface props {
  img: string;
  inView: boolean;
  ani: string;
}

const moveUp = keyframes`
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
`;

const moveUpTwo = keyframes`
  0% {
    transform: translateY(75%);
  }
  100% {
    transform: translateY(0);
  }
`;

const moveUpThree = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Box = styled.div<props>`
  min-height: 400px;
  width: 100%;
  position: relative;
  @media (min-width: 800px) {
    animation: ${({ inView, ani }) =>
      inView && ani === "one"
        ? css`
            ${moveUp} 1s ease-out forwards;
          `
        : inView && ani === "two"
        ? css`
            ${moveUpTwo} 1s ease-out forwards;
          `
        : inView && ani === "three"
        ? css`
            ${moveUpThree} 1s ease-out forwards;
          `
        : "none"};
  }

  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: ${({ img }) => `url(${img})`};
  /* margin-right: 16px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.2s linear;
  width: 32.5%;
  margin-bottom: 16px;

  @media (max-width: 850px) {
    width: 48%;
    min-height: 451px;
    max-height: 451px;
  }

  @media (max-width: 560px) {
    width: 343px;
    margin: 10px auto;
  }

  @media (max-width: 375px) {
    width: 100%;
    margin: 10px auto;
  }

  padding: 24px;
  position: relative;

  @media (max-width: 850px) {
    padding: 16px;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #f8f8f8;
    margin-bottom: 8px;

    position: relative;
    z-index: 99;
    @media (max-width: 900px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #f8f8f8;

    position: relative;
    z-index: 99;
    @media (max-width: 900px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  div:nth-child(4) {
    display: flex;
    max-height: 0px;
    justify-content: space-between;
    transition: 0.2s linear;
    opacity: 0%;
    position: relative;
    z-index: 99;

    p {
      font-weight: 600;
    }
  }
  :hover {
    cursor: pointer;
    div {
      max-height: 100px;
      opacity: 100%;
      margin-top: 10px;
    }

    div:nth-child(5) {
      opacity: 100%;
    }
  }

  div:nth-child(5) {
    transition: 0.1s linear;
    opacity: 0%;
  }
`;

export const Background = styled.div`
  background: rgba(245, 34, 24, 0.6);

  min-width: 100%;
  min-height: 100%;

  position: absolute;
  bottom: 0px;
  z-index: 50;
  left: 0px;
`;

export const TopLeft = styled.p`
  position: absolute !important;
  top: 24px;
  left: 24px;

  @media (max-width: 850px) {
    top: 16px;
    left: 16px;
  }
`;

export const BtnCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(101, 122, 133, 1);
    margin-top: 10px;
  }
`;

interface prop {
  showMore: boolean;
}

export const ShowMoreCont = styled(ServicesGridContainer)<prop>`
  max-height: ${({ showMore }) =>
    (!showMore && "0px") || (showMore && "4000px")};
  opacity: ${({ showMore }) => (!showMore && "0") || (showMore && "1")};
  pointer-events: ${({ showMore }) =>
    (!showMore && "none") || (showMore && "all")};
  transition: 1s linear;

  @media (max-width: 560px) {
    flex-wrap: nowrap;
  }
`;

export const AllCont = styled.div`
  overflow: hidden;
`;
