import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import type {RootState} from "../index";

interface props {
  loaded: boolean;
}

const initialState: props = {
  loaded: false,
};

export const loadedSlice = createSlice({
  name: "loaded",

  initialState,
  reducers: {
    updateLoaded: (state) => {
      return {...state, loaded: true};
    },
  },
});

export const {updateLoaded} = loadedSlice.actions;

export const getLoaded = (state: RootState) => state.loaded;

export default loadedSlice.reducer;
